import React from "react";
import { TitleProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    {collapsed ? (
      <img
        src={"/images/logo.png"}
        alt="Action Program"
        style={{
          width: "-moz-available",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          //         padding: "12px 24px",
        }}
      />
    ) : (
      <img
        src={"/images/logo.png"}
        alt="Action Program"
        style={{
          width: "200px",
          //          padding: "12px 24px",
        }}
      />
    )}
  </Link>
);
