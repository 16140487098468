import {
  useGetLocale,
  useSetLocale,
  useGetIdentity,
  useLogout,
  useNavigation,
  usePermissions,
} from "@pankod/refine-core";
import {
  AntdLayout,
  Space,
  Menu,
  Button,
  Icons,
  Dropdown,
  Typography,
  TextField,
  Switch,
  useModalForm,
  Modal,
} from "@pankod/refine-antd";
import { Avatar, Badge } from "antd";

import MagicBell, {
  FloatingNotificationInbox,
} from "@magicbell/magicbell-react";

import { useTranslation } from "react-i18next";
// @ts-ignore
import Notifications from "react-notifications-menu";
import { useState } from "react";
import {
  UserOutlined,
  AntDesignOutlined,
  InboxOutlined,
  BellOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { EditProfile } from "components/users/profile/edit";
import { PasswordChange } from "components/users/profile/passwordChange";

const { DownOutlined } = Icons;
const { Text, Title } = Typography;

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user, refetch:userRefetch } = useGetIdentity();
  const { t } = useTranslation();
  const { list } = useNavigation();
  const [isDarkMode, setIsDarkMode] = useState<boolean>();
  const { switcher, themes } = useThemeSwitcher();
  const [openPassChange, setOpenPassChange] = useState(false);

  const {
    formProps: editProfileFormProps,
    modalProps,
    show,
  } = useModalForm({
    resource: "users",
    action: "edit",
    redirect: false,
  });

  function toggleTheme(isChecked: boolean) {
    // added
    setIsDarkMode(isChecked);
    switcher({ theme: isChecked ? themes.dark : themes.light });
  }
  const menu2 = (
    <Menu
      items={[
        {
          label: <Title level={4}>{t("buttons.notificatonTitle")}</Title>,
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <>
              <Space>
                <ExclamationCircleOutlined style={{ fontSize: 18 }} />
                <Text onClick={() => list("actions")} style={{ fontSize: 18 }}>
                  {t("buttons.notifNewTask")}
                </Text>
              </Space>
            </>
          ),
          key: "1",
        },
      ]}
    />
  );

  const menu3 = (
    <Menu
      items={[
        {
          label: <Title level={4}>{t("buttons.notificatonTitle")}</Title>,
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: (
            <>
              <Space>
                <ExclamationCircleOutlined style={{ fontSize: 18 }} />
                <Text style={{ fontSize: 18 }}>{t("buttons.noNotif")}</Text>
              </Space>
            </>
          ),
          key: "1",
        },
      ]}
    />
  );

  const { mutate: logout } = useLogout();

  const currentLocale = locale();

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <>
          <Menu.Item
            key={lang}
            onClick={() => changeLanguage(lang)}
            icon={
              <span style={{ marginRight: 8 }}>
                <Avatar size={16} src={`/images/flags/${lang}.svg`} />
              </span>
            }
          >
            {lang === "hu"
              ? t("dashboard.translateHun")
              : lang === "en"
              ? t("dashboard.translateEng")
              : "English"}
          </Menu.Item>
        </>
      ))}
    </Menu>
  );

  const profileMenu = (
    <Menu>
      {user && (
        <>
          <Menu.Item
            key={"profile"}
            onClick={() => {
              userRefetch();
              show(user?.id);
            }}
          >
            {" "}
            {t("buttons.profile", "Profile")}
          </Menu.Item>
          <Menu.Item
            key={"changePassword"}
            onClick={() => {
              setOpenPassChange(true);
            }}
          >
            {t("buttons.changePassword", "Change Password")}
          </Menu.Item>
          <Menu.Item
            key={"logout"}
            onClick={() => {
              logout();
            }}
          >
            {t("buttons.logout", "Logout")}
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const { data: permissionsData } = usePermissions();

  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        //backgroundColor: "#FFF"
      }}
    >
      <div className="main fade-in" style={{ paddingRight: 25 }}>
        <Switch
          checkedChildren="🌜"
          unCheckedChildren="🌞"
          checked={isDarkMode}
          onChange={toggleTheme}
        />
      </div>

      <Dropdown overlay={menu} trigger={["click"]}>
        <Button type="link">
          <Space>
            <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />

            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <Space style={{ marginLeft: "8px" }}>
        {user?.name && (
          <Text ellipsis strong>
            {user.name}
          </Text>
        )}
        {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
      </Space>
      <Space style={{ marginLeft: "8px" }}>
        {user ? (
          <Dropdown trigger={["click"]} overlay={profileMenu}>
            <Space>
              {user?.avatar ? (
                <Avatar size={30} src={user?.avatar} alt={user?.email} />
              ) : (
                <Avatar size={30} icon={<UserOutlined />} />
              )}
              {user?.email ? (
                <Button type="link" onClick={(e) => e.preventDefault()}>
                  {user?.firstName
                    ? t("UserName", {
                        firstName: user.firstName,
                        lastName: user.lastName,
                      })
                    : user.name}{" "}
                  <DownOutlined />
                </Button>
              ) : (
                <></>
              )}
            </Space>
          </Dropdown>
        ) : (
          <Button
            type="link"
            onClick={() => {
              logout();
            }}
          >
            {t("buttons.login", "Login")}
          </Button>
        )}
      </Space>
      <EditProfile modalProps={modalProps} formProps={editProfileFormProps} />
      <Modal
        style={{ minWidth: "60rem" }}
        visible={openPassChange}
        onCancel={() => setOpenPassChange(false)}
        title={t("employees.titles.changePassword")}
        footer={false}
      >
        <PasswordChange
          onUpdate={(visible: boolean) => setOpenPassChange(visible)}
        />
      </Modal>
    </AntdLayout.Header>
  );
};
