import React, { useEffect, useMemo, useState } from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useMany,
  CrudFilters,
  HttpError,
  useShow,
  BaseKey,
  useGetIdentity,
  usePermissions,
  getDefaultFilter,
  useExport,
  CrudFilter,
  useUpdateMany
} from "@pankod/refine-core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  MarkdownField,
  DateField,
  TextField,
  useModalForm,
  CreateButton,
  useDrawerForm,
  Create,
  useModal,
  useSelect,
  TagField,
  ExportButton,

} from "@pankod/refine-antd";
import { Table, Space, Modal, Form, FormProps, Row, Col, Input, Card, Select, Typography, Radio, Button, Drawer, RadioChangeEvent, Grid, DatePickerProps, DatePicker, TimePickerProps, TimePicker, InputNumber, Popover } from "antd";
import moment, { Moment } from "moment";
import Calendar from "antd/lib/calendar";
import "moment/locale/hu";
//import { IEsemenyek, IKoltseghelyek, IPartnerFilter, IPartnerek } from "interfaces";
import { Tooltip } from "antd/lib";
import { ICost, IPartner, IUser } from "interfaces";
import { IUserCostConnection, IUserCostConnectionFilter } from "interfaces/usercostconnection";
import { PartnerSelect } from "components/partner/PartnersSelect";
import { CostSelect } from "components/partner/CostsSelect";
//import { PartnerSelect } from "components/PartnerSelect";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Filter } from "pages/workedhours/filter";

moment.locale('hu');

export const CostList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  moment.locale('hu');
  const customLocaleData = {
    weekdays: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    weekdaysShort: ['V', 'H', 'K', 'Sz', 'Cs', 'P', 'Szo'],
    weekdaysMin: ['V', 'H', 'K', 'Sz', 'Cs', 'P', 'Szo'],
    months: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember', 'október', 'november', 'december'],
    monthsShort: ['jan.', 'febr.', 'márc.', 'ápr.', 'máj.', 'jún.', 'júl.', 'aug.', 'szept.', 'okt.', 'nov.', 'dec.'],
  };

  moment.updateLocale('hu', customLocaleData);

  const [valueCal, setValue] = useState(() => moment());
  const [selectedValue, setSelectedValue] = useState(() => moment());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (newValue: Moment) => {
    setIsModalOpen(true);
    setValue(newValue);
    setSelectedValue(newValue);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onPanelChange = (newValue: Moment) => {
    setValue(newValue);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const { modalProps, formProps, show } = useModalForm({
    action: "create",
  });

  const { formProps: drawerForm, drawerProps, show: drawerShow, saveButtonProps } = useDrawerForm({
    action: "create",
    resource: "usercostconnections"
  });

  const { formProps: drawerFormCost, drawerProps: drawerPropsCost, show: drawerShowCost, saveButtonProps: saveButtonPropsCost } = useDrawerForm({
    action: "create",
    resource: "costs",
    redirect: false
  });

  const { selectProps: selectPropsKh, queryResult: queryResultKh, defaultValueQueryResult: defaultValueQueryResultKh } =
    useSelect<ICost>({
      resource: "partners",
      optionLabel: "name",
      optionValue: "id",
      sort: [
        {
          field: "name",
          order: "asc"
        }
      ]
    });



  const allOptionsKh = [
    ...(queryResultKh.data?.data || []),
    ...(defaultValueQueryResultKh.data?.data || []),
  ].filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.id === item.id
    ))
  );


  const { selectProps: selectPropsUser, queryResult: queryResultUser, defaultValueQueryResult: defaultValueQueryResultUser } =
    useSelect<IUser>({
      resource: "users",
      optionLabel: "email",
      optionValue: "id",
    });

  const allOptionsUser = [
    ...(queryResultUser.data?.data || []),
    ...(defaultValueQueryResultUser.data?.data || []),
  ];

  const { data: permissionsData } = usePermissions();


  const { TextArea } = Input;
  const { data: user } = useGetIdentity();
  //alert(JSON.stringify(user?.firstName + user?.lastName))


  const { tableProps: tableProps2, searchFormProps, filters: tableFilters } = useTable<IUserCostConnection, HttpError, IUserCostConnectionFilter>({
    resource: "usercostconnections",
    syncWithLocation: true,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { date, workerName, partnerId, startDate, endDate, month, ticketId } = params;
      const expfilters: CrudFilters = [
        {
          field: "paginate",
          operator: "eq",
          value: false,
        },
      ];

      filters.push(
        {
          field: "startDate",
          operator: "eq",
          value: startDate,
        },
        {
          field: "endDate",
          operator: "eq",
          value: endDate,
        },
        {
          field: "month",
          operator: "eq",
          value: month,
        },
        {
          field: "workerName",
          operator: "eq",
          value: workerName
        },
        {
          field: "partnerId",
          operator: "eq",
          value: partnerId
        },
        {
          field: "ticketId",
          operator: "eq",
          value: ticketId
        }
      );

      expfilters.push(
        {
          field: "date",
          operator: "gte",
          value: date ? date[0].startOf("day").toISOString() : undefined,
        },
        {
          field: "date",
          operator: "lte",
          value: date ? date[1].endOf("day").toISOString() : undefined,
        },
        {
          field: "workerName",
          operator: "eq",
          value: workerName
        }
      )

      setExportFilter(expfilters);
      setSearchExportFilter(exportFilters);

      return filters;
    }
  });

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const [exportFilters, setExportFilter] = React.useState<CrudFilter[]>([
    {
      field: "paginate",
      operator: "eq",
      value: false,
    },
  ]);

  const [searchExportFilters, setSearchExportFilter] = React.useState<CrudFilter[]>([
    {
      field: "paginate",
      operator: "eq",
      value: false,
    },
  ]);

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
    const filters: CrudFilters = [
      {
        field: "paginate",
        operator: "eq",
        value: false,
      },
    ];
    if (selectedRowKeys.length > 0) {
      selectedRowKeys.map((selected) => {
        filters.push({
          field: "id",
          operator: "eq",
          value: `${selected}`,
        });
        //console.log(`KIVÁLASZOTT ID: ${selected}`)
      });
      setExportFilter(filters);
      //console.log(`KIVÁLASZOTT FILTEREK: ${JSON.stringify(filters)}`)
    } else {
      //alert(JSON.stringify(searchExportFilters));
      setExportFilter(searchExportFilters);
    }
  };

  const { triggerExport, isLoading } = useExport<IUserCostConnection>({
    resourceName: "usercostconnections",
    filters: tableFilters,
    mapData: (data) => {
      return {
        Dátum: moment(data.date).format("YYYY. M. D"),
        Feladat: costs?.data.find((item) => item.id === data.ticketId)?.subprojects,
        Partner: partners?.data.find((item) => item.id === data.partnerId)?.name,
        Dolgozó: data.workerName,
        Óraszám: data.workedHours,
        Megjegyzés: data.note == null ? "Nincs megjegyzés" : data.note
      };
    },
    exportOptions: {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      title: "Óraelszámolás",
    },
  });
  //console.log(`AZ EXPORTFILTER: ${JSON.stringify(exportFilters)}`)

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { data: costs } = useMany<ICost>({
    resource: "tickets",
    ids: [],
  });

  const { data: partners } = useMany<IPartner>({
    resource: "partners",
    ids: [],
  });

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
  };

  const onChangeTime: TimePickerProps['onChange'] = (time: any) => {
    console.log(time);
  };


  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={5} xs={24}>
          <Card title="Szűrés">
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>
        <Col lg={19} xs={24}>
          <List
            title="Elvégzett feladatok"
            createButtonProps={{
              onClick: () => {
                drawerShow();
              }
            }}
            headerButtons={({ defaultButtons }) => (
              <>
                <ExportButton onClick={triggerExport} loading={isLoading} />
              </>
            )}
          >
            <Table {...tableProps2} /*rowSelection={rowSelection}*/ rowKey="id">
              <Table.Column
                dataIndex="workerName"
                title="Dolgozó neve"
              />

              <Table.Column
                dataIndex="partnerId"
                title="Partner"
                render={(value: string) => {
                  return (
                    <TextField
                      value={`${partners?.data.find((item) => item.id === value)?.name}`}
                    />
                  );
                }}
              />

              <Table.Column
                dataIndex="date"
                title="Dátum"
                render={(text) => moment(text).format('YYYY-MM-DD')}
              />

              <Table.Column
                dataIndex="ticketId"
                title="Feladat"
                render={(value: string) => {
                  return (
                    <TextField
                      value={`${costs?.data.find((item) => item.id === value)?.subprojects}`}
                    />
                  );
                }}
              />

              <Table.Column

                dataIndex="workedHours"
                title="Ledolgozott óra"
              />

              <Table.Column

                dataIndex="note"
                title="Feladat leírása"
                render={(value: any) => (
                  <Popover content={value != null ? value : "Nincs leírás rögzítve"} title="Megjegyzés">
                    <Button type="primary">Megtekintés</Button>
                  </Popover>
                )}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};