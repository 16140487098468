import {
  HttpError,
  useGetLocale,
  useMany,
  useTranslate,
} from "@pankod/refine-core";

import {
  DateField,
  EditButton,
  List,
  ShowButton,
  Space,
  Table,
  TagField,
  TextField,
  Tooltip,
  useTable,
} from "@pankod/refine-antd";
import type { ColumnsType } from "antd/es/table";

import { ITicket, IArea, ITicketpriority, IUser } from "interfaces";
import { dateFormat } from "apiconstants";
import moment from "moment";
import { TicketStatus } from "constants/pm";

export const OwnTicketList: React.FC = () => {
  const t = useTranslate();
  const locale = useGetLocale();
  const currentLocale = locale();

  const {
    tableProps: ticketsTable,
    sorter,
    filters,
    searchFormProps,
  } = useTable<
    ITicket,
    HttpError
    //IPartnerFilter
  >({
    resource: "tickets",
    queryOptions: {},
    //initialPageSize:3,
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "own",
        operator: "eq",
        value: true,
      },
    ],

    syncWithLocation: false,
  });

  const assignedIds =
    ticketsTable?.dataSource?.map((item) => item.acceptantId) ?? [];

  const { data: assigned } = useMany<IUser>({
    resource: "users",
    ids: assignedIds,
  });

  const areaIds =
    ticketsTable?.dataSource?.map((item) => item.areaId) ?? [];
  const { data: areas } = useMany<IArea>({
    resource: "areas",
    ids: areaIds,
  });

  const priorityIds =
    ticketsTable?.dataSource?.map((item) => item.priorityId) ?? [];
  const { data: priorities } = useMany<ITicketpriority>({
    resource: "ticketpriorities",
    ids: priorityIds,
  });

  const columnsAllTasks: ColumnsType<ITicket> = [
    {
      title: t("tickets.fields.projectName"),
      dataIndex: "projectName",
      key: "projectName",
      align: "center",
    },
    {
      title: t("tickets.fields.taskNumber"),
      dataIndex: "title",
      key: "title",
      align: "center",
    },

    {
      title: t("tickets.fields.area"),
      dataIndex: "areaId",
      key: "areaId",
      align: "center",
      render: (value: string) => {
        return (
          <TagField
            value={areas?.data.find((item) => item.id === value)?.name}
          />
        );
      },
    },
    {
      title: t("tickets.fields.startDate"),
      dataIndex: "createdOn",
      key: "createdOn",
      align: "center",
      render: (value) =>
        value &&
        !moment(value, dateFormat).toString().includes("0001 00:00:00") && (
          <DateField locales={currentLocale} value={value} format="L" />
        ),
    },
    {
      title: t("tickets.fields.deadline"),
      dataIndex: "dueDate",
      key: "dueDate",
      align: "center",
      render: (value) =>
        value &&
        !moment(value, dateFormat).toString().includes("0001 00:00:00") && (
          <DateField locales={currentLocale} value={value} format="L" />
        ),
    },
 /*   {
      title: t("tickets.fields.risk"),
      dataIndex: "priorityId",
      key: "priorityId",
      align: "center",
      render: (value: string) => {
        return (
          <TagField
            value={priorities?.data.find((item) => item.id === value)?.name}
          />
        );
      },
    },*/
    {
      title: t("tickets.fields.description"),
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          <div
            style={{
              maxWidth: "15rem",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {value}
          </div>
        </Tooltip>
      ),
    },

    {
      title: t("tickets.fields.status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (value) => (
        <TextField value={TicketStatus[value || 0].toString()} />
      ),
    },
    {
      title: t("tickets.fields.endDate"),
      dataIndex: "closedOn",
      key: "closedOn",
      align: "center",
      responsive: ["lg"],
      render: (value) =>
        value &&
        !moment(value, dateFormat).toString().includes("0001 00:00:00") && (
          <DateField locales={currentLocale} value={value} format="L" />
        ),
    },
    {
      title: t("tickets.fields.actions"),
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed:"right",
      render: (_, record) => (
        <Space>
          <EditButton resourceNameOrRouteName="tickets" hideText size="small" recordItemId={record.id} />
          <ShowButton  resourceNameOrRouteName="tickets"  hideText size="small" recordItemId={record.id} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <List  resource="tickets"  canCreate={false} title={t("menu.owntickets")}>
        <Table {...ticketsTable} columns={columnsAllTasks} rowKey="id">
        
        </Table>
      </List>
    </>
  );
};
