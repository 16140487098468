import {
  useShow,
  useOne,
  useNavigation,
  usePermissions,
  useMany,
  useGetLocale,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  Show,
  Typography,
  ListButton,
  Row,
  Col,
  TagField,
  DateField,
  ImageField,
  TextField,
  Descriptions,
} from "@pankod/refine-antd";

import { IProject, ITicket, IUser } from "interfaces";
import { Card, Space } from "antd";
import React from "react";
import { Table, Tag, Spin, Alert, Image } from "antd";
import {
  InboxOutlined,
  UploadOutlined,
  UserOutlined,
  AntDesignOutlined,
  FormOutlined,
  Loading3QuartersOutlined,
  CheckOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  ExceptionOutlined,
} from "@ant-design/icons";
import {
  Button,
  Upload,
  Progress,
  Avatar,
  Tooltip,
  Divider,
  Tabs,
  Checkbox,
} from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { useTranslation } from "react-i18next";
import { dateFormat, HOST_URL } from "apiconstants";
import { ListTickets } from "components/tickets/listTickets";
import { isOverdue } from "utility/functions";
import { ProjectRisk, ProjectStatus } from "constants/pm";
import moment from "moment";
const { Title, Text } = Typography;

export const ProjectShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IProject>();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const today = new Date();
  const { t } = useTranslation();
  const { list } = useNavigation();
  const { data: permissionsData } = usePermissions();
  const locale = useGetLocale();
  const currentLocale = locale();
  const { Dragger } = Upload;

  const { data: users } = useMany<IUser>({
    resource: "users",
    ids: [],
  });

  /*const previews = record?.attachments?.map((file, index) => {
   // return <Image key={index} src={file.attachmentUrl} />;
   return <ImageField
   key={file.name}
   value={file.attachmentUrl}
   title={file.name}
   width={200}
/>

});*/

  const getPrecent = () => {
    let totalItems = record?.tickets?.length || 0;
    let items =
      record?.tickets?.filter(
        (value: ITicket) => value.closedOn || value.reviewedOn
      ) || 0;
    let p = totalItems === 0 ? 0 : (items.length / totalItems) * 100;
    return +p.toFixed(2);
  };

  const columns = [
    {
      title: t("projects.fields.projectTitle"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("projects.fields.startDate"),
      dataIndex: "createdOn",
      key: "createdOn",
      render: (value: any) =>
        value &&
        !moment(value, dateFormat).toString().includes("0001 00:00:00") && (
          <DateField locales={currentLocale} value={value} format="L" />
        ),
    },
    {
      title: t("projects.fields.deadline"),
      dataIndex: "dueDate",
      key: "dueDate",
      render: (value: any) =>
        value &&
        !moment(value, dateFormat).toString().includes("0001 00:00:00") && (
          <DateField locales={currentLocale} value={value} format="L" />
        ),
    },
    {
      title: t("projects.fields.priority"),
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: t("projects.fields.createdBy"),
      dataIndex: "createdBy",
      key: "createdBy",
      render: (value: string) => {
        return (
          <TagField
            value={
              value?.endsWith("00000000-0000-0000-0000-000000000000")
                ? "System"
                : users?.data.find((item) => item.id === value)?.email
            }
          />
        );
      },
    },
    {
      title: t("projects.fields.status"),
      dataIndex: "status",
      key: "status",
      render: (value: number) => (
        <TextField value={ProjectStatus[value || 0].toString()} />
      ),
    },
  ];
  const { TabPane } = Tabs;

  const gridStyle: React.CSSProperties = {
    width: "25%",
    textAlign: "center",
  };

  return (
    <Show
      resource="projects"
      title={t("projects.titles.show")}
      pageHeaderProps={{
        extra: [<ListButton hidden />],
      }}
    >
      <Row style={{ marginBottom: ".5rem" }} gutter={[20, 20]}>
        <Col xs={24} md={12}>
          <Card
            style={{ width: "100%", height: "100%" }}
            title={t("projects.fields.aboutTheProjectCard")}
            size="default"
          >
            <p>{record?.description}</p>
          </Card>
        </Col>

        <Col xs={24} md={12}>
          <Card
            style={{ width: "100%", height: "100%" }}
            title={t("projects.fields.projectDetails")}
            size="default"
          >
            <Descriptions
              bordered
              size="small"
              layout="vertical"
              column={{ xxl: 4, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
              //title="Custom Size"

              //extra={<Button type="primary">Edit</Button>}
            >
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label={t("projects.fields.projectTitle")}
              >
                {record?.name}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label={t("projects.fields.startDate")}
                style={{whiteSpace: "nowrap",}}
              >
                {record?.createdOn &&
                  !moment(record?.createdOn, dateFormat)
                    .toString()
                    .includes("0001 00:00:00") && (
                    <DateField
                      locales={currentLocale}
                      value={record?.createdOn}
                      format="L"
                    />
                  )}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label={t("projects.fields.deadline")}
                style={{whiteSpace: "nowrap",}}
              >
                {record?.dueDate &&
                  !moment(record?.dueDate, dateFormat)
                    .toString()
                    .includes("0001 00:00:00") && (
                    <DateField
                      locales={currentLocale}
                      value={record?.dueDate}
                      format="L"
                    />
                  )}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label={t("projects.fields.priority")}
              >
                {record?.risk ? ProjectRisk[+record?.risk] : ProjectRisk[0]}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label={t("projects.fields.createdBy")}
              >
                <TagField
                  value={
                    record?.createdBy?.endsWith(
                      "00000000-0000-0000-0000-000000000000"
                    )
                      ? "System"
                      : users?.data.find(
                          (item) => item.id === record?.createdBy
                        )?.firstName
                  }
                />
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontWeight: "bolder" }}
                label={t("projects.fields.status")}
              >
                <TextField
                  value={ProjectStatus[record?.status || 0].toString()}
                />
              </Descriptions.Item>
            </Descriptions>
            <Progress
              strokeColor={{
                "0%": "#c79dbd",
                "100%": "#ab0988",
              }}
              percent={getPrecent()}
              status="active"
            />
          </Card>
        </Col>
      </Row>
      <Row style={{ marginBottom: ".5rem" }} gutter={[20, 20]}>
      <Col xs={24}>
              <Card
                style={{ width: "100%", height: "100%" }}
                size="default"
                title={t("projects.fields.tasks")}
              >
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane
                    tab={
                      <span>
                        <FormOutlined />
                        {t("tickets.tabs.all")}
                      </span>
                    }
                    key="1"
                  >
                    <ListTickets
                      dataSource={record?.tickets}
                      users={users?.data}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <span>
                        <Loading3QuartersOutlined />
                        {t("tickets.tabs.pending")}
                      </span>
                    }
                    key="2"
                  >
                    <ListTickets
                      dataSource={record?.tickets?.filter(
                        (value: ITicket) => !value.closedOn
                      )}
                      users={users?.data}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <span>
                        <CheckOutlined />
                        {t("tickets.tabs.completed")}
                      </span>
                    }
                    key="3"
                  >
                    <ListTickets
                      dataSource={record?.tickets?.filter(
                        (value: ITicket) => value.closedOn
                      )}
                      users={users?.data}
                    />
                  </Tabs.TabPane>

                  <Tabs.TabPane
                    tab={
                      <span>
                        <ExceptionOutlined />
                        {t("tickets.tabs.overdue")}
                      </span>
                    }
                    key="4"
                  >
                    <ListTickets
                      dataSource={record?.tickets?.filter((value: ITicket) =>
                        isOverdue(value)
                      )}
                      users={users?.data}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </Card>
            </Col>
        <Col xs={24} md={12}>
          <Card
            style={{ width: "100%", height: 200 }}
            title={t("projects.fields.uploadedFilesCard")}
            size="default"
          >
            {record?.attachments?.map((file, index) => {
              return (
                <Card.Grid style={gridStyle}>
                  <ImageField
                    key={file.name}
                    //src={file.attachmentUrl}
                    value={`${HOST_URL}/${file.attachmentUrl}`}
                    title={file.name}
                    width="8rem"
                  ></ImageField>
                </Card.Grid>
              );
            })}
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Row style={{ marginBottom: ".5rem" }} gutter={[20, 20]}>
            <Col xs={24} md={12}>
              <Card
                style={{ width: "100%", height: 200 }}
                title={t("projects.fields.leader")}
              >
                <Avatar size={{ xs: 24, sm: 32 }} />
                <Text style={{ marginLeft: "0.2rem" }}>
                  {!record?.ownerId ||
                  record?.ownerId?.endsWith(
                    "00000000-0000-0000-0000-000000000000"
                  )
                    ? "System"
                    : users?.data.find(
                        (item) =>
                          item.id === record?.ownerId?.toString().toLowerCase()
                      )?.lastName
                      }
                </Text>
                <Text style={{ marginLeft: "0.2rem" }}>
                  {!record?.ownerId ||
                  record?.ownerId?.endsWith(
                    "00000000-0000-0000-0000-000000000000"
                  )
                    ? "System"
                    : users?.data.find(
                        (item) =>
                          item.id === record?.ownerId?.toString().toLowerCase()
                      )?.firstName
                      }
                </Text>
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Card
                style={{ width: "100%", height: 200 }}
                title={t("projects.fields.team")}
              >
                <Avatar.Group size={{ xs: 24, sm: 32 }}>
                  {record &&
                    Object.keys(
                      record?.tickets.reduce(
                        (acc: any, { acceptantId }: any) => {
                          acc[acceptantId] = acceptantId;

                          return acc; //acceptantId
                        },
                        {}
                      )
                    ).map((id: string) => {
                      const usr = users?.data.find(
                        (item) => item.id === id?.toString().toLowerCase()
                      );
                      return (
                        usr && (
                          //usr.imageUrl &&
                          <Tooltip title={usr?.userName} placement="top">
                              <Avatar
                                style={{ backgroundColor: "#f56a00", margin: 5 }}
                                icon={<UserOutlined value={usr?.email} />}
                                src={`${HOST_URL}/${usr?.imageUrl}`}
                              ></Avatar>
                              <Text style={{ marginLeft: "0.2rem" }}>
                                {usr?.lastName}
                              </Text>
                              <Text style={{ marginLeft: "0.2rem" }}>
                                {usr?.firstName}
                              </Text>
                          </Tooltip>
                        )
                      );
                    })}
                </Avatar.Group>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Show>
  );
};
