import { useTranslate, useApiUrl, useGetLocale, file2Base64 } from "@pankod/refine-core";

import {
  Create,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  ButtonProps,
  Avatar,
  Typography,
  Upload,
  Grid,
  getValueFromEvent,
  useSelect,
  RcFile,
  UploadFile,
  //DatePicker,
} from "@pankod/refine-antd";
import {
  PlusOutlined
} from "@ant-design/icons";

import { DatePicker } from "antd";
import { IArea, ITicketpriority, IUser } from "interfaces";
import React, { useState } from "react";
import { EventType } from "@testing-library/react";
import { getValueProps } from "helpers";
import { HOST_URL } from "apiconstants";
import { Checkbox } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { PartnerSelect } from "components/partner/PartnersSelect";

const { Text } = Typography;

type CreateTicketProps = {
  projectId?: string;
  type?: number;
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const CreateTicket: React.FC<CreateTicketProps> = ({
  projectId: initProjectId,
  type: initType,
  drawerProps,
  formProps,
  saveButtonProps,
}) => {
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const locale = useGetLocale();
  const currentLocale = locale();
  const breakpoint = Grid.useBreakpoint();

  const { selectProps: areaSelectProps } = useSelect<IArea>({
    resource: "areas",
    optionValue: "id",
    optionLabel: "name",
    hasPagination: false,
    pagination: {
      pageSize: 2,
    },
  });

  const { selectProps: prioritySelectProps } = useSelect<ITicketpriority>({
    resource: "ticketpriorities",
    optionValue: "id",
    optionLabel: "name",
    hasPagination: false,
    pagination: {
      pageSize: 2,
    },

  });

  const { selectProps: ownerProps } = useSelect<IUser>({
    resource: "users",
    optionValue: "id",
    optionLabel: "email",
    hasPagination: false,
  });


  const checkboxOptions = [
    { label: '✔️', value: true },
    { label: '❌', value: false }
  ];

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
      afterVisibleChange={
        (e: any) => {
          drawerProps?.afterVisibleChange && drawerProps.afterVisibleChange(e);
          formProps?.form?.resetFields();
          formProps?.form?.setFieldsValue({ projectId: initProjectId, type: initType });
        }
      }
    /*  onClose={(e:any) => {
        drawerProps?.onClose && drawerProps.onClose(e);
        formProps?.form?.resetFields();
        }
      }*/
    >
      <Create
        title={t("tickets.titles.create")}
        goBack={false}
        breadcrumb={false}
        resource="tickets"
        saveButtonProps={saveButtonProps}
      >
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            isActive: true,
          }}
        >
          {initProjectId && (
            <>
              <Form.Item hidden initialValue={initProjectId} name="projectId" >
                <Input defaultValue={initProjectId} />
              </Form.Item>
            </>
          )}

          {initType != undefined ? (
            <Form.Item hidden initialValue={initType} name="type">
              <Input />
            </Form.Item>
          ) : (
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={1}>
                  {t("tickets.fields.corrective")}
                </Radio.Button>
                <Radio.Button value={2}>
                  {t("tickets.fields.preventive")}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item
            label={t("tickets.fields.title")}
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("tickets.fields.description")}
            name="description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea rows={6} />
          </Form.Item>
          <Form.Item
            label={t("tickets.fields.area")}
            name={["areaId"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select {...areaSelectProps} />
          </Form.Item>
          <Form.Item
            label={t("tickets.fields.priority")}
            name={["priorityId"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select {...prioritySelectProps} />
          </Form.Item>
          <Form.Item
            label="MESH"
            name="meshChecked"
            valuePropName="checked"

          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label={t("tickets.fields.owner")}
            name="acceptantId"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select allowClear {...ownerProps} />

            {/*<Select {...assigneProps} />*/}
          </Form.Item>
          <Form.Item
            label={t("tickets.fields.deadline")}
            name="dueDate"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder={t("tickets.fields.selectDate")}
            />
          </Form.Item>

          <Form.Item
            label="Partner"
            name="partnerId"
          >
            <PartnerSelect />

          </Form.Item>


          <Form.Item
            label="Feladat neve"
            name="subprojects"
          >
            <Input />

          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};
