import React from "react";
import {
  IResourceComponentsProps,
  useGetLocale,
  useTranslate,
} from "@pankod/refine-core";
import {
  Create,
  Form,
  useForm,
  Input,
  useSelect,
  Select,
  DrawerProps,
  FormProps,
  ButtonProps,
  useDrawerForm,
  Grid,
  Drawer,
} from "@pankod/refine-antd";
import { IComment } from "interfaces";

type CreateCommentProps = {
  parentId?: string;
  ticketId?: string | undefined;
  parentQueryResult?: any;
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const CommentCreate: React.FC<CreateCommentProps> = ({
  parentId: initParentId,
  ticketId: initTicketId,
  //parentQueryResult,
  drawerProps,
  formProps,
  saveButtonProps,
}) => {
  /* const {
    formProps: commentCreateFormProps,
    drawerProps: commentCreateDrawerProps,
    show: commentCreate,
    saveButtonProps: commentCreateSaveButtonProps,
  } = useDrawerForm<IComment>({
    action: "create",
    resource: "comments",
    redirect: false,
    onMutationSuccess: () => {
      parentQueryResult?.refetch();
    },
  });
*/

  const t = useTranslate();
  const breakpoint = Grid.useBreakpoint();
  const locale = useGetLocale();
  const currentLocale = locale();
  const { TextArea } = Input;

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      zIndex={1001}
    >
      <Create
        resource="comments"
        goBack={false}
        breadcrumb={false}
        saveButtonProps={saveButtonProps}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item hidden initialValue={initParentId} name="parentId">
            <Input />
          </Form.Item>

          <Form.Item hidden initialValue={initTicketId} name="ticketId">
            <Input />
          </Form.Item>
          <Form.Item
             label={t("comments.fields.content")}
            name={["content"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
                           <TextArea
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    style={{ height: "100%" }}
                    showCount
                     />

          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};
