import React from "react";
import { useLogin, useTranslate } from "@pankod/refine-core";
import {
    Row,
    Col,
    AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
} from "@pankod/refine-antd";
import "styles/style.css";

const { Text, Title } = Typography;

export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
}

export const Login: React.FC = () => {
    const [form] = Form.useForm<ILoginForm>();
    const t = useTranslate();
    const { mutate: login } = useLogin<ILoginForm>();

    const CardTitle = (
        <Title level={3} className="title">
            {t("pages.login.title")}
        </Title>
    );

    return (
        <AntdLayout className="layout">
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div className="container">
                        <div className="imageContainer">
                            <img src="/images/logo.png" alt="Logo" />
                        </div>
                        <Card title={CardTitle} headStyle={{ borderBottom: 0 }} style={{  }}>
                            <Form<ILoginForm>
                                layout="vertical"
                                form={form}
                                onFinish={(values) => {
                                    login(values);
                                }}
                                requiredMark={false}
                                initialValues={{
                                    remember: false,
				    tenant:'root'
                                }}
                            >
                                <Form.Item
                                    name="tenant"
                                    label={t("pages.login.tenant")}
                                    rules={[{ required: true , message:t("pages.login.tenantReq")}]}
                                >
                                    <Input
                                        style={{  }}
                                        size="large"
                                        placeholder={t("pages.login.tenant")}

                                    />
                                </Form.Item>
                                <Form.Item
                                    name="username"
                                    label={t("pages.login.username")}
                                    rules={[{ required: true , message:t("pages.login.usernameReq")}]}
                                >
                                    <Input
                                        style={{  }}
                                        size="large"
                                        placeholder={t("pages.login.username")}

                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label={t("pages.login.password")}
                                    rules={[{ required: true, message:t("pages.login.passwordReq")}]}
                                    style={{ marginBottom: "12px" }}
                                >
                                    <Input
                                        style={{  }}
                                        type="password"
                                        placeholder="●●●●●●●●"
                                        size="large"
                                    />
                                </Form.Item>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    block
                                    style={{    background: "#c471b2", borderColor:"#c471b2"}}
                                >
                                    {t("pages.login.signin")}
                                </Button>
                            </Form>
                        </Card>
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
};