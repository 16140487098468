import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useMany,
  file2Base64,
  HttpError,
  usePermissions,
} from "@pankod/refine-core";
import {
  useTable,
  List,
  Table,
  Space,
  TagField,
  EmailField,
  BooleanField,
  useModalForm,
  useSelect,
  Form,
  Row,
  Modal,
  Select,
  Col,
  getValueFromEvent,
  Upload,
  Input,
  EditButton,
  ShowButton,
  Typography,
  AntdLayout,
  Button,
} from "@pankod/refine-antd";
import { Avatar, Card } from "antd";
import { HOST_URL } from "apiconstants";
import { IArea, IPremise, IUser } from "interfaces";
const { Text, Link, Title } = Typography;

export const EmployeesList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps: usersTable,
    sorter,
    filters,
    searchFormProps,
  } = useTable({
    resource: "users",
    syncWithLocation: true,
  });

  const { data: permissionsData } = usePermissions();
  const t = useTranslate();
  const {
    modalProps: createModal,
    formProps: createForm,
    show: showCreateModal,
  } = useModalForm<IUser>({
    action: "create",
    resource: "users",
    redirect: false,
  /*  errorNotification: (data: any, values, resource) => {
      const errors = data?.response?.data?.errors;
      const msg = Object.keys(errors).map((err: any) => {
        // alert('hello')
        return JSON.stringify(errors[err]);
      });
      return {
        message: `${data?.response?.data?.title}`,
        description: `${msg}`,
        type: "error",
      };
    },*/
    /*onMutationError: (data, variables, context) => {
      //console.log({ data, variables, context });
      //alert(JSON.stringify(data?.response?.data?.errors))
      //alert(data?.response?.data?.errors)
      const errors = data?.response?.data?.errors;
      //alert(JSON.stringify(x.errors));
      const msg = Object.keys(errors).map((err: any) => {
       // alert('hello')
        return JSON.stringify(errors[err]);
      });
      return {
        message: `Something went wrong when getting ${msg}`,
        description: msg,
        type: "error",
      };
    },*/
  });

  const {
    queryResult: editQueryResult,
    modalProps: editModal,
    formProps: editForm,
    show: editShow,
  } = useModalForm<IUser, HttpError, IUser>({
    action: "edit",
    resource: "users",
    redirect: false,
  });

  //const { data: editRecord, isLoading: editIsLoading } = editQueryResult;
  const editRecord = editQueryResult?.data;

  const premiseIds =
    usersTable?.dataSource?.map((item) => item.premiseId) ?? [];
  const { data: premises } = useMany<IPremise>({
    resource: "premises",
    ids: premiseIds,
  });

  const areaIds =
    usersTable?.dataSource?.map((item) => item.areaId) ?? [];
  const { data: areas } = useMany<IArea>({
    resource: "areas",
    ids: areaIds,
  });

  const { selectProps: premiseProps } = useSelect<IPremise>({
    resource: "premises",
    optionValue: "id",
    optionLabel: "name",
    hasPagination: false,
  });

  const { selectProps: areaProps } = useSelect<IArea>({
    resource: "areas",
    optionValue: "id",
    optionLabel: "name",
    hasPagination: false,
  });

  const CardTitle = (
    <Title level={3} className="title">
      {t("employees.titles.create")}
    </Title>
  );

  return (
    <>
    {
    //alert(JSON.stringify(permissionsData.some((r:any) => (r.roleName === 'Admin' && r.enabled === true))))

    }
      <List  title={t("employees.titles.list")}
      canCreate={permissionsData?.some((r:any) => (r.roleName === 'Admin' && r.enabled === true))}
        createButtonProps={{
          onClick: () => {
            showCreateModal();
          },
        }}
      >
        <Table {...usersTable} rowKey="id">
          <Table.Column dataIndex="userName" title={t("employees.fields.userName")} />
          <Table.Column dataIndex="firstName" title={t("employees.fields.firstName")} />
          <Table.Column dataIndex="lastName" title={t("employees.fields.lastName")} />
          <Table.Column
            dataIndex={["email"]}
            title={t("employees.fields.email")}
            render={(value: any) => <EmailField value={value} />}
          />
          <Table.Column
            dataIndex={["isActive"]}
            title={t("employees.fields.isActive")}
            render={(value: any) => <BooleanField value={value} />}
          />
          <Table.Column
            dataIndex={["emailConfirmed"]}
            title={t("employees.fields.emailConfirmed")}
            render={(value: any) => <BooleanField value={value} />}
          />
          <Table.Column
            key="actions"
            title={t('actions.title')}
            dataIndex="actions"
	    fixed= "right"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton
                  hideText
                  size="small"
                  onClick={() => editShow(record.id)}
                  resourceNameOrRouteName="users"
                />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal {...editModal}>
        {editRecord && (
          <Form
            {...editForm}
            layout="vertical"
            onFinish={async (values) => {
              const base64Files = [];
              const { image } = values;
              if (image) {
                for (const file of image) {
                  if (file.originFileObj) {
                    const base64String = await file2Base64(file);

                    base64Files.push({
                      ...file,

                      data: base64String,
                    });
                  } else {
                    base64Files.push(file);
                  }
                }
              }
              const file = base64Files.length > 0 && base64Files[0];

              return file
                ? editForm.onFinish &&
                    editForm.onFinish({
                      ...values,
                      image: {
                        name: file?.name,
                        data: file.data,
                        extension: ".png",
                      },
                    })
                : editForm.onFinish &&
                    editForm.onFinish({
                      ...values,
                    });
            }}
          >
            <Row gutter={18}>
              <Col span={24}>
                <Form.Item label={t("employees.fields.id")} hidden name="id" />
                <Form.Item
                  label={t("employees.fields.email")}
                  hidden
                  name="email"
                />
                <Row gutter={18}>                
                  <Col span={12}>
                    <Form.Item
                      label={t("employees.fields.lastName")}
                      name="lastName"
                    >
                      <Input style={{}} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t("employees.fields.firstName")}
                      name="firstName"
                    >
                      <Input style={{}} />
                    </Form.Item>{" "}
                  </Col>
                </Row>
                <Row gutter={18}>
                  <Col span={12}>
                    <Form.Item
                      label={t("employees.fields.premise")}
                      name="premiseId"
                    >
                      <Select allowClear {...premiseProps} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t("employees.fields.area")}
                      name="areaId"
                    >
                      <Select allowClear {...areaProps} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Form.Item label="Avatar">
                <Form.Item name="imageUrl">
                  <Avatar src={`${HOST_URL}/${editRecord?.data?.imageUrl}`} />
                </Form.Item>

                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueFromEvent={getValueFromEvent}
                  noStyle
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Upload.Dragger
                    maxCount={1}
                    listType="picture"
                    multiple={false}
                    beforeUpload={() => false}
                  >
                    <p className="ant-upload-text">
                      Drag & drop a file in this area
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
            </Row>
          </Form>
        )}
      </Modal>
      <Modal {...createModal} title={false}>
        <AntdLayout className="layout">
          <Col xs={22}>
            <div className="container">
              <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
                <Form
                  {...createForm}
                  layout="vertical"

                  /*onFinish={(values) => {
                                      signup(values.email, values.password);
                                      push("login");
                                  }}*/
                >
                  <Col span={24}>
                    <Form.Item
                      name="email"
                      label={t("employees.fields.email")}
                      rules={[{ required: true, type: "email" }]}
                    >
                      <Input size="large" placeholder="Email" />
                    </Form.Item>
                   
                    <Row gutter={18}>                      
                      <Col span={12}>
                        <Form.Item
                          label={t("employees.fields.lastName")}
                          name="lastName"
                        >
                          <Input style={{}} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={t("employees.fields.firstName")}
                          name="firstName"
                        >
                          <Input style={{}} />
                        </Form.Item>{" "}
                      </Col>
                    </Row>
                    <Row gutter={18}>
                      <Col span={12}>
                        <Form.Item
                          label={t("employees.fields.premise")}
                          name="premiseId"
                        >
                          <Select allowClear {...premiseProps} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={t("employees.fields.area")}
                          name="areaId"
                        >
                          <Select allowClear {...areaProps} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true }]}
                    style={{ marginBottom: "12px" }}
                  >
                    <Input
                      type="password"
                      placeholder="●●●●●●●●"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    rules={[{ required: true }]}
                    style={{ marginBottom: "12px" }}
                  >
                    <Input
                      type="password"
                      placeholder="●●●●●●●●"
                      size="large"
                    />
                  </Form.Item>
                 {/* <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    block
                    style={{ marginTop: 24 }}
                  >
                    Create Account
                  </Button>
                                */}
                </Form>
              </Card>
            </div>
          </Col>
        </AntdLayout>
      </Modal>
    </>
  );
};
