import {
  Form,
  Modal,
  Input,
  ModalProps,
  FormProps,
  Select,
  useSelect,
  Typography,
  Row,
  getValueFromEvent,
  Col,
  Upload,
  Space,
  Avatar,
  Tabs,
  Button,
  SaveButton,
} from "@pankod/refine-antd";
import { FileDoneOutlined } from "@ant-design/icons";
import {
  useGetLocale,
  useNavigation,
  useTranslate,
  useUpdatePassword,
} from "@pankod/refine-core";
import { IArea, IPremise } from "interfaces";

type PasswordChangeProps = {
  onUpdate?: any;
  hideSecurity?: boolean;
};

type updatePasswordVariables = {
  password: string;
  newPassword: string;
  confirmPassword: string;
};

export const PasswordChange: React.FC<PasswordChangeProps> = ({
  onUpdate,
  hideSecurity = true,
}) => {
  const t = useTranslate();
  const { show, edit } = useNavigation();
  const [form] = Form.useForm<updatePasswordVariables>();
  const locale = useGetLocale();
  const currentLocale = locale();

  const { Title, Text } = Typography;
  const { TextArea } = Input;

  const { mutate: updatePassword } =
    useUpdatePassword<updatePasswordVariables>();

  const onSubmit = (values: updatePasswordVariables) => {
   
    updatePassword(values, {
      onSuccess: (data) => {
        form.resetFields();
        return onUpdate && onUpdate(false);
      },
    });
  };

  return (
    <Form layout="vertical"
    form={form}
    onFinish={onSubmit}>
      
      <Form.Item
        name="password"
        label={t("password.old")}
        rules={[{ required: true }]}
        style={{ marginBottom: "12px" }}
      >
        <Input type="password" placeholder="●●●●●●●●" size="large" />
      </Form.Item>
      <Form.Item
        name="newPassword"
        label={t("password.new")}
        rules={[{ required: true }]}
        style={{ marginBottom: "12px" }}
      >
        <Input type="password" placeholder="●●●●●●●●" size="large" />
      </Form.Item>
      <Form.Item
        name="confirmNewPassword"
        label={t("password.confirm")}
        rules={[{ required: true }]}
        style={{ marginBottom: "12px" }}
      >
        <Input type="password" placeholder="●●●●●●●●" size="large" />
      </Form.Item>
      <Form.Item>
      <Space key="action-buttons" style={{ float: "right", marginRight: 24 }}>
        <SaveButton htmlType="submit" ></SaveButton>
        <Button
       onClick={()=> {
        form.resetFields();
        onUpdate && onUpdate(false);}}
          size="middle"
       //   icon={<FileDoneOutlined style={{ fontSize: "115%" }} />}
        >
          {t("buttons.cancel")}
        </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
