import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from "@pankod/refine-core";
import {
  useTable,
  List,
  Table,
  Space,
  EditButton,
  ShowButton,
  DeleteButton,
} from "@pankod/refine-antd";
import { usePermissions } from "@pankod/refine-core";

export const SourceList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { data: permissionsData } = usePermissions();
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  return (
    <List
      title={t("sources.titles.list")}
      canCreate={permissionsData?.some(
        (r: any) => r.roleName === "Admin" && r.enabled === true
      )}
    >
      <Table {...tableProps} rowKey="id">
        {/*<Table.Column dataIndex="id" title="Id" />*/}
        <Table.Column dataIndex="name" title={t("sources.fields.name")} />
        <Table.Column
          key="actions"
          title={t("actions.title")}
          dataIndex="actions"
          fixed="right"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
