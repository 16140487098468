import React from "react";
import {
    Form,
    FormProps,
    Input,
    useSelect,  
    DatePicker,
    Icons,
    Button,
    Typography,
} from "@pankod/refine-antd";
import {  Select } from "antd";

import { ITicket, ITicketpriority, IArea, IUser  } from "interfaces";
import { TicketStatus } from "constants/pm";

const { RangePicker } = DatePicker;
const { Text, Title } = Typography;
const { Option } = Select;

export const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
   

    const { selectProps: areaSelectProps } = useSelect<IArea>({
        resource: "areas",
        optionValue: "id",
        optionLabel: "name",
        hasPagination: false,
      });
    
      const { selectProps: prioritySelectProps } = useSelect<ITicketpriority>({
        resource: "ticketpriorities",
        optionValue: "id",
        optionLabel: "name",
        hasPagination: false,
      });
      
      const { selectProps: ownerSelectProps } = useSelect<IUser>({
        resource: "users",
        optionValue: "id",
        optionLabel: "email",
        hasPagination: false,
      });
    
    return (
        <Form layout="vertical" {...formProps}>
            <Form.Item label="Search" name="title">
                <Input placeholder="Title" prefix={<Icons.SearchOutlined />} />
            </Form.Item>
           
            <Form.Item label="Priority" name="priorityId">
                <Select
                    {...prioritySelectProps}
                    allowClear
                    placeholder="Seach Priority"
                />
            </Form.Item>
            <Form.Item label="Area" name="areaId">
                <Select
                    {...areaSelectProps}
                    allowClear
                    placeholder="Seach Area"
                />
            </Form.Item>
            <Form.Item label="Status" name="statusId">
            <Select allowClear   placeholder="Search Status">
                  {Object.keys(TicketStatus).map((key) => (
                    <Option value={+key}>
                      <Text style={{ fontSize: 20 }}>{TicketStatus[+key]}</Text>
                    </Option>
                  ))}
                </Select>
                
            </Form.Item>
            <Form.Item label="Assigned" name="acceptantId">
                <Select
                    {...ownerSelectProps}
                    allowClear
                    placeholder="Search Assignee"
                />
            </Form.Item>
          {/*  <Form.Item label="Start Date" name="dueDate">
                <RangePicker />
                  </Form.Item>*/}
            
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </Form.Item>
        </Form>
    );
};
