import { newModel, StringAdapter } from "casbin";

export const model2 = newModel(`
  [request_definition]
  r = sub, obj, act

  [policy_definition]
  p = sub, obj, act

  [role_definition]
  g = _, _

  [policy_effect]
  e = some(where (p.eft == allow))

  [matchers]
  m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act, eft

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new StringAdapter(`

p, sqsadmin, owntickets, (list)|(create)|(edit)|(show)|(delete)
p, sqsadmin, owntickets/*, (edit)|(show)|(delete)|(create)

p, sqsadmin, costconnections, (list)
p, sqsadmin, usercostconnections, (list)|(show)|(delete)|(create)
p, sqsadmin, usercostconnections/*, (edit)|(show)|(delete)|(create)
p, sqsadmin, costs, (list)|(show)|(delete)
p, sqsadmin, costs/*, (edit)|(show)|(delete)|(create)

p, sqsadmin, mainprojects, (list)|(create)|(edit)|(show)|(delete)
p, sqsadmin, mainprojects/*, (edit)|(show)|(delete)|(create)

p, sqsadmin, dashboard, list
p, sqsadmin, basedatas, (list)|(create)
p, sqsadmin, basedatas/*, (edit)|(show)|(delete)|(create)


p, sqsadmin, premises, (list)|(create)|(edit)|(show)|(delete)
p, sqsadmin, premises/*, (edit)|(show)|(delete)
p, sqsadmin, premises1/*, field

p, sqsadmin, sources, (list)|(create)|(edit)|(show)|(delete)
p, sqsadmin, sources/*, (edit)|(show)|(delete)
p, sqsadmin, sources/*, field

p, sqsadmin, areas, (list)|(create)|(edit)|(show)|(delete)
p, sqsadmin, areas/*, (edit)|(show)|(delete)
p, sqsadmin, areas/*, field

p, sqsadmin, places, (list)|(create)|(edit)|(show)|(delete)
p, sqsadmin, places/*, (edit)|(show)|(delete)
p, sqsadmin, places/*, field

p, sqsadmin, projects, (list)|(create)|(edit)|(show)|(delete)
p, sqsadmin, projects/*, (edit)|(show)|(delete)
p, sqsadmin, projects/*, field

p, sqsadmin, allprojects, (list)|(create)|(edit)|(show)|(delete)
p, sqsadmin, allprojects/*, (edit)|(show)|(delete)
p, sqsadmin, allprojects/*, field

p, sqsadmin, tickets, (list)|(create)|(edit)|(show)|(delete)
p, sqsadmin, tickets/*, (edit)|(show)|(delete)
p, sqsadmin, tickets/*, field

p, sqsadmin, alltickets, (list)|(create)|(edit)|(show)|(delete)
p, sqsadmin, alltickets/*, (edit)|(show)|(delete)
p, sqsadmin, alltickets/*, field

p, sqsadmin, users, (list)|(create)|(edit)|(show)|(delete)
p, sqsadmin, users/*, (edit)|(show)|(delete)

p, admin, owntickets, (list)|(create)|(edit)|(show)|(delete)
p, admin, owntickets/*, (edit)|(show)|(delete)|(create)
p, admin, mainprojects, (list)|(create)|(edit)|(show)|(delete)
p, admin, mainprojects/*, (edit)|(show)|(delete)|(create)
p, admin, dashboard, list
p, admin, basedatas, (list)|(create)
p, admin, basedatas/*, (edit)|(show)|(delete)|(create)


p, admin, premises, (list)|(create)|(edit)|(show)|(delete)
p, admin, premises/*, (edit)|(show)|(delete)
p, admin, premises1/*, field

p, admin, sources, (list)|(create)|(edit)|(show)|(delete)
p, admin, sources/*, (edit)|(show)|(delete)
p, admin, sources/*, field

p, admin, areas, (list)|(create)|(edit)|(show)|(delete)
p, admin, areas/*, (edit)|(show)|(delete)
p, admin, areas/*, field

p, admin, places, (list)|(create)|(edit)|(show)|(delete)
p, admin, places/*, (edit)|(show)|(delete)
p, admin, places/*, field

p, admin, projects, (list)|(create)|(edit)|(show)|(delete)
p, admin, projects/*, (edit)|(show)|(delete)
p, admin, projects/*, field

p, admin, allprojects, (list)|(create)|(edit)|(show)|(delete)
p, admin, allprojects/*, (edit)|(show)|(delete)
p, admin, allprojects/*, field

p, admin, tickets, (list)|(create)|(edit)|(show)|(delete)
p, admin, tickets/*, (edit)|(show)|(delete)
p, admin, tickets/*, field

p, admin, alltickets, (list)|(create)|(edit)|(show)|(delete)
p, admin, alltickets/*, (edit)|(show)|(delete)
p, admin, alltickets/*, field

p, admin, users, (list)|(create)|(edit)|(show)|(delete)
p, admin, users/*, (edit)|(show)|(delete)

p, editor, projects, (list)|(create)|(edit)|(show)|(delete)
p, editor, projects/*, (edit)|(show)
p, editor, projects/*, field

p, editor, tickets, (list)|(create)
p, editor, tickets/*, (edit)|(show)
p, editor, tickets/*, field, deny

p, editor, sources, list

p, basic, dashboard, list
p, editor, dashboard, list

p, basic, mainprojects, (list)
p, basic, allprojects, (list)|(create)|(edit)|(show)|(delete)
p, basic, allprojects/*, (edit)|(show)|(delete)
p, basic, allprojects/*, field

p, basic, owntickets, (list)|(edit)|(show)
p, basic, projects, (list)|(create)|(edit)|(show)
p, basic, tickets, (list)|(create)|(edit)|(show)

p, editor, mainprojects, (list)
p, editor, allprojects, (list)|(create)|(edit)|(show)|(delete)
p, editor, allprojects/*, (edit)|(show)|(delete)
p, editor, allprojects/*, field

p, editor, costconnections, (list)
p, editor, owntickets, (list)|(edit)|(show)
p, editor, usercostconnections, (list)|(create)|(edit)|(show)
p, editor, tickets, (list)|(create)|(edit)|(show)
`);
