import hulocaleValues from "antd/lib/locale/hu_HU";
import {Locale} from "antd/lib/locale-provider";

const typeTemplate = '${label} nem érvényes ${type}';

const localeValues: Locale = {
  ...hulocaleValues,
  global: {
    placeholder: 'Kérjük, válasszon',
  },
  Table: {
    filterTitle: 'Szűrők',
    filterConfirm: 'Alkalmazás',
    filterReset: 'Visszaállítás',
    selectAll: 'Jelenlegi oldal kiválasztása',
    selectInvert: 'Jelenlegi oldal inverze',
    sortTitle: 'Rendezés',
    filterEmptyText: 'Nincsenek szűrők',
    filterCheckall: 'Válassza ki az összes elemet',
    filterSearchPlaceholder: 'Keresés szűrőkben',
    emptyText: 'Nincs adat',
    selectNone: 'Minden adat törlése',
    selectionAll: 'Minden adat kiválasztása',
    expand: 'Sor kibontása',
    collapse: 'Sor összecsukása',
    triggerDesc: 'Kattintson ide a csökkenő sorrendbe rendezéshez',
    triggerAsc: 'Kattintson a gombra a növekvő rendezéshez',
    cancelSort: 'Kattintson a gombra a rendezés törléséhez',
  },
  Modal: {
    okText: 'Alkalmazás',
    cancelText: 'Visszavonás',
    justOkText: 'Alkalmazás'    
  },
  Popconfirm: {
    okText: 'Alkalmazás',
    cancelText: 'Visszavonás',
  },
  Transfer: {
    titles: ['', ''],
    searchPlaceholder: 'Keresés',
    itemUnit: 'elem',
    itemsUnit: 'elemek',    
    remove: 'Eltávolítás',
    selectCurrent: 'Jelenlegi oldal kiválasztása',
    removeCurrent: 'Jelenlegi oldal eltávolítása',
    selectAll: 'Minden adat kiválasztása',
    removeAll: 'Minden adat eltávolítása',
    selectInvert: 'Jelenlegi oldal invertálása',
  },
  Upload: {
    uploading: 'Feltöltés...',
    removeFile: 'Fájl eltávolítása',
    uploadError: 'Feltöltési hiba',
    previewFile: 'Fájl előnézet',
    downloadFile: 'Fájl letöltése',    
  },
  Empty: {
    description: 'Nincs adat',
  },
  Icon: {
    icon: 'Ikon',
  },
  Text: {
    edit: 'Szerkesztés',
    copy: 'Másolás',
    copied: 'Másolva',
    expand: 'Bővítés',
  },
  PageHeader: {
    back: 'Vissza',
  },
  Form: {
    optional: '(optional)',
    defaultValidateMessages: {
      default: 'Mező érvényesítési hiba ${label}',
      required: 'Kérjük, adja meg a ${label}',
      enum: '${label} a következők valamelyikének kell lennie [${enum}]',
      whitespace: '${label} cannot be a blank character',
      date: {
        format: '${label} a dátumformátum érvénytelen',
        parse: '${label} nem alakítható át dátummá',
        invalid: '${label} érvénytelen dátum',
      },
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate,
      },
      string: {
        len: '${label} ${len} karakter hosszúságú.',
        min: '${label} legalább ${min} karakter',
        max: '${label} legfeljebb ${max} karakter lehet',
        range: '${label}-nak/nek ${min}-${max} karakterek között kell lennie',
      },
      number: {
        len: '${label} egyenlőnek kell lennie ${len}-val/vel',
        min: '${label} legyen minimum ${min}',
        max: '${label} legyen maximum ${max}',
        range: '${label} ${min}-${max} között kell lennie.',
      },
      array: {
        len: '${label} ${len} kell lennie',
        min: 'Legalább ${min} ${label}',
        max: 'Legfeljebb ${max} ${label}',
        range: 'A ${label} összegének ${min}-${max} között kell lennie.',
      },
      pattern: {
        mismatch: '${label} nem felel meg a mintának ${pattern}',
      },
    },
  },
  Image: {
    preview: 'Előnézet',
  },
};

export default localeValues;