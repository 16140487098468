import {
  file2Base64,
  HttpError,
  IResourceComponentsProps,
  useMany,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import React, { useEffect, useState } from "react";

import {
  PlusOutlined,
  UserOutlined,
  InboxOutlined,
  UserAddOutlined,
  FormOutlined,
  CheckCircleOutlined,
  Loading3QuartersOutlined,
  CheckOutlined,
  ExceptionOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  CreateButton,
  Drawer,
  //DatePicker,
  Edit,
  Form,
  getValueFromEvent,
  Input,
  List,
  Modal,
  RcFile,
  Row,
  Select,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
  useDrawerForm,
} from "@pankod/refine-antd";
import { DatePicker } from "antd";

import { useForm, useSelect } from "@pankod/refine-antd";

import { dateFormat, HOST_URL } from "apiconstants";
import { getValueProps } from "helpers";
import {
  IPremise,
  IProject,
  IProjectVariable,
  ISource,
  ITicket,
  IArea,
  ITicketpriority,
  IUser,
} from "interfaces";
import moment from "moment";
import { isOverdue } from "utility/functions";
import { ListTickets } from "components/tickets/listTickets";
import { CreateTicket } from "components/tickets/create";
import { IPlace } from "../../interfaces";
import { ProjectStatus, ProjectRisk } from "constants/pm";

export const ProjectEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<
    IProject,
    HttpError,
    IProjectVariable
  >({
    resource:"projects",
    warnWhenUnsavedChanges: true,
    redirect: false,
  });

  const record = queryResult?.data?.data;
  const { selectProps: premiseProps } = useSelect<IPremise>({
    resource: "premises",
    optionValue: "id",
    optionLabel: "name",
    hasPagination:false
  });
  const { selectProps: sourceProps } = useSelect<ISource>({
    resource: "sources",
    optionValue: "id",
    optionLabel: "name",
    hasPagination:false
  });
  const { selectProps: placeProps } = useSelect<IPlace>({
    resource: "places",
    optionValue: "id",
    optionLabel: "name",
    hasPagination:false
  });
  const { selectProps: ownerProps } = useSelect<IUser>({
    resource: "users",
    optionValue: "id",
    optionLabel: "email",
    hasPagination:false
  });
  const { data: users } = useMany<IUser>({
    resource: "users",
    ids: [],
  });

  const t = useTranslate();
  const onChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log("Change:", e.target.value);
  };

  const { Option } = Select;
  const { TextArea } = Input;
  const { Text, Link } = Typography;

  const [enabledCapaTab, setEnabledCapaTab] = useState(false);

  /*** Upload */

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));

    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> {t("projects.fields.upload")}</div>
    </div>
  );
  /*** Upload */
  /*** Tasks */

  const {
    formProps: ticketCreateFormProps,
    drawerProps: ticketCreateDrawerProps,
    show: ticketCreate,
    saveButtonProps: ticketCreateSaveButtonProps,
  } = useDrawerForm<ITicket>({
    action: "create",
    resource: "tickets",
    redirect: false,
    onMutationSuccess: () => {
      queryResult?.refetch();
    },
  });
  const { selectProps: areaProps } = useSelect<IArea>({
    resource: "areas",
    optionValue: "id",
    optionLabel: "name",
    hasPagination: false,
  });

  const { selectProps: priorityProps } = useSelect<ITicketpriority>({
    resource: "ticketpriorities",
    optionValue: "id",
    optionLabel: "name",
    hasPagination: false,
  });

  useEffect(() => {
    setEnabledCapaTab(record?.isCapa);
  }, [record]);
  /** Tasks */
  return (
    // record &&
    <Edit goBack={false} saveButtonProps={saveButtonProps}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t("projects.tabs.baseData")} key="1">
          <Form
            {...formProps}
            onFinish={async (values) => {
              //alert(JSON.stringify(formProps.form?.getFieldsValue(true).removeUploads))
              //alert(JSON.stringify(values.removeUploads))
              const removeUploads =
                formProps.form?.getFieldsValue(true).removeUploads;
              const base64Files = [];
              const { attachments } = values;

              for (const file of attachments) {
                if (file.originFileObj) {
                  const base64String = await file2Base64(file);

                  base64String &&
                    base64Files.push({
                      ...file,
                      base64String,
                      name:
                        file?.name.substring(0, file?.name.lastIndexOf(".")) ||
                        file?.name,
                      data: base64String,
                      extension: "." + file?.name?.split(".").pop(),
                    });
                } else {
                  //base64Files.push(file);
                }
              }

              return (
                formProps.onFinish &&
                formProps.onFinish({
                  ...values,
                  uploads: base64Files,
                  removeUploads: removeUploads,
                })
              );
            }}
            layout="vertical"
          >
            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item hidden name={"id"}>
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t("projects.fields.projectTitle")}
                  name="name"
                >
                  <Input style={{}} />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item
                  label={t("projects.fields.site")}
                  name={["premise", "id"]}
                  valuePropName={"premiseId"}
                  getValueProps={(value) => ({
                    value: value && value,
                  })}
                >
                  <Select allowClear {...premiseProps} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t("projects.fields.description")}
                  name="description"
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    style={{ height: "100%" }}
                    showCount
                    minLength={1000}
                    onChange={onChangeInput}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Row gutter={10}>
                  <Col xs={24} lg={24}>
                    <Form.Item
                      label={t("projects.fields.source")}
                      name={["source", "id"]}
                      valuePropName={"sourceId"}
                      getValueProps={(value) => ({
                        value: value && value,
                      })}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select allowClear {...sourceProps} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col xs={6} lg={6}>
                <Form.Item
                  label={t("projects.fields.detectionDate")}
                  getValueProps={(value) => ({
                    value:
                      value &&
                      value !== "0001-01-01T00:00:00" &&
                      !moment(value?.detectionDate, dateFormat)
                        .toString()
                        .includes("0001 00:00:00")
                        ? moment(value, dateFormat)
                        : "",
                  })}
                  name="detectionDate"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    //onChange={onChangeDate}
                    //defaultValue={moment("2022-10-31", dateFormat)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
              <Col xs={6} lg={6}>
                <Form.Item
                  label={t("projects.fields.detectionPlace")}
                  name={"detectionPlaceId"}
                  // name={["place", "id"]}
                  //valuePropName={"detectionPlaceId"}
                  getValueProps={(value) => ({
                    value: value && value,
                  })}
                >
                  <Select allowClear {...placeProps} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col xs={24} lg={12}>
                <Form.Item label={t("projects.fields.upload")}>
                  <Form.Item
                    name="attachments"
                    valuePropName="fileList"
                    /* getValueProps={(props)=>{
              alert(JSON.stringify(props));
              return props || []
            }            
            }*/
                    getValueProps={(data) => getValueProps(data, HOST_URL)}
                    getValueFromEvent={(props) => {
                      //alert(JSON.stringify(props));
                      const fileList = getValueFromEvent(props);
                      return fileList;
                    }}
                    noStyle
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Upload
                      listType="picture-card"
                      multiple
                      onPreview={handlePreview}
                      beforeUpload={() => false}
                      onRemove={(value: any) => {
                        //alert(JSON.stringify(value))
                        const removeUploads =
                          formProps.form?.getFieldValue("removeUploads") || [];
                        removeUploads.push(value?.id);
                        formProps.form?.setFieldsValue({
                          removeUploads: removeUploads,
                        });
                        // alert(JSON.stringify(formProps.form?.getFieldsValue(true).removeUploads))
                      }}
                    >
                      {uploadButton}
                      {/*<p className="ant-upload-text">
                {t("projects.fields.uploadDrag")}                
            </p>*/}
                    </Upload>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Modal
            visible={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
            width={
              previewImage?.split(".").pop()?.toLowerCase() === "pdf"
                ? "100vw"
                : undefined
            }
          >
            {previewImage?.split(".").pop()?.toLowerCase() === "pdf" ? (
              <iframe
                src={previewImage}
                style={{ width: "100%", height: "75vh" }}
              ></iframe>
            ) : (
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            )}
          </Modal>
        </Tabs.TabPane>

        <Tabs.TabPane tab={t("projects.tabs.actions.intermediate")} key="2">
          <CreateButton
            style={{ float: "right" }}
            onClick={() => ticketCreate()}
          />
          <CreateTicket
            projectId={record?.id}
            type={0}
            drawerProps={ticketCreateDrawerProps}
            formProps={{ ...ticketCreateFormProps }}
            saveButtonProps={ticketCreateSaveButtonProps}
          />

          <Tabs defaultActiveKey="1">
            <Tabs.TabPane
              tab={
                <span>
                  <FormOutlined />
                  {t("tickets.tabs.all")}
                </span>
              }
              key="1"
            >
              <ListTickets
                dataSource={record?.tickets?.filter(
                  (value: ITicket) => value.type === 0
                )}
                users={users?.data}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>
                  <Loading3QuartersOutlined />
                  {t("tickets.tabs.pending")}
                </span>
              }
              key="2"
            >
              <ListTickets
                dataSource={record?.tickets?.filter(
                  (value: ITicket) => !value.closedOn && value.type === 0
                )}
                users={users?.data}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>
                  <CheckOutlined />
                  {t("tickets.tabs.completed")}
                </span>
              }
              key="3"
            >
              <ListTickets
                dataSource={record?.tickets?.filter(
                  (value: ITicket) => value.closedOn && value.type === 0
                )}
                users={users?.data}
              />
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={
                <span>
                  <ExceptionOutlined />
                  {t("tickets.tabs.overdue")}
                </span>
              }
              key="4"
            >
              <ListTickets
                dataSource={record?.tickets?.filter(
                  (value: ITicket) => isOverdue(value) && value.type === 0
                )}
                users={users?.data}
              />
            </Tabs.TabPane>
          </Tabs>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("projects.tabs.investigation")} key="3">
          <Form
            {...formProps}
            onFinish={async (values) => {
              //alert(JSON.stringify(formProps.form?.getFieldsValue(true).removeUploads))
              //alert(JSON.stringify(values.removeUploads))
              const removeUploads =
                formProps.form?.getFieldsValue(true).removeUploads;
              const base64Files = [];
              const { attachments } = values;

              for (const file of attachments) {
                if (file.originFileObj) {
                  const base64String = await file2Base64(file);

                  base64String &&
                    base64Files.push({
                      ...file,
                      base64String,
                      name:
                        file?.name.substring(0, file?.name.lastIndexOf(".")) ||
                        file?.name,
                      data: base64String,
                      extension: "." + file?.name?.split(".").pop(),
                    });
                } else {
                  //base64Files.push(file);
                }
              }

              return (
                formProps.onFinish &&
                formProps.onFinish({
                  ...values,
                  uploads: base64Files,
                  removeUploads: removeUploads,
                })
              );
            }}
            layout="vertical"
          >
            <Row gutter={10}>
              <Col xs={6} lg={6}>
                <Form.Item
                  label={t("projects.fields.isCapa")}
                  name="isCapa"
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(value) => setEnabledCapaTab(value)}
                    checkedChildren={t("projects.fields.isCapaRequired")}
                    unCheckedChildren={t("projects.fields.isCapaNoNeed")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item dependencies={["isCapa"]}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue("isCapa") && (
                    <>
                      <Row gutter={10}>
                        <Col xs={6} lg={6}>
                          <Form.Item
                            label={t("projects.fields.startDate")}
                            name="startDate"
                            getValueProps={(value) => ({
                              value:
                                value &&
                                !moment(value, dateFormat)
                                  .toString()
                                  .includes("0001 00:00:00")
                                  ? moment(value, dateFormat)
                                  : "",
                            })}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              //onChange={onChangeDate}
                              //defaultValue={moment("2022-05-01", dateFormat)}
                              format={dateFormat}
                              placeholder={t("projects.fields.selectDate")}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={6} lg={6}>
                          <Form.Item
                            label={t("projects.fields.deadline")}
                            getValueProps={(value) => ({
                              value:
                                value &&
                                !moment(value, dateFormat)
                                  .toString()
                                  .includes("0001 00:00:00")
                                  ? moment(value, dateFormat)
                                  : "",
                            })}
                            name="dueDate"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              //onChange={onChangeDate}
                              //defaultValue={moment("2022-10-31", dateFormat)}
                              format={dateFormat}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} lg={12}>
                          <Form.Item
                            label={t("projects.fields.priority")}
                            name="risk"
                          >
                            <Select allowClear>
                              {Object.keys(ProjectRisk).map((key) => (
                                <Option value={+key}>
                                  <Text>{ProjectRisk[+key]}</Text>
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col xs={12} lg={12}>
                          <Form.Item
                            label={t("projects.fields.addProjectLeader")}
                            name="ownerId"
                          >
                            <Select allowClear {...ownerProps} />

                            {/*<Select {...assigneProps} />*/}
                          </Form.Item>

                          {/*  <Form.Item label={t("projects.fields.addTeam")} name="addTeam">
                          <Input style={{}} defaultValue={"John Doe, Ant Users"} />
                        </Form.Item>
                */}
                        </Col>

                        <Col xs={24} lg={12}>
                          <Form.Item
                            label={t("projects.fields.team")}
                            name="tickets"
                          >
                            <Avatar.Group>
                              {record &&
                                Object.keys(
                                  record?.tickets.reduce(
                                    (acc: any, { acceptantId }: any) => {
                                      acc[acceptantId] = acceptantId;

                                      return acc; //acceptantId
                                    },
                                    {}
                                  )
                                ).map((id: string) => {
                                  const usr = users?.data.find(
                                    (item) =>
                                      item.id === id?.toString().toLowerCase()
                                  );
                                  return (
                                    usr &&
                                    usr.imageUrl && (
                                      <Tooltip
                                        title={usr?.userName}
                                        placement="top"
                                      >
                                        <Avatar
                                          style={{ backgroundColor: "#f56a00" }}
                                          icon={
                                            <UserOutlined value={usr?.email} />
                                          }
                                          src={`${HOST_URL}/${usr?.imageUrl}`}
                                        ></Avatar>
                                      </Tooltip>
                                    )
                                  );
                                })}
                            </Avatar.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )
                );
              }}
            </Form.Item>
            <Row gutter={10}>
              <Form.Item label={t("projects.fields.upload")}>
                <Form.Item
                  name="attachments"
                  valuePropName="fileList"
                        /* getValueProps={(props)=>{
                    alert(JSON.stringify(props));
                    return props || []
                  }            
                  }*/
                  getValueProps={(data) => getValueProps(data, HOST_URL)}
                  getValueFromEvent={(props) => {
                    //alert(JSON.stringify(props));
                    const fileList = getValueFromEvent(props);
                    return fileList;
                  }}
                  noStyle
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Upload
                    listType="picture-card"
                    multiple
                    onPreview={handlePreview}
                    beforeUpload={() => false}
                    onRemove={(value: any) => {
                      //alert(JSON.stringify(value))
                      const removeUploads =
                        formProps.form?.getFieldValue("removeUploads") || [];
                      removeUploads.push(value?.id);
                      formProps.form?.setFieldsValue({
                        removeUploads: removeUploads,
                      });
                      // alert(JSON.stringify(formProps.form?.getFieldsValue(true).removeUploads))
                    }}
                  >
                    {uploadButton}
                    {/*<p className="ant-upload-text">
                {t("projects.fields.uploadDrag")}                
            </p>*/}
                  </Upload>
                </Form.Item>
              </Form.Item>
            </Row>
          </Form>
          <Modal
            visible={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
            width={
              previewImage?.split(".").pop()?.toLowerCase() === "pdf"
                ? "100vw"
                : undefined
            }
          >
            {previewImage?.split(".").pop()?.toLowerCase() === "pdf" ? (
              <iframe
                src={previewImage}
                style={{ width: "100%", height: "75vh" }}
              ></iframe>
            ) : (
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            )}
          </Modal>
        </Tabs.TabPane>

        {enabledCapaTab && (
          <Tabs.TabPane
            tab={t("projects.tabs.actions.capa")}
            key="4"
            disabled={!enabledCapaTab}
          >
            <Form
              {...formProps}
              onFinish={async (values) => {
                //alert(JSON.stringify(formProps.form?.getFieldsValue(true).removeUploads))
                //alert(JSON.stringify(values.removeUploads))
                const removeUploads =
                  formProps.form?.getFieldsValue(true).removeUploads;
                const base64Files = [];
                const { attachments } = values;

                for (const file of attachments) {
                  if (file.originFileObj) {
                    const base64String = await file2Base64(file);

                    base64String &&
                      base64Files.push({
                        ...file,
                        base64String,
                        name:
                          file?.name.substring(
                            0,
                            file?.name.lastIndexOf(".")
                          ) || file?.name,
                        data: base64String,
                        extension: "." + file?.name?.split(".").pop(),
                      });
                  } else {
                    //base64Files.push(file);
                  }
                }

                return (
                  formProps.onFinish &&
                  formProps.onFinish({
                    ...values,
                    uploads: base64Files,
                    removeUploads: removeUploads,
                  })
                );
              }}
              layout="vertical"
            >
              <Row gutter={10}>
                <Col xs={20} lg={20}>
                  <Form.Item
                    label={t("projects.fields.rootCause")}
                    name="rootCause"
                  >
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 6 }}
                      style={{ height: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row gutter={10}>
              <Col xs={24} lg={24}>
                <CreateButton
                  style={{ float: "right" }}
                  onClick={() => ticketCreate()}
                />
              </Col>
            </Row>            
            <Row gutter={10}>
              <CreateTicket
                projectId={record?.id}
                drawerProps={ticketCreateDrawerProps}
                formProps={{ ...ticketCreateFormProps }}
                saveButtonProps={ticketCreateSaveButtonProps}
              />
            </Row>

            <div className="site-card-wrapper">
              <Row gutter={16}>
                <Col span={12}>
                  <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title={t("tickets.fields.corrective")}
                  >
                    <Tabs defaultActiveKey="1">
                      <Tabs.TabPane
                        tab={
                          <span>
                            <FormOutlined />
                            {t("tickets.tabs.all")}
                          </span>
                        }
                        key="1"
                      >
                        <ListTickets
                          dataSource={record?.tickets?.filter(
                            (value: ITicket) => value.type === 1
                          )}
                          users={users?.data}
                        />
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          <span>
                            <Loading3QuartersOutlined />
                            {t("tickets.tabs.pending")}
                          </span>
                        }
                        key="2"
                      >
                        <ListTickets
                          dataSource={record?.tickets?.filter(
                            (value: ITicket) =>
                              !value.closedOn && value.type === 1
                          )}
                          users={users?.data}
                        />
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          <span>
                            <CheckOutlined />
                            {t("tickets.tabs.completed")}
                          </span>
                        }
                        key="3"
                      >
                        <ListTickets
                          dataSource={record?.tickets?.filter(
                            (value: ITicket) =>
                              value.closedOn && value.type === 1
                          )}
                          users={users?.data}
                        />
                      </Tabs.TabPane>

                      <Tabs.TabPane
                        tab={
                          <span>
                            <ExceptionOutlined />
                            {t("tickets.tabs.overdue")}
                          </span>
                        }
                        key="4"
                      >
                        <ListTickets
                          dataSource={record?.tickets?.filter(
                            (value: ITicket) =>
                              isOverdue(value) && value.type === 1
                          )}
                          users={users?.data}
                        />
                      </Tabs.TabPane>
                    </Tabs>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    style={{ marginTop: 16 }}
                    type="inner"
                    title={t("tickets.fields.preventive")}
                  >
                    <Tabs defaultActiveKey="1">
                      <Tabs.TabPane
                        tab={
                          <span>
                            <FormOutlined />
                            {t("tickets.tabs.all")}
                          </span>
                        }
                        key="1"
                      >
                        <ListTickets
                          dataSource={record?.tickets?.filter(
                            (value: ITicket) => value.type === 2
                          )}
                          users={users?.data}
                        />
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          <span>
                            <Loading3QuartersOutlined />
                            {t("tickets.tabs.pending")}
                          </span>
                        }
                        key="2"
                      >
                        <ListTickets
                          dataSource={record?.tickets?.filter(
                            (value: ITicket) =>
                              !value.closedOn && value.type === 2
                          )}
                          users={users?.data}
                        />
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab={
                          <span>
                            <CheckOutlined />
                            {t("tickets.tabs.completed")}
                          </span>
                        }
                        key="3"
                      >
                        <ListTickets
                          dataSource={record?.tickets?.filter(
                            (value: ITicket) =>
                              value.closedOn && value.type === 2
                          )}
                          users={users?.data}
                        />
                      </Tabs.TabPane>

                      <Tabs.TabPane
                        tab={
                          <span>
                            <ExceptionOutlined />
                            {t("tickets.tabs.overdue")}
                          </span>
                        }
                        key="4"
                      >
                        <ListTickets
                          dataSource={record?.tickets?.filter(
                            (value: ITicket) =>
                              isOverdue(value) && value.type === 2
                          )}
                          users={users?.data}
                        />
                      </Tabs.TabPane>
                    </Tabs>
                  </Card>
                </Col>
              </Row>
            </div>
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab={t("projects.tabs.quality.reviews")} key="5">
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane
              tab={
                <span>
                  <FormOutlined />
                  {t("tickets.tabs.all")}
                </span>
              }
              key="1"
            >
              <ListTickets
                dataSource={record?.tickets}
                users={users?.data}
                review={true}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>
                  <Loading3QuartersOutlined />
                  {t("projects.tabs.quality.pending")}
                </span>
              }
              key="2"
            >
              <ListTickets
                dataSource={record?.tickets?.filter(
                  (value: ITicket) => !value.closedOn
                )}
                users={users?.data}
                review={true}
              />
            </Tabs.TabPane>
          </Tabs>
        </Tabs.TabPane>
      </Tabs>
    </Edit>
  );
};
