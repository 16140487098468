import {
  Row,
  Col,
  Card,
  Typography,
  Title,
  DateField,
  TextField,
  Button,
  Statistic,
  notification,
} from "@pankod/refine-antd";
import { useTranslation } from "react-i18next";
import type { DatePickerProps } from "antd";
import { DatePicker, Space, Progress } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FundProjectionScreenOutlined, FormOutlined } from "@ant-design/icons";
//import { View, Text } from "react-native";
import Clock from "react-live-clock";
import {
  useGetIdentity,
  useGetLocale,
  useList,
  useMany,
  useNavigation,
  usePermissions,
} from "@pankod/refine-core";
import { ClockCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Timeline } from "antd";
import { IPlace, IProject, ISource, ITicket, ITicketpriority } from "interfaces";
import { MixChart, TaskChart } from "components/tickets/pie";
import { groupBy } from "helpers";
import axios from "axios";
import { TOKEN_KEY } from "apiconstants";

export const DashboardPage: React.FC = () => {
  const projectList = useList<IProject>({
    resource: "projects",
    config: {
      hasPagination:false,
  
  },
  });

  const placeIds =
    projectList.data?.data.map((item) => item.detectionPlaceId) ?? [];

  const sourceIds = projectList.data?.data.map((item) => item.sourceId) ?? [];

  const { data: places } = useMany<IPlace>({
    resource: "places",
    ids: placeIds || [],
  });

  const { data: sources } = useMany<ISource>({
    resource: "sources",
    ids: sourceIds || [],
  });
  const ticketList = useList<ITicket>({
    resource: "tickets",
    config: {
      hasPagination:false,
   /*   filters: [
        {
          field: "own",
          operator: "eq",
          value: true,
        },
      ],
      */
  },
  });

  const priorityIds =
    ticketList.data?.data.map((item) => item.priorityId) ?? [];
  const { data: priorities } = useMany<ITicketpriority>({
    resource: "ticketpriorities",
    ids: priorityIds || [],
  });

  const { t } = useTranslation();
  const { list } = useNavigation();
  const locale = useGetLocale();
  const currentLocale = locale();

  const { Text, Title } = Typography;
  const { data: permissionsData } = usePermissions();

  const { data: user } = useGetIdentity();

  const openNotification = (title: any, description: any) => {
    notification.warning({
      message: title,
      description: description,
      duration: 0,
      placement: "top"
    });
  };

  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [notifiedTickets, setNotifiedTickets] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        //Local
        //const response = await axios.get('https://localhost:5001/api/tickets', {
          
        //Server
        const response = await axios.get('https://office.sqs.hu/SQSProjects/api/tickets', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
          },
        });
        setTickets(response.data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    fetchTickets();
  }, []);

  useEffect(() => {
    const checkTicketStatus = () => {
      if (user) {
        tickets.forEach(ticket => {
          if (ticket.status === 5 && ticket.acceptantId === user.id && !notifiedTickets.has(ticket.id)) {
            openNotification(
              <h2>Értesítés</h2>,
              `A(z) "${ticket.title}" megnevezésű feladat további módosítást igényel.`
            );
            setNotifiedTickets(prev => new Set(prev).add(ticket.id));
          }
        });
      }
    };

    checkTicketStatus();
  }, [tickets, user, notifiedTickets]);
 
  return (
    <>
      <Row gutter={[20, 20]}>
        <Col xl={18}>
          <Title style={{ fontSize: "1.5vw" }} level={2}>
            {t("dashboard.welcome")}
          </Title>
        </Col>
        <Col xl={6}>
          <Title style={{ fontSize: "1.5vw" }} level={2}>
            <DateField locales={currentLocale} value={dayjs()} format="L" />
            <Clock format={"HH:mm"} ticking={true} style={{ padding: 18 }} />
          </Title>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={12} xs={24}>
          <Card title={<Title level={3}>{t("dashboard.projectStat")}</Title>}>
            <Row gutter={20}>
              <Col xs={24}>
               {/* <MixChart
                  data={{
                    pie1:
                    sources?.data.map((i) => {
                        return {
                          id: i.id,
                          value: groupBy(sourceIds)[i.id],
                        };
                      }) ?? [],
                    pie2:
                      places?.data.map((i) => {
                        return {
                          id: i.id,
                          value: groupBy(placeIds)[i.id],
                        };
                      }) ?? [],
                  }}
                />*/}
                { <TaskChart
                  data={
                    sources?.data.map((i) => {
                      return {
                        type: i.name,
                        value: groupBy(sourceIds)[i.id],
                      };
                    }) ?? []
                  }
                />}
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={12} xs={24}>
                <Statistic
                  title={t("dashboard.activeProjects")}
                  value={
                    projectList?.data?.data.filter(
                      (value: IProject) => value?.closedOn
                    ).length
                  }
                  suffix={`/ ${projectList?.data?.data?.length}`}
                />
              </Col>
              <Col md={12} xs={24}>
                <Statistic
                  title={t("dashboard.closedProjects")}
                  value={
                    projectList?.data?.data.filter(
                      (value: IProject) => !value?.closedOn
                    ).length
                  }
                  suffix={`/ ${projectList?.data?.data?.length}`}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={12} xs={24}>
          <Card
            title={<Title level={3}>{t("dashboard.cardTitleTasksStat")}</Title>}
          >
            <Row gutter={20}>
              <Col xs={24}>
                <TaskChart
                  data={
                    priorities?.data.map((i) => {
                      return {
                        type: i.name,
                        value: groupBy(priorityIds)[i.id],
                      };
                    }) ?? []
                  }
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col md={12} xs={24}>
                <Statistic
                  title={t("dashboard.inprogressTasks")}
                  value={
                    ticketList?.data?.data.filter(
                      (value: ITicket) => !value.closedOn
                    ).length
                  }
                  suffix={`/ ${ticketList?.data?.data?.length}`}
                />
              </Col>
              <Col md={12} xs={24}>
                <Statistic
                  title={t("dashboard.closedProjects")}
                  value={
                    ticketList?.data?.data.filter(
                      (value: ITicket) => value.closedOn
                    ).length
                  }
                  suffix={`/ ${ticketList?.data?.data?.length}`}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {/*<Row gutter={[20, 20]}>
        <Col xl={10}>
          <Card
            title={<Title level={3}>{t("dashboard.projectStat")}</Title>}
            style={{
              border: "2px solid",
              fontSize: "1vw",
              borderColor: "#c471b2",
            }}
          >
            <Row gutter={20}>
              <Col span={8}>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  {t("dashboard.activeProjects")}
                </Title>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  {t("dashboard.closedProjects")}
                </Title>
              </Col>
              <Col span={8}>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  6 / 8
                </Title>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  2 / 8
                </Title>
              </Col>
              <Col span={8}>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  {t("dashboard.percentage")}
                </Title>

                <Progress
                  strokeColor={{
                    from: "#c79dbd",
                    to: "#ab0988",
                  }}
                  percent={25}
                  status="active"
                />
              </Col>
            </Row>
          </Card>
          <Title level={5}></Title>
          <Card
            title={<Title level={3}>{t("dashboard.cardTitleTasksStat")}</Title>}
            style={{ border: "2px solid", borderColor: "#c471b2" }}
          >
            <Row gutter={22}>
              <Col span={6}>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  {t("dashboard.totalTasks")}
                </Title>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  {t("dashboard.overdueTasks")}
                </Title>
              </Col>
              <Col span={6}>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  42
                </Title>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  8
                </Title>
              </Col>
              <Col span={6}>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  {t("dashboard.completedTasks")}
                </Title>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  {t("dashboard.inprogressTasks")}
                </Title>
              </Col>
              <Col span={6}>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  20
                </Title>
                <Title style={{ fontSize: "1vw" }} level={4}>
                  14
                </Title>
              </Col>
            </Row>
            <Progress
              strokeColor={{
                "0%": "#c79dbd",
                "100%": "#ab0988",
              }}
              percent={47.6}
              status="active"
            />
          </Card>
        </Col>
        <Col span={6} xl={6}>
          <Card
            title={<Title level={3}>{t("dashboard.cardTitleProject")}</Title>}
            style={{ border: "2px solid", borderColor: "#c471b2" }}
            hoverable
            onClick={() => list("projects")}
          >
            <FundProjectionScreenOutlined style={{ fontSize: "280%" }} />

            <Text style={{ textAlign: "right", fontSize: "2vw" }}>8</Text>
          </Card>

          <Title level={5}></Title>

          <Card
            hidden={permissionsData?.includes("editor")}
            title={<Title level={3}>{t("dashboard.cardTitleTasks")}</Title>}
            style={{ border: "2px solid", borderColor: "#c471b2" }}
            hoverable
            onClick={() => list("tasks")}
          >
            <FormOutlined style={{ fontSize: "280%" }} />

            <Text style={{ textAlign: "right", fontSize: "2vw" }}>42</Text>
          </Card>

          <Card
            hidden={permissionsData?.includes("admin")}
            title={<Title level={3}>{t("dashboard.cardTitleTasks2")}</Title>}
            style={{ border: "2px solid", borderColor: "#c471b2" }}
            hoverable
            onClick={() => list("actions")}
          >
            <FormOutlined style={{ fontSize: "280%" }} />

            <Text style={{ textAlign: "right", fontSize: "2vw" }}>6</Text>
          </Card>
        </Col>

        <Col xl={6}>
          <Card
            hidden={permissionsData?.includes("admin")}
            title={<Title level={3}>{t("dashboard.timeline")}</Title>}
            style={{ border: "2px solid", borderColor: "#c471b2" }}
          >
            <Timeline mode="left">
              <Timeline.Item>
                <Button onClick={() => list("projects")} type="text">
                  <Text style={{ fontSize: "0.8vw" }}>
                    {t("dashboard.timeline1")}
                  </Text>
                </Button>
              </Timeline.Item>
              <Timeline.Item>
                <Button onClick={() => list("actions")} type="text">
                  <Text style={{ fontSize: "0.8vw" }}>
                    {t("dashboard.timeline2")}
                  </Text>
                </Button>
              </Timeline.Item>
              <Timeline.Item
                dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}
                color="red"
              >
                <Button onClick={() => list("actions")} type="text">
                  <Text style={{ fontSize: "0.8vw" }}>
                    {t("dashboard.timeline3")}
                  </Text>
                </Button>
              </Timeline.Item>
              <Timeline.Item
                dot={<CheckCircleOutlined style={{ fontSize: "16px" }} />}
                color="green"
              >
                <Button onClick={() => list("actions")} type="text">
                  <Text style={{ fontSize: "0.8vw" }}>
                    {t("dashboard.timeline4")}
                  </Text>
                </Button>
              </Timeline.Item>
            </Timeline>
          </Card>

          <Card
            hidden={permissionsData?.includes("editor")}
            title={<Title level={3}>{t("dashboard.timeline")}</Title>}
            style={{ border: "2px solid", borderColor: "#c471b2" }}
          >
            <Timeline mode="left">
              <Timeline.Item>
                <Button onClick={() => list("projects")} type="text">
                  <Text style={{ fontSize: "0.8vw" }}>
                    {t("dashboard.timeline1")}
                  </Text>
                </Button>
              </Timeline.Item>
              <Timeline.Item>
                <Button onClick={() => list("tasks")} type="text">
                  <Text style={{ fontSize: "0.8vw" }}>
                    {t("dashboard.timeline2")}
                  </Text>
                </Button>
              </Timeline.Item>
              <Timeline.Item
                dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}
                color="red"
              >
                <Button onClick={() => list("tasks")} type="text">
                  <Text style={{ fontSize: "0.8vw" }}>
                    {t("dashboard.timeline3")}
                  </Text>
                </Button>
              </Timeline.Item>
              <Timeline.Item
                dot={<CheckCircleOutlined style={{ fontSize: "16px" }} />}
                color="green"
              >
                <Button onClick={() => list("tasks")} type="text">
                  <Text style={{ fontSize: "0.8vw" }}>
                    {t("dashboard.timeline4")}
                  </Text>
                </Button>
              </Timeline.Item>
            </Timeline>
          </Card>
        </Col>
      </Row>
            */}
    </>
  );
};
