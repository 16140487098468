//export const API_URL = "/api";

//export const HOST_URL = "http://127.0.01:5000";
//export const API_URL = "http://127.0.01:5000/api";

export const HOST_URL = "//office.sqs.hu/SQSProjects";
export const API_URL = "//office.sqs.hu/SQSProjects/api";
//export const HOST_URL = "http://127.0.01:81";
//export const API_URL = "http://127.0.01:81/api";
//export const HOST_URL = "http://192.168.207.11:81";
//export const API_URL = "http://192.168.207.11:81/api";
//export const HOST_URL = "http://actions.mambaby.com:81";
//export const API_URL = "http://actions.mambaby.com:81/api";
//export const HOST_URL = "https://actions.mambaby.com/host";
//export const API_URL = "https://actions.mambaby.com/host/api";


export const TENANT_KEY = "refine-tenant";
export const TOKEN_KEY = "refine-auth";
export const REFRESH_KEY = "refine-auth-refresh";
export const ROLES_KEY = "refine-auth-roles";
export const dateFormat = "YYYY-MM-DD";