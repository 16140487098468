import React from "react";
import {
  IResourceComponentsProps,
  useMany,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import {
  Comment,
  Show,
  Typography,
  TagField,
  TextField,
  Input,
  Descriptions,
  Card,
  ImageField,
  FileField
} from "@pankod/refine-antd";
import { IComment, ITicket, IUser } from "interfaces";
import { TicketStatus } from "constants/pm";
import moment from "moment";
import { HOST_URL } from "apiconstants";

const { Title } = Typography;

type CommentListProps = {
  comments: IComment[];
};

export const TicketShow: React.FC<IResourceComponentsProps> = (props: any) => {
  const { queryResult } = useShow<ITicket>(
    {
      resource: "tickets",

    }
  );
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const t = useTranslate();

  const { data: users } = useMany<IUser>({
    resource: "users",
    ids: [],
  });

  const CommentList: React.FC<CommentListProps> = ({ comments }) => {
    let items = comments.map((comment) => {
      return (
        <div key={comment.id} className="border-l pl-6">
          <Comment
            key={comment.id}
            content={<p>{comment.content}</p>}
            author={
              users?.data.find(
                (item) =>
                  item.id === comment?.createdBy?.toString().toLowerCase()
              )?.email
            }
            datetime={moment(comment?.createdOn).fromNow()}
          >
            {comment.replies && CommentList({ comments: comment.replies })}
          </Comment>
        </div>
      );
    });

    return <>{items}</>;
  };

  //alert(JSON.stringify(record?.meshChecked));

  return (

    <Show resource="tickets"
      breadcrumb={false}
      headerButtons={({ defaultButtons }) => (
        <>
          {
            //defaultButtons
          }
        </>
      )}
      isLoading={isLoading}
    >
      <Descriptions
        bordered
        size="small"
        layout="vertical"
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      //title="Custom Size"

      //extra={<Button type="primary">Edit</Button>}
      >
        <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
          label={t("tickets.fields.taskNumber")}
        >
          {record?.title}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
          label={t("tickets.fields.projectName")}
        >
          {record?.project.name}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
          label={t("tickets.fields.owner")}
        >
          <Text>
            {
              users?.data?.find(
                (item) =>
                  item?.id === record?.acceptantId?.toString().toLowerCase()
              )?.email
            }
          </Text>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
          label={t("tickets.fields.taskStatus")}
        >
          <Text>
            {record?.status ? TicketStatus[+record?.status] : TicketStatus[0]}
          </Text>
        </Descriptions.Item>
        
        <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
          label="MESH"
        >
          {record?.meshChecked === true ? <Text>{t("tickets.fields.meshTrue")}</Text> : <Text>{t("tickets.fields.meshFalse")}</Text>}
          
          
        </Descriptions.Item>

        <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
          label={t("tickets.fields.description")}
        >
          <TextArea
            value={record?.description}
            disabled
            autoSize={{ minRows: 3, maxRows: 6 }}
            style={{ height: "100%" }}
            showCount
          />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
      bordered
        size="small"
        layout="vertical"
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
      <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}>
          <Card
            style={{ width: "100%", height: 200 }}
            title={t("projects.fields.uploadedFilesCard")}
            size="default"
          >
            {record?.attachments?.map((file: any) => {
              return (
                <FileField
                  target="_blank"
                  key={file.name}
                  src={`${HOST_URL}/${file.attachmentUrl}`}
                  //value={`${HOST_URL}/${file.attachmentUrl}`}
                  title={file.name}
                ></FileField>

                /*<ImageField
                  key={file.name}
                  //src={file.attachmentUrl}
                  value={`${HOST_URL}/${file.attachmentUrl}`}
                  title={file.name}
                  width="8rem"
                ></ImageField>*/
              );
            })}
          </Card>
        </Descriptions.Item>
      </Descriptions>
      
      {record?.comments !== undefined && record?.comments?.length > 0 && (
        <Card title={t("tickets.fields.comments")}>
          <CommentList
            comments={record?.comments?.filter((comment) => {
              return comment.parentId === null;
            })}
          />
        </Card>
      )}
    </Show>
  );
};
