import React, { useEffect, useMemo, useState } from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useMany,
  CrudFilters,
  HttpError,
  useShow,
  BaseKey,
  useGetIdentity,
  usePermissions,
  getDefaultFilter,
  useExport,
  CrudFilter,
  useUpdateMany
} from "@pankod/refine-core";
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
  MarkdownField,
  DateField,
  TextField,
  useModalForm,
  CreateButton,
  useDrawerForm,
  Create,
  useModal,
  useSelect,
  TagField,
  ExportButton,
  Badge,
  BadgeProps,
  Icons,
  Tabs
} from "@pankod/refine-antd";
import { Table, Space, Modal, Form, FormProps, Row, Col, Input, Card, Select, Typography, Radio, Button, Drawer, RadioChangeEvent, Grid, DatePickerProps, DatePicker, TimePickerProps, TimePicker, InputNumber, Popover } from "antd";
import moment, { Moment } from "moment";
import Calendar from "antd/lib/calendar";
import "moment/locale/hu";
//import { IEsemenyek, IKoltseghelyek, IPartnerFilter, IPartnerek } from "interfaces";
import { Tooltip } from "antd/lib";
import { ICost, IPartner, ITicket, IUser } from "interfaces";
import { IUserCostConnection, IUserCostConnectionFilter } from "interfaces/usercostconnection";
import { PartnerSelect } from "components/partner/PartnersSelect";
import { CostSelect } from "components/partner/CostsSelect";
//import { PartnerSelect } from "components/PartnerSelect";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TOKEN_KEY } from "apiconstants";
import axios from "axios";
import { useForm } from 'antd/es/form/Form';
import { CostSelect2 } from "pages/costs/CostSelect2";
import { ICalendar } from "interfaces/calendar";

moment.locale('hu');

export const WorkList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  moment.locale('hu');
  const customLocaleData = {
    weekdays: ['hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat', 'vasárnap'],
    weekdaysShort: ['H', 'K', 'Sz', 'Cs', 'P', 'Szo', 'V'],
    weekdaysMin: ['H', 'K', 'Sz', 'Cs', 'P', 'Szo', 'V'],
    months: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember', 'október', 'november', 'december'],
    monthsShort: ['jan.', 'febr.', 'márc.', 'ápr.', 'máj.', 'jún.', 'júl.', 'aug.', 'szept.', 'okt.', 'nov.', 'dec.'],
  };

  moment.updateLocale('hu', customLocaleData);

  const [valueCal, setValue] = useState(() => moment());
  const [selectedValue, setSelectedValue] = useState(() => moment());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (newValue: Moment) => {
    setIsModalOpen(true);
    setValue(newValue);
    setSelectedValue(newValue);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onPanelChange = (newValue: Moment) => {
    setValue(newValue);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const { modalProps, formProps, show } = useModalForm({
    action: "create",
  });

  const { formProps: drawerForm, drawerProps, show: drawerShow, saveButtonProps } = useDrawerForm({
    action: "create",
    resource: "usercostconnections",
  });

  const { formProps: drawerFormCalendar, drawerProps: drawerPropsCalendar, show: drawerShowCalendar, saveButtonProps: saveButtonPropsCalendar } = useDrawerForm({
    action: "create",
    resource: "calendar",
    redirect: false
  });

  const [form] = Form.useForm();

  const handleOkFields = async (e: any) => {
    form.resetFields();
  };


  const { formProps: drawerFormCost, drawerProps: drawerPropsCost, show: drawerShowCost, saveButtonProps: saveButtonPropsCost } = useDrawerForm({
    action: "create",
    resource: "costs",
    redirect: false
  });

  const { selectProps: selectPropsKh, queryResult: queryResultKh, defaultValueQueryResult: defaultValueQueryResultKh } =
    useSelect<ICost>({
      resource: "partners",
      optionLabel: "name",
      optionValue: "id",
      sort: [
        {
          field: "name",
          order: "asc"
        }
      ]
    });



  const allOptionsKh = [
    ...(queryResultKh.data?.data || []),
    ...(defaultValueQueryResultKh.data?.data || []),
  ].filter((item, index, self) =>
    index === self.findIndex((t) => (
      t.id === item.id
    ))
  );


  const { selectProps: selectPropsUser, queryResult: queryResultUser, defaultValueQueryResult: defaultValueQueryResultUser } =
    useSelect<IUser>({
      resource: "users",
      optionLabel: "email",
      optionValue: "id",
    });

  const allOptionsUser = [
    ...(queryResultUser.data?.data || []),
    ...(defaultValueQueryResultUser.data?.data || []),
  ].reduce((unique: IUser[], item) => {
    return unique.some(existingItem => existingItem.id === item.id) ? unique : [...unique, item];
  }, []);


  const { data: permissionsData } = usePermissions();


  const [selectedWorker, setSelectedWorker] = useState<string | undefined>(undefined);

  const { TextArea } = Input;
  const { data: user } = useGetIdentity();
  console.log(JSON.stringify(permissionsData))
  //alert(JSON.stringify(user?.id))
  const { tableProps, filters } = useTable<IUserCostConnection, HttpError>({
    resource: "usercostconnections",
    syncWithLocation: true,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const expfilters: CrudFilters = [
        {
          field: "paginate",
          operator: "eq",
          value: false,
        },
      ];

      setExportFilter(expfilters);
      setSearchExportFilter(exportFilters);

      return filters;
    },
    permanentFilter:

      //permissionsData?.some((r: any) => (r.roleName === 'SQSAdmin' && r.enabled === true)) 
      user?.firstName + user?.lastName === "LászlóKarakó" || user?.firstName + user?.lastName === "LászlóŐsze"
        ?
        [
          {
            field: "date", operator: "eq", value: selectedValue.format("YYYY-MM-DD"),
          },
          ...(selectedWorker
            ? [{
              field: "workerName",
              operator: "eq",
              value: selectedWorker,
            }] as CrudFilters
            : []
          )
        ] :
        [
          {
            field: "date", operator: "eq", value: selectedValue.format("YYYY-MM-DD"),
          },

          {
            field: "workerName", operator: "eq", value: `${user?.firstName} ${user?.lastName}`  //Kezeljük, hogy a nem Admin felhasználó csak a saját rögzített feladatait lássa a naptárban
          },
        ]


  });

  const { tableProps: tablePropsCalendar, filters: filtersCalendar } = useTable<ICalendar, HttpError>({
    resource: "calendar",
    syncWithLocation: true,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const expfilters: CrudFilters = [
        {
          field: "paginate",
          operator: "eq",
          value: false,
        },
      ];

      setExportFilter(expfilters);
      setSearchExportFilter(exportFilters);

      return filters;
    },
    permanentFilter:

      //permissionsData?.some((r: any) => (r.roleName === 'SQSAdmin' && r.enabled === true)) 
      user?.firstName + user?.lastName === "LászlóKarakó" || user?.firstName + user?.lastName === "LászlóŐsze"
        ?
        [
          {
            field: "date", operator: "eq", value: selectedValue.format("YYYY-MM-DD"),
          },
        ] :
        [
          {
            field: "date", operator: "eq", value: selectedValue.format("YYYY-MM-DD"),
          },

          {
            field: "workerName", operator: "eq", value: `${user?.firstName} ${user?.lastName}`
          },
        ]


  });

  const [exportFilters, setExportFilter] = React.useState<CrudFilter[]>([
    {
      field: "paginate",
      operator: "eq",
      value: false,
    },
  ]);

  const [searchExportFilters, setSearchExportFilter] = React.useState<CrudFilter[]>([
    {
      field: "paginate",
      operator: "eq",
      value: false,
    },
  ]);
  const { data: tickets } = useMany<ITicket>({
    resource: "tickets",
    ids: [],
  });

  const { data: partners } = useMany<IPartner>({
    resource: "partners",
    ids: [],
  });

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
  };

  const onChangeTime: TimePickerProps['onChange'] = (time: any) => {
    console.log(time);
  };


  const { triggerExport, isLoading } = useExport<IUserCostConnection>({
    resourceName: "usercostconnections",
    filters: exportFilters,
    mapData: (data) => {
      return {
        Partner: partners?.data.find((item) => item.id === data.partnerId)?.name,
        Feladat: tickets?.data.find((item) => item.id === data.ticketId)?.title,
        Dolgozó: data.workerName,
        Óraszám: data.workedHours,
        Dátum: moment(data.date).format("YYYY. M. D"),
        Megjegyzés: data.note
      };
    },
    exportOptions: {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      title: "Óraelszámolás",
    },
  });

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
    const filters: CrudFilters = [
      {
        field: "paginate",
        operator: "eq",
        value: false,
      },
    ];
    if (selectedRowKeys.length > 0) {
      selectedRowKeys.map((selected) => {
        filters.push({
          field: "id",
          operator: "eq",
          value: `${selected}`,
        });
      });
      setExportFilter(filters);
      alert(JSON.stringify(filters))
    } else {
      //alert(JSON.stringify(searchExportFilters));
      setExportFilter(searchExportFilters);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [eventsData, setEventsData] = useState<{ [key: string]: boolean }>({});
  const [calendarData, setCalendarData] = useState<{
    [key: string]: {
      workerName: string | undefined; type: string
    }
  }>({});
  
  // Function to update calendarData based on selected worker
  const updateCalendarData = (selectedWorker: string | undefined) => {
    const updatedData = Object.keys(calendarData).reduce((acc, key) => {
      const item = calendarData[key];
      if (!selectedWorker || item.workerName === selectedWorker) {
        acc[key] = item;
      }
      return acc;
    }, {} as { [key: string]: { workerName: string | undefined; type: string } });
  
    setCalendarData(updatedData);
  };
  
 
  
  const fetchEventsData = async (workerName?: string) => {
    try {
      //Local
      //const response = await axios.get('https://localhost:5001/api/usercostconnections', {

      //Server
      const response = await axios.get('https://office.sqs.hu/SQSProjects/api/usercostconnections', {
  
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });
  
      const data = response.data;
      const formattedData = data.reduce((acc: any, event: any) => {
        const dateStr = moment(event.date).format('YYYY-MM-DD');
  
        // Csak akkor adja hozzá az adatokat, ha a workerName megegyezik a kiválasztott munkavállalóval, vagy ha nincs kiválasztva munkavállaló
        if (!workerName || event.workerName === workerName) {
          acc[dateStr] = true; // Mark the date as having events
        }
  
        return acc;
      }, {});
  
      setEventsData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
 
  
  const fetchCalendarData = async (workerName?: string, showPublicHolidays = true) => {
    try {
        //Local
        //const response = await axios.get('https://localhost:5001/api/calendar', {
        
        //Server
        const response = await axios.get('https://office.sqs.hu/SQSProjects/api/calendar', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        },
      });
  
      const data = response.data;
      const formattedData = data.reduce((acc: any, item: any) => {
        const dateStr = moment(item.date).format('YYYY-MM-DD');
  
        // Ha a workerName nem egyezik, de szeretnénk megjeleníteni az ünnepnapokat
        if (!workerName || item.workerName === workerName || (showPublicHolidays && !item.workerName)) {
          acc[dateStr] = { type: item.type, workerName: item.workerName || undefined };
        }
  
        return acc;
      }, {});
  
      setCalendarData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  
  

  useEffect(() => {
    fetchEventsData();
    fetchCalendarData();
  }, []);
  
  useEffect(() => {
    async function fetchData() {
      await fetchEventsData(selectedWorker);  // Rögzített munkaórák lekérdezése
      await fetchCalendarData(selectedWorker); // Szabadságok lekérdezése
    }
    fetchData();
  }, [selectedWorker]);
  
  
  
  
  useEffect(() => {
    updateCalendarData(selectedWorker); 
  }, [selectedWorker]);
  


  const dateCellRender = (value: Moment) => {
    const dateStr = value.format('YYYY-MM-DD');
    const hasEvents = eventsData[dateStr];
    const calendarItem = calendarData[dateStr];
  
    return (
      <div>
        {/* Szabadságok megjelenítése */}
        {calendarItem && calendarItem.type && (
          <p><Badge status="warning" text={calendarItem.type} /></p>
        )}
        {/* Rögzített munkaórák megjelenítése */}
        {hasEvents && (
          <p><Badge status="error" text="Rögzített adatok" /></p>
        )}
      </div>
    );
  };
  
  


  const dateCellRender2 = (value: Moment) => {
    const dateStr = value.format('YYYY-MM-DD');
    const calendarItem = calendarData[dateStr];

    return (
      <div>
        {calendarItem && (
          <p><Badge status="warning" text={calendarItem.type} /></p>
        )}
      </div>
    );
  };

  const { TabPane } = Tabs;

  function callback(key: any) {
    console.log(key);
  }

  
  return (
    <>
      <List
        createButtonProps={{
          onClick: () => {
            drawerShow();
          }
        }}
        headerButtons={({ defaultButtons }) => (
          <>
            {<Button icon={<Icons.PlusSquareOutlined />} onClick={() => { drawerShowCalendar() }}>Szabadság rögzítése</Button>}
            {defaultButtons}
          </>
        )}
      >

        {/*--------------NAPTÁR---------------*/}
        {/*<Filter formProps={formProps} />*/}

        <Modal width={"60%"} title={<DateField style={{ marginLeft: 10, fontSize: 20 }} value={selectedValue.format("YYYY-MM-DD")} />} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Óraelszámolás" key="1">
              <Table {...tableProps} rowKey="id">

                <Table.Column
                  dataIndex="workerName"
                  title="Dolgozó neve"
                />

                <Table.Column
                  dataIndex="partnerId"
                  title="Partner"
                  render={(value: string) => {
                    return (
                      <TagField

                        value={`${partners?.data.find((item) => item.id === value)?.name}`}
                      />
                    );
                  }}
                />

                <Table.Column
                  dataIndex="ticketId"
                  title="Feladat"
                  render={(value: string) => {
                    return (
                      <TagField
                        value={`${tickets?.data.find((item) => item.id === value)?.subprojects}`}
                      />
                    );
                  }}
                />

                <Table.Column
                  width={80}
                  dataIndex="workedHours"
                  title="Ledolgozott óra"
                />

                <Table.Column
                  width={80}
                  dataIndex="note"
                  title="Feladat leírása"
                  render={(value: any) => (
                    <Popover content={value != null ? value : "Nincs leírás rögzítve"} title="Megjegyzés">
                      <Button type="primary">Megtekintés</Button>
                    </Popover>
                  )}
                />
              </Table>
            </TabPane>
            <TabPane tab="Szabadságok" key="2">
              <Table {...tablePropsCalendar} rowKey="id">

                <Table.Column
                  dataIndex="workerName"
                  title="Dolgozó neve"
                />

                <Table.Column
                  dataIndex="date"
                  title="Dátum"
                  render={(text) => moment(text).format('YYYY-MM-DD')}
                />

              </Table>
            </TabPane>
          </Tabs>
        </Modal>

        {user?.firstName + user?.lastName === "LászlóKarakó" || user?.firstName + user?.lastName === "LászlóŐsze" ?
          <>
            <Card title="Szűrés a kiválasztott dolgozóra">
              <Select
                placeholder="Dolgozó kiválasztása"
                options={allOptionsUser.map((p) => ({
                  label: `${p.lastName} ${p.firstName}`,
                  value: `${p.firstName} ${p.lastName}`,
                }))}
                allowClear
                onChange={(value) => {
                  console.log('Selected worker:', value); // Debugging line
                  setSelectedWorker(value);
                }}
              />
            </Card>
            <br></br>
            <br></br>

            <Calendar dateCellRender={dateCellRender} value={valueCal} onSelect={showModal} onPanelChange={onPanelChange}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                const start = 0;
                const end = 12;
                const monthOptions = [];

                let current = value.clone();
                const localeData = value.localeData();
                const months = [];
                current.locale('hu');
                for (let i = 0; i < 12; i++) {
                  current = current.month(i);
                  months.push(localeData.months(current));
                }

                for (let i = start; i < end; i++) {
                  monthOptions.push(
                    <Select.Option key={i} value={i} className="month-item">
                      {months[i]}
                    </Select.Option>,
                  );
                }

                const year = value.year();
                const month = value.month();
                const options = [];
                for (let i = year - 10; i < year + 10; i += 1) {
                  options.push(
                    <Select.Option key={i} value={i} className="year-item">
                      {i}
                    </Select.Option>,
                  );
                }
                return (
                  <div style={{ padding: 8 }}>
                    <Typography.Title level={4}>Naptár</Typography.Title>
                    <Row gutter={8}>
                      <Col>
                        <Radio.Group
                          size="small"
                          onChange={(e) => onTypeChange(e.target.value)}
                          value={type}
                        >
                          <Radio.Button value="month">Hónap</Radio.Button>
                        </Radio.Group>
                      </Col>
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          className="my-year-select"
                          value={year}
                          onChange={(newYear) => {
                            const now = value.clone().year(newYear);
                            onChange(now);
                          }}
                        >
                          {options}
                        </Select>
                      </Col>
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          value={month}
                          onChange={(newMonth) => {
                            const now = value.clone().month(newMonth);
                            onChange(now);
                          }}
                        >
                          {monthOptions}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                );
              }}
            />
          </>
          :
          <Calendar dateCellRender={dateCellRender2} value={valueCal} onSelect={showModal} onPanelChange={onPanelChange}
            headerRender={({ value, type, onChange, onTypeChange }) => {
              const start = 0;
              const end = 12;
              const monthOptions = [];

              let current = value.clone();
              const localeData = value.localeData();
              const months = [];
              current.locale('hu');
              for (let i = 0; i < 12; i++) {
                current = current.month(i);
                months.push(localeData.months(current));
              }

              for (let i = start; i < end; i++) {
                monthOptions.push(
                  <Select.Option key={i} value={i} className="month-item">
                    {months[i]}
                  </Select.Option>,
                );
              }

              const year = value.year();
              const month = value.month();
              const options = [];
              for (let i = year - 10; i < year + 10; i += 1) {
                options.push(
                  <Select.Option key={i} value={i} className="year-item">
                    {i}
                  </Select.Option>,
                );
              }
              return (
                <div style={{ padding: 8 }}>
                  <Typography.Title level={4}>Naptár</Typography.Title>
                  <Row gutter={8}>
                    <Col>
                      <Radio.Group
                        size="small"
                        onChange={(e) => onTypeChange(e.target.value)}
                        value={type}
                      >
                        <Radio.Button value="month">Hónap</Radio.Button>
                      </Radio.Group>
                    </Col>
                    <Col>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        value={year}
                        onChange={(newYear) => {
                          const now = value.clone().year(newYear);
                          onChange(now);
                        }}
                      >
                        {options}
                      </Select>
                    </Col>
                    <Col>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        value={month}
                        onChange={(newMonth) => {
                          const now = value.clone().month(newMonth);
                          onChange(now);
                        }}
                      >
                        {monthOptions}
                      </Select>
                    </Col>
                  </Row>
                </div>
              );
            }}
          />}

        <br></br>

      </List>
      <Drawer {...drawerProps}>
        <Create saveButtonProps={saveButtonProps}>
          <Form {...drawerForm} layout="vertical">

            <Form.Item
              label="Dolgozó neve"
              name="workerName"
              initialValue={`${user?.firstName} ${user?.lastName}`}
            >
              <Input />

            </Form.Item>

            <Form.Item
              label="Partner"
              name="partnerId"
              rules={[
                {
                  required: true,
                  message: "A partner megadása kötelező!"
                },
              ]}
            >
              {/*
                  <Select
                style={{ width: "100%" }}
                {...selectPropsKh}
                options={allOptionsKh.map((p) => ({
                label: `${p.partnercode} - ${p.name} - ${p.subprojects}`,
                value: p.id,
              }))}
            />
            */}
              <PartnerSelect />
            </Form.Item>

            <Form.Item
              label="Feladat"
              name="ticketId"
              rules={[
                {
                  required: true,
                  message: "A feladat megadása kötelező!"
                },
              ]}
            >
              <CostSelect />
            </Form.Item>

            <Form.Item
              label="Dátum"
              name="date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} onChange={onChange} />
            </Form.Item>

            <Form.Item
              label="Ledolgozott óra"
              name="workedHours"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Feladat leírása"
              name="note"
            >
              <TextArea rows={4} />
            </Form.Item>
          </Form>
        </Create>
      </Drawer>

      <Drawer {...drawerPropsCalendar}>
        <Create saveButtonProps={saveButtonPropsCalendar}>
          <Form {...drawerFormCalendar} layout="vertical">

            <Form.Item
              label="Dolgozó neve"
              name="workerName"
              initialValue={`${user?.firstName} ${user?.lastName}`}
            >
              <Input />

            </Form.Item>

            <Form.Item
              label="Dátum"
              name="date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} onChange={onChange} />
            </Form.Item>

            <Form.Item
              hidden
              initialValue={"Szabadság"}
              name="type"
            >
              <Input />
            </Form.Item>
          </Form>
        </Create>
      </Drawer>
    </>
  );
};
