import { Avatar, Space, TextField, Tooltip } from "@pankod/refine-antd";
import { useMany, useShow } from "@pankod/refine-core";
import {
    Document,
    Image,
    Page,
    StyleSheet,
    View,
    Text,
    PDFViewer,
    Font,
} from "@react-pdf/renderer";
import { API_URL, HOST_URL } from "apiconstants";
import { IPlace, IProject, ITicket, IUser } from "interfaces";
import dayjs from 'dayjs'
import {
    InboxOutlined,
    UploadOutlined,
    UserOutlined,
    AntDesignOutlined,
    FormOutlined,
    Loading3QuartersOutlined,
    CheckOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
    ExceptionOutlined,
} from "@ant-design/icons";

type UsersProps = {
    users: IUser | undefined;
};

type PdfProps = {
    record: IProject | undefined;
    users: IUser | undefined;
};

export const PdfLayout: React.FC<PdfProps> = ({ record }) => {
    const { data: users } = useMany<IUser>({
        resource: "users",
        ids: [],
    });

    const { data: place } = useMany<IPlace>({
        resource: "places",
        ids: [],
    });

    return (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page style={styles.page} wrap={false} size="A4">
                    {/*---------- Alapadatok ----------*/}
                    <View style={styles.font}>
                        <View style={styles.tableHeader}>
                            <View style={[
                                styles.tableHeaderItem,
                                { width: "33.3%" },
                            ]}>
                                <Image src={'/images/logo.png'} style={{ width: "60%", height: "auto", display: "flex", marginLeft: "auto", marginRight: "auto" }} />
                            </View>

                            <View
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "33.3%" },
                                ]}
                            >
                                <Space>
                                    <Text style={[styles.boldText, { marginTop: 10, marginLeft: 5, marginRight: 5 }]}>HELYESBÍTŐ ÉS MEGELŐZŐ INTÉZKEDÉSEK JEGYZŐKÖNYVE</Text>
                                    <Text style={[styles.boldText, { marginTop: 15, marginLeft: 10, marginRight: 10 }]}>CORRECTIVE AND PREVENTIVE ACTIONS REPORT</Text>
                                </Space>
                            </View>

                            <View
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "33.3%" },
                                ]}
                            >
                                <Text style={[styles.boldText, { marginTop: 45, fontSize: 14 }]}>MKE 8.5 B03</Text>
                            </View>
                        </View>
                    </View>


                    <View style={styles.font}>
                        <View style={styles.tableHeader}>
                            <View
                                style={[
                                    styles.tableHeaderItemBottomBorder,
                                    { width: "50%" },
                                ]}
                            >   <Space>
                                    <Text style={[styles.boldText]}>Jegyzőkönyvet vezető személy /</Text>
                                    <Text style={[styles.boldText]}>Responsible person of the report:</Text>
                                    <Text style={{ fontSize: 12 }}>{users?.data.find((item) => item.id === record?.ownerId)?.lastName} {users?.data.find((item) => item.id === record?.ownerId)?.firstName}</Text>
                                </Space>
                            </View>

                            <View
                                style={[
                                    styles.tableHeaderItemBottomBorder2,
                                    { width: "50%" },
                                ]}
                            >
                                <Space>
                                    <Text style={[styles.boldText]}>Nem megfelelőségi azonosítószám /</Text>
                                    <Text style={[styles.boldText]}>ID of non-compliance:</Text>
                                    <Text style={{ fontSize: 12 }}>{record?.id}</Text>
                                </Space>
                            </View>
                        </View>
                    </View>

                    <View style={styles.dividerSM} />

                    <View style={styles.font}>
                        <View style={styles.tableHeader}>
                            <View
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "100%" },
                                ]}
                            >
                                <Space>
                                    <Text style={[styles.boldText]}>Termék/szolgáltatás/folyamat megnevezése, azonosítása / Identification of product/process:</Text>
                                    <Text style={{ fontSize: 12 }}>{record?.name}</Text>
                                </Space>
                            </View>
                        </View>
                    </View>

                    <View style={styles.font}>
                        <View style={styles.tableHeader}>
                            <View
                                style={[
                                    styles.tableHeaderItemBottomBorder,
                                    { width: "50%" },
                                ]}
                            >
                                <Space>
                                    <Text style={[styles.boldText]}>Észlelés időpontja / Detection date:</Text>
                                    <Text style={{ fontSize: 12 }}>{dayjs(record?.detectionDate).format(' YYYY-MM-DD')}</Text>
                                </Space>
                            </View>

                            <View
                                style={[
                                    styles.tableHeaderItemBottomBorder2,
                                    { width: "50%" },
                                ]}
                            >
                                <Space>
                                    <Text style={[styles.boldText]}>Észlelés helye / Detection machine, place:</Text>
                                    <Text style={{ fontSize: 12 }}>{place?.data.find((item) => item?.id === record?.detectionPlaceId)?.name}</Text>
                                </Space>
                            </View>
                        </View>
                    </View>
                    {/*---------- Alapadatok ----------*/}



                    {/*---------- Hiba részletes leírása ----------*/}
                    <View style={styles.font}>
                        <View style={styles.table}>
                            <View
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "100%" },
                                ]}
                            ><>
                                    <Space>
                                        <Text style={[styles.boldText, { marginLeft: 10, marginRight: 10 }]}>A hiba pontos leírása, észlelési körülmények /</Text>
                                        <Text style={[styles.boldText, { marginLeft: 10, marginRight: 10 }]}>Condition and description of the failure, non-conformity:</Text>
                                        <View style={styles.dividerSM} />

                                        <Text style={{ fontSize: 12, marginLeft: 10, marginRight: 10 }}>{record?.description}</Text>
                                        <View style={styles.dividerSM2} />
                                        <View style={styles.tableHeader}>
                                            <Text
                                                style={[
                                                    styles.tableHeaderItemTopBottom, styles.boldText,
                                                    { width: "25%" },
                                                ]}
                                            >
                                                Dátum / Date
                                            </Text>
                                            <View
                                                style={[
                                                    styles.tableCol2,
                                                    { width: "75%" },
                                                ]}
                                            >
                                                <Text style={[styles.boldText]}>Azonnali intézkedések / Immediate actions:</Text>
                                            </View>
                                        </View>
                                        {record?.tickets.map((item: ITicket) => {
                                            return (
                                                <>
                                                    {item?.type === 0 ?
                                                        <View key={item.id} style={styles.tableRow}>
                                                            <Text
                                                                style={[
                                                                    styles.tableCol2,
                                                                    { width: "25%", textAlign: "center" },
                                                                ]}
                                                            >
                                                                {dayjs(item?.dueDate).format('YYYY-MM-DD')}
                                                            </Text>
                                                            <Text
                                                                style={[
                                                                    styles.tableCol2,
                                                                    { width: "75%" },
                                                                ]}
                                                            >
                                                                {item.description}
                                                            </Text>
                                                        </View>
                                                        : ""}
                                                </>
                                            );
                                        })}
                                        <Text style={[styles.boldText, { marginLeft: 10, marginRight: 10 }]} >Felelős: {users?.data.find((item) => item.id === record?.ownerId)?.lastName} {users?.data.find((item) => item.id === record?.ownerId)?.firstName}</Text>
                                        <Text style={[styles.boldText, { marginLeft: 10, marginRight: 10 }]}>Dátum: {dayjs(record?.startDate).format(' YYYY-MM-DD')}</Text>

                                        <View style={styles.tableRow}>
                                            <View
                                                style={[
                                                    styles.tableHeaderItemNoBorder,
                                                    { width: "50%" },
                                                ]}
                                            >
                                                <Space>
                                                    <Text style={[styles.boldText, { marginLeft: 10, marginRight: 10 }]}>Azonosító: MKE 8.5 B03</Text>
                                                    <Text style={[styles.boldText, { marginLeft: 10, marginRight: 10 }]}>{/* ??? */}Jóváhagyta: {record?.reviewedBy}</Text>
                                                </Space>
                                            </View>
                                        </View>


                                        <View style={styles.dividerSM} />

                                        <View style={styles.tableRow}>
                                            <View
                                                style={[
                                                    styles.tableHeaderItemNoBorder,
                                                    { width: "50%" },
                                                ]}
                                            >
                                                <Space>
                                                    <Text style={[styles.boldText, { marginLeft: 10 }]}>Érvényesség kezdete:</Text>
                                                    <Text style={{ fontSize: 12, marginLeft: 10 }}>{/* ??? */}{dayjs(record?.detectionDate).format(' YYYY-MM-DD')}</Text>
                                                </Space>
                                            </View>

                                            <View
                                                style={[
                                                    styles.tableHeaderItemNoBorder,
                                                    { width: "50%" },
                                                ]}
                                            >
                                                <Space>
                                                    <Text style={{ fontSize: 12, marginTop: 20 }}>MAM BABY AG. FT - MAM HUNGÁRIA KFT.</Text>
                                                </Space>
                                            </View>
                                        </View>

                                    </Space>
                                </>
                            </View>
                        </View>
                    </View>
                    {/*---------- Hiba részletes leírása ----------*/}
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>

                <Page style={styles.page} wrap={false} size="A4">
                    {/*---------- Hiba súlyozása / CAPA ----------*/}
                    <View style={styles.font}>
                        <View style={styles.tableHeader}>
                            <View
                                style={[
                                    styles.tableHeaderItemTop,
                                    { width: "50%" },
                                ]}
                            >
                                <Space>
                                    <Text style={[styles.boldText]}>A hiba súlyozása / Severity of the failure:</Text>
                                </Space>
                            </View>

                            <View
                                style={[
                                    styles.tableHeaderItemTop2,
                                    { width: "50%" },
                                ]}
                            >
                                <Space>
                                    <Text style={[styles.boldText]}>CAPA</Text>
                                </Space>
                            </View>
                        </View>
                    </View>
                    <View style={styles.font}>
                        <View style={styles.tableHeader}>
                            <View
                                style={[
                                    styles.tableHeaderItemBottomBorder,
                                    { width: "50%" },
                                ]}
                            >
                                <Space>
                                    <Text style={{ fontSize: 12 }}>{record?.risk === 0 ? "Alacsony / Low" : record?.risk === 1 ? "Normál / Normal" : record?.risk === 2 ? "Magas / High" : record?.risk}</Text>
                                </Space>
                            </View>

                            <View
                                style={[
                                    styles.tableHeaderItemBottomBorder2,
                                    { width: "50%" },
                                ]}
                            >
                                <Space>
                                    <Text style={{ fontSize: 12 }}>{record?.isCapa === true ? "Szükséges / Required" : "Nem szükséges / Not required"}</Text>
                                </Space>
                            </View>
                        </View>
                    </View>
                    <View style={styles.dividerSM} />
                    <View style={styles.font}>
                        <View style={styles.tableHeader}>
                            <View
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "100%" },
                                ]}
                            >
                                <Space>
                                    <Text style={[styles.boldText]}>CAPA csoport / CAPA team:</Text>
                                </Space>
                            </View>
                        </View>
                        <View style={styles.tableHeader}>
                            <View
                                style={[
                                    styles.tableHeaderItemBottomBorder2,
                                    { width: "100%" },
                                ]}
                            >

                                {record &&
                                    Object.keys(
                                        record?.tickets.reduce(
                                            (acc: any, { acceptantId }: any) => {
                                                acc[acceptantId] = acceptantId;

                                                return acc; //acceptantId
                                            },
                                            {}
                                        )
                                    ).map((id: string) => {
                                        const usr = users?.data.find(
                                            (item) => item.id === id?.toString().toLowerCase()
                                        );
                                        return (
                                            usr && (
                                                //usr.imageUrl &&
                                                <Text>
                                                    {`${usr?.lastName} ${usr?.firstName}`}
                                                </Text>
                                            )
                                        );
                                    })}

                            </View>
                        </View>
                    </View>

                    {/*---------- Hiba súlyozása / CAPA ----------*/}


                    {/*---------- Gyökérokok feltárása ----------*/}


                    <View style={styles.table}>
                        <View style={styles.tableHeader}>
                            <View
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "100%" },
                                ]}
                            >
                                <Space>
                                    <Text style={[styles.boldText]}>Gyökérok feltárása / Root-cause analysis: </Text>
                                </Space>
                            </View>
                        </View>
                        <View style={styles.tableHeader}>
                            <View
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "100%" },
                                ]}
                            >
                                <Space>
                                    <Text style={{ fontSize: 12 }}>{record?.rootCause}</Text>
                                </Space>
                            </View>
                        </View>

                        <View style={styles.dividerSM} />
                        <View style={styles.tableHeader}>
                            <View
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "30%" },
                                ]}
                            >
                                <Text style={[styles.boldText]}>Intézkedés</Text>
                                <Text style={[styles.boldText]}>megnevezése</Text>
                            </View>
                            <View
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "50%" },
                                ]}
                            >

                                <Text style={[styles.boldText]}>Javasolt helyesbítő* és/vagy</Text>
                                <Text style={[styles.boldText]}>megelőző* intézkedések /</Text>

                                <Text style={[styles.boldText]}>Recommended corrective* and/or</Text>
                                <Text style={[styles.boldText]}>preventive* actions:</Text>
                            </View>
                            <Text
                                style={[
                                    styles.tableHeaderItem, styles.boldText,
                                    { width: "25%" },
                                ]}
                            >
                                Felelős
                            </Text>

                            <Text
                                style={[
                                    styles.tableHeaderItem, styles.boldText,
                                    { width: "25%" },
                                ]}
                            >
                                Határidő
                            </Text>
                        </View>


                        {record?.tickets.map((item: ITicket) => {
                            return (
                                <>
                                    {item?.type === 1 ?
                                        <View key={item.id} style={styles.tableRow}>
                                            <Text
                                                style={[
                                                    styles.tableCol,
                                                    { width: "30%" },
                                                ]}
                                            >
                                                {item.title}
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.tableCol,
                                                    { width: "50%" },
                                                ]}
                                            >
                                                {item.description}
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.tableCol,
                                                    { width: "25%", textAlign: "center" },
                                                ]}

                                            >

                                                {users?.data.find((usr) => usr.id === item?.acceptantId)?.lastName} {users?.data.find((usr) => usr.id === item?.acceptantId)?.firstName}
                                            </Text>

                                            <Text
                                                style={[
                                                    styles.tableCol,
                                                    { width: "25%", textAlign: "center" },
                                                ]}
                                            >
                                                {dayjs(item?.dueDate).format('YYYY-MM-DD')}
                                            </Text>
                                        </View>
                                        : item?.type === 2 ? <View key={item.id} style={styles.tableRow}>
                                            <Text
                                                style={[
                                                    styles.tableCol,
                                                    { width: "30%" },
                                                ]}
                                            >
                                                {item.title}
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.tableCol,
                                                    { width: "50%" },
                                                ]}
                                            >
                                                {item.description}
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.tableCol,
                                                    { width: "25%", textAlign: "center" },
                                                ]}

                                            >

                                                {users?.data.find((usr) => usr.id === item?.acceptantId)?.lastName} {users?.data.find((usr) => usr.id === item?.acceptantId)?.firstName}
                                            </Text>

                                            <Text
                                                style={[
                                                    styles.tableCol,
                                                    { width: "25%", textAlign: "center" },
                                                ]}
                                            >
                                                {dayjs(item?.dueDate).format('YYYY-MM-DD')}
                                            </Text>
                                        </View> : ""}
                                </>

                            );
                        })}
                    </View>
                    {/*---------- Gyökérokok feltárása ----------*/}


                    <View style={styles.table}>
                        <View style={styles.tableHeader}>
                            <View
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "100%" },
                                ]}
                            >
                                <Space>
                                    <Text style={[styles.boldText]}>A helyesbítő* és/vagy megelőző* intézkedések lezárása /</Text>
                                    <Text style={[styles.boldText]}>Closing corrective* and/or preventive* actions</Text>
                                </Space>
                            </View>
                        </View>

                        <View style={styles.tableHeader}>
                            <Text
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "25%" },
                                ]}
                            >
                                Megfelelt / OK
                            </Text>

                            <Text
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "25%" },
                                ]}
                            >

                            </Text>

                            <Text
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "25%" },
                                ]}
                            >
                                Ismételt intézkedés szükséges / NOK, additional actions needed
                            </Text>

                            <Text
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "25%" },
                                ]}
                            >

                            </Text>
                        </View>

                        <View style={styles.tableHeader}>
                            <Text
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "50%" },
                                ]}
                            >
                                Jóváhagyta / Approved by:
                            </Text>

                            <Text
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "50%" },
                                ]}
                            >

                            </Text>

                            <Text
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "50%" },
                                ]}
                            >
                                Dátum / Date:
                            </Text>

                            <Text
                                style={[
                                    styles.tableHeaderItem,
                                    { width: "50%" },
                                ]}
                            >

                            </Text>

                        </View>
                    </View>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            </Document>
        </PDFViewer>

    );
};


/*Font.register({
    family: 'Oswald',
    fontWeight:'bold',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });
*/

Font.register({
    family: 'Inter',
    fontWeight: 'bold',
    src: 'http://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf'
});

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
});
const styles = StyleSheet.create({
    viewer: {
        paddingTop: 32,
        width: "100%",
        height: "80vh",
        border: "none",
    },
    footerImg: {
        position: 'absolute',
        fontSize: 12,
        bottom: 15,
        left: 510,
        right: 0,
        textAlign: 'center',
        color: 'grey',
        width: "10%"
    },

    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 5,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    page: {
        display: "flex",
        padding: "0.4in 0.4in",
        fontSize: 12,
        color: "#333",
        backgroundColor: "#fff",
    },
    inoviceTextNumberContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    inoviceText: {
        color: "#3aabf0",
    },
    inoviceId: {
        textAlign: "center",
    },
    inoviceForFromCotnainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    inoviceForFromTitle: {
        marginBottom: 24,
    },
    inoviceFor: {
        flex: 1.5,
    },
    inoviceFrom: {
        flex: 1,
    },
    inoviceForFromText: {
        color: "#787878",
        lineHeight: 1.5,
    },
    dividerSM: {
        width: "100%",
        height: 1,
        marginTop: 12,
        marginBottom: 12,
        backgroundColor: "#e5e5e5",
    },
    dividerSM2: {
        width: "100%",
        borderBottom: "1px solid #000",
    },
    dividerLG: {
        width: "100%",
        height: 1,
        marginTop: 25,
        marginBottom: 25,
        backgroundColor: "#e5e5e5",
    },
    table: {
        marginTop: 32,
        fontFamily: "Open Sans"
    },
    tableHeader: {
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
    },
    tableHeaderItem: {
        paddingVertical: 8,
        border: "1px solid #000",
    },
    tableHeaderItemTop: {
        paddingVertical: 8,
        borderTop: "1px solid #000",
        borderBottom: "1px solid #000",
        borderLeft: "1px solid #000",
    },
    tableHeaderItemLeft: {
        paddingVertical: 8,
        borderLeft: "1px solid #000",
    },
    tableHeaderItemTopBottom: {
        paddingVertical: 8,
        
        borderBottom: "1px solid #000",
    },
    tableHeaderItemLeftRight: {
        paddingVertical: 8,
        borderLeft: "1px solid #000",
        borderRight: "1px solid #000"
    },
    tableHeaderItemTop2: {
        paddingVertical: 8,
        borderTop: "1px solid #000",
        borderBottom: "1px solid #000",
        borderLeft: "1px solid #000",
        borderRight: "1px solid #000"
    },
    tableHeaderItemBottomBorder: {
        paddingVertical: 8,
        borderBottom: "1px solid #000",
        borderLeft: "1px solid #000",

    },
    tableHeaderItemBottomBorder2: {
        paddingVertical: 8,
        borderBottom: "1px solid #000",
        borderLeft: "1px solid #000",
        borderRight: "1px solid #000"
    },
    tableHeaderItemNoBorder: {
        paddingVertical: 8,
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
    },
    tableCol: {
        paddingVertical: 8,
        paddingHorizontal: 4,
        border: "1px solid #000",
    },
    tableCol2: {
        paddingVertical: 8,
        paddingHorizontal: 4,
        borderBottom: "1px solid #000",
    },
    tableColActions: {
        paddingVertical: 8,
        paddingHorizontal: 4,
        borderBottom: "1px solid #000",
        borderLeft: "1px solid #000",
    },
    signatureTotalContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 32,
    },
    signatureContainer: {},
    totalContainer: {},
    signatureText: {
        marginTop: 32,
    },
    totalText: {
        marginTop: 16,
    },
    footer: {
        borderTop: "1px solid #e5e5e5",
        paddingTop: 8,
        marginTop: "auto",
    },
    footerText: {
        color: "#787878",
        lineHeight: 1.5,
    },
    boldText: {
        fontSize: 12,
        fontFamily: "Open Sans",
        fontWeight: 600
    },
    font: {
        fontFamily: "Open Sans",
    }
});
