import { InboxOutlined, UserOutlined } from "@ant-design/icons";
import {
  AntdList,
  Button,
  Card,
  Col,
  Descriptions,
  Edit,
  Form,
  Input,
  List,
  ListButton,
  RcFile,
  Row,
  TextField,
  Tooltip,
  UploadFile,
  getValueFromEvent,
  useDrawerForm,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import {
  HttpError,
  IResourceComponentsProps,
  useMany,
  file2Base64,
  useGetIdentity
} from "@pankod/refine-core";
import { Avatar, Comment, message, Select, Typography, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { HOST_URL } from "apiconstants";
import {
  IPremise,
  IProject,
  IProjectVariable,
  ISource,
  ITicket,
  IArea,
  ITicketpriority,
  ITicketVariable,
  IUser,
  IComment,
} from "interfaces";
import { CommentCreate } from "components/comments/create";
import { useState } from "react";
import React from "react";
import moment from "moment";
import { TicketStatus } from "constants/pm";
import { getValueProps } from "helpers";
import {
  PlusOutlined
} from "@ant-design/icons";

export const TicketEdit: React.FC<IResourceComponentsProps> = (props: any) => {
  const { formProps, saveButtonProps, queryResult } = useForm<
    ITicket,
    HttpError,
    ITicketVariable
  >({
    resource: "tickets",
    redirect: false,
    warnWhenUnsavedChanges: true,
  });

  const { t } = useTranslation();

  const record = queryResult?.data?.data;
  const { data: users } = useMany<IUser>({
    resource: "users",
    ids: [],
  });
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));

    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };


  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> {t("projects.fields.upload")}</div>
    </div>
  );

  const {
    formProps: commentCreateFormProps,
    drawerProps: commentCreateDrawerProps,
    show: commentCreate,
    saveButtonProps: commentCreateSaveButtonProps,
  } = useDrawerForm<IComment>({
    action: "create",
    resource: "comments",
    redirect: false,
    onMutationSuccess: () => {
      queryResult?.refetch();
    },
  });

  const { selectProps: ownerProps } = useSelect<IUser>({
    resource: "users",
    optionValue: "id",
    optionLabel: "email",
    hasPagination: false,
  });

  const [parentId, setParentId] = useState<string>();
  const [ticketId, setTicketId] = useState<string>();
  const shCommentCreate = (_parentId: any, _ticketId: any) => {
    commentCreateFormProps.form.setFieldsValue({
      parentId: _parentId,
      ticketId: _ticketId,
    });
    commentCreate();
  };

  type CommentListProps = {
    comments: IComment[];
  };

  const CommentList: React.FC<CommentListProps> = ({ comments }) => {
    let items = comments.map((comment) => {
      return (
        <div key={comment.id} className="border-l pl-6">
          <Comment
            key={comment.id}
            content={<p>{comment.content}</p>}
            author={
              users?.data.find(
                (item) =>
                  item.id === comment?.createdBy?.toString().toLowerCase()
              )?.email
            }
            datetime={moment(comment?.createdOn).fromNow()}
            actions={[
              <Tooltip key="comment-basic-like" title="Like">
                <span
                  onClick={() =>
                    shCommentCreate(comment?.id, comment?.ticketId)
                  }
                  key="comment-basic-reply-to"
                >
                  {t("tickets.fields.reply")}
                </span>
              </Tooltip>,
            ]}
          >
            {comment.replies && CommentList({ comments: comment.replies })}
          </Comment>
        </div>
      );
    });

    return <>{items}</>;
  };

  const { Text, Title } = Typography;
  const { TextArea } = Input;

  const { Option } = Select;


  const { data: user } = useGetIdentity();
  const { Dragger } = Upload;

  return (
    <>
      <Edit
        saveButtonProps={saveButtonProps}
        resource="tickets"
        breadcrumb={false}
        headerButtons={({ defaultButtons }) => (
          <>
            {
              //defaultButtons
            }
          </>
        )}

        title={t("tickets.fields.taskManage")}
      >
        <Form {...formProps} layout="vertical"
          onFinish={async (values) => {
            //alert(JSON.stringify(formProps.form?.getFieldsValue(true).removeUploads))
            //alert(JSON.stringify(values.removeUploads))
            const removeUploads =
              formProps.form?.getFieldsValue(true).removeUploads;
            const base64Files = [];
            const { attachments } = values;

            for (const file of attachments) {
              if (file.originFileObj) {
                const base64String = await file2Base64(file);

                base64String &&
                  base64Files.push({
                    ...file,
                    base64String,
                    name:
                      file?.name.substring(0, file?.name.lastIndexOf(".")) ||
                      file?.name,
                    data: base64String,
                    extension: "." + file?.name?.split(".").pop(),
                  });
              } else {
                //base64Files.push(file);
              }
            }

            return (
              formProps.onFinish &&
              formProps.onFinish({
                ...values,
                uploads: base64Files,
                removeUploads: removeUploads,
              })
            );
          }}>
          <Form.Item
            hidden
            label="Id"
            name={["id"]}
            getValueProps={(value) => ({
              value: value || record?.id,
            })}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input readOnly disabled />
          </Form.Item>

          <Descriptions
            bordered
            size="small"
            layout="vertical"
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          //title="Custom Size"

          //extra={<Button type="primary">Edit</Button>}
          >
            <Descriptions.Item
              labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
              label={t("tickets.fields.taskNumber")}
            >
              {record?.title}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
              label={t("tickets.fields.projectName")}
            >
              {record?.project.name}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
              label={t("tickets.fields.owner")}
            >
              <Form.Item name="acceptantId">
                <Select allowClear {...ownerProps} />

                {/*<Select {...assigneProps} />*/}
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
              label={t("tickets.fields.taskStatus")}
            >
              <Form.Item
                name="status"
                getValueProps={(value) => ({
                  value: value || 0,
                })}
              >
                {user?.firstName + user?.lastName === "LászlóKarakó" || user?.firstName + user?.lastName === "LászlóŐsze" ?

                  <Select allowClear>
                    {Object.keys(TicketStatus).map((key) => {
                      return +key < 6 &&
                        <Option value={+key}>
                          <Text>{TicketStatus[+key]}</Text>
                        </Option>

                    })}
                  </Select> :

                  <Select allowClear>
                    {Object.keys(TicketStatus).map((key) => {
                      return +key < 3 &&
                        <Option value={+key}>
                          <Text>{TicketStatus[+key]}</Text>
                        </Option>

                    })}
                  </Select>}

                {/*<Select {...assigneProps} />*/}
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
              label="MESH"
            >
              {record?.meshChecked}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
              label={t("tickets.fields.description")}
            >
              <Form.Item name="description">
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  style={{ height: "100%" }}
                  showCount
                />
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>

          {/*  <Form.Item label={t("tickets.fields.upload")} name="uploadFiles">
            <Dragger>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {t("tickets.fields.uploadDrag")}
              </p>
            </Dragger>
          </Form.Item>
                */}

          <Form.Item label={t("tickets.fields.comments")} name="comments">
            <Card>
              {record?.comments !== undefined &&
                record?.comments?.length > 0 && (
                  <CommentList
                    comments={record?.comments?.filter((comment) => {
                      return comment.parentId === null;
                    })}
                  />
                )}
              {/*<CommentList values={record?.comments}></CommentList>*/}
              <Button
                onClick={() => shCommentCreate(null, record?.id)}
                type="primary"
              >
                {t("comments.titles.create")}
              </Button>
            </Card>
          </Form.Item>

          <Form.Item label={t("projects.fields.upload")}>
            <Form.Item
              name="attachments"
              valuePropName="fileList"
              getValueProps={(data) => getValueProps(data, HOST_URL)}
              getValueFromEvent={(props) => {
                //alert(JSON.stringify(props));
                const fileList = getValueFromEvent(props);
                return fileList;
              }}
              noStyle
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Upload
                listType="picture-card"
                multiple
                onPreview={handlePreview}
                beforeUpload={() => false}
                onRemove={(value: any) => {
                  //alert(JSON.stringify(value))
                  const removeUploads =
                    formProps.form?.getFieldValue("removeUploads") || [];
                  removeUploads.push(value?.id);
                  formProps.form?.setFieldsValue({
                    removeUploads: removeUploads,
                  });
                  // alert(JSON.stringify(formProps.form?.getFieldsValue(true).removeUploads))
                }}
              >
                {uploadButton}
                {/*<p className="ant-upload-text">
                        {t("projects.fields.uploadDrag")}                
                    </p>*/}
              </Upload>
            </Form.Item>
          </Form.Item>

        </Form>
      </Edit>
      <CommentCreate
        parentId={parentId}
        ticketId={ticketId}
        formProps={commentCreateFormProps}
        drawerProps={commentCreateDrawerProps}
        saveButtonProps={commentCreateSaveButtonProps}
      />
    </>
  );
};
