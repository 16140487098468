import React from "react";
import {
    Form,
    FormProps,
    Input,
    useSelect,
    Icons,
    Button,
    Typography,
    DatePickerProps,
} from "@pankod/refine-antd";
import { Select, DatePicker } from "antd";

import { ITicket, ITicketpriority, IArea, IUser, IUserCostConnection, ICost, IPartner } from "interfaces";
import { TicketStatus } from "constants/pm";
import { PartnerSelect } from "components/partner/PartnersSelect";
import { CostSelect } from "components/partner/CostsSelect";
import { CostSelect2 } from "pages/costs/CostSelect2";

const { RangePicker } = DatePicker;
const { Text, Title } = Typography;
const { Option } = Select;

export const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    const { selectProps, queryResult, defaultValueQueryResult } =
        useSelect<IPartner>({
            resource: "partners",
            optionLabel: "name",
            optionValue: "id",
            sort: [
                {
                    field: "name",
                    order: "asc"
                }
            ]
        });

    const allOptions = [
        ...(queryResult.data?.data || []),
        ...(defaultValueQueryResult.data?.data || []),
    ].filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.id === item.id
        ))
    );

    const { selectProps: selectPropsKh, queryResult: queryResultKh, defaultValueQueryResult: defaultValueQueryResultKh } =
        useSelect<ICost>({
            resource: "costs",
            optionLabel: "name",
            optionValue: "id",
            sort: [
                {
                    field: "name",
                    order: "asc"
                }
            ]
        });

    const allOptionsKh = [
        ...(queryResultKh.data?.data || []),
        ...(defaultValueQueryResultKh.data?.data || []),
    ].filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.id === item.id
        ))
    );

    const { selectProps: selectPropsUser, queryResult: queryResultUser, defaultValueQueryResult: defaultValueQueryResultUser } =
        useSelect<IUser>({
            resource: "users",
        });

    const allOptionsUser = [
        ...(queryResultUser.data?.data || []),
        ...(defaultValueQueryResultUser.data?.data || []),
    ].filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.id === item.id
        ))
    );

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
      };

    return (
        <Form layout="vertical" {...formProps}>
            
            <Form.Item label="Partner keresése" name="partnerId">
                {/*<Select
                    placeholder="Partner kiválasztása"
                    style={{ width: "100%" }}
                    {...selectPropsKh}
                    options={allOptions.map((p) => ({
                        label: `${p.name}`,
                        value: p.id,
                    }))}
                    allowClear
                />*/}
                <PartnerSelect />
            </Form.Item>

            <Form.Item label="Feladat keresése" name="ticketId">
                {/*<Select
                    placeholder="Feladat kiválasztása"
                    style={{ width: "100%" }}
                    {...selectPropsKh}
                    options={allOptionsKh.map((p) => ({
                        label: `${p.subprojects}`,
                        value: p.id,
                    }))}
                    allowClear
                />*/}

                <CostSelect2 />
            </Form.Item>

            <Form.Item label="Dolgozó keresése" name="workerName">
                <Select
                    placeholder="Dolgozó kiválasztása"
                    style={{ width: "100%" }}
                    {...selectPropsKh}
                    options={allOptionsUser.map((p) => ({
                        label: `${p.lastName} ${p.firstName}`,
                        value: `${p.firstName} ${p.lastName}`,
                    }))}
                    allowClear
                />
            </Form.Item>

            <Form.Item label="Hónap kiválasztása" name="month">
                <DatePicker onChange={onChange} style={{ width: "100%" }} name="month" picker="month" />
            </Form.Item>

            <Form.Item label="Kezdő dátum" name="startDate">
                <DatePicker onChange={onChange} style={{ width: "100%" }} name="startDate" />
            </Form.Item>

            <Form.Item label="Befejező dátum" name="endDate">
                <DatePicker onChange={onChange} style={{ width: "100%" }} name="endDate" />
            </Form.Item>

            <Form.Item>
                <Button htmlType="submit" type="primary">
                    Szűrés
                </Button>
            </Form.Item>
        </Form>
    );
};
