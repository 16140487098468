import {
    Form,
    Modal,
    Input,
    ModalProps,
    FormProps,
    Select,
    useSelect,
    Typography,
    Row,
    getValueFromEvent,
    Col,
    Upload,
    Space,
    Avatar,
    Tabs,
} from "@pankod/refine-antd";
import { useGetIdentity, useGetLocale, useNavigation, useTranslate } from "@pankod/refine-core";
import { IArea, IPremise } from "interfaces";

type EditProfileProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    hideSecurity?: boolean;
};



export const EditProfile: React.FC<EditProfileProps> = ({
    modalProps,
    formProps,
    hideSecurity = true,
}) => {
    
    const t = useTranslate();
    const { show, edit } = useNavigation();
    const locale = useGetLocale();
    const currentLocale = locale();
  
    const { Title, Text } = Typography;
    const { TextArea } = Input;
  
    const { selectProps: premiseProps } = useSelect<IPremise>({
        resource: "premises",
        optionValue: "id",
        optionLabel: "name",
        hasPagination: false,
      });
    
      const { selectProps: areaProps } = useSelect<IArea>({
        resource: "areas",
        optionValue: "id",
        optionLabel: "name",
        hasPagination: false,
      });
    
    
  
    const apiUrl='';
  

    return  (
      
        <Modal {...modalProps} afterClose={()=>formProps?.form && formProps?.form?.resetFields()}      
        title={t("employees.titles.editProfile")}>
        
            <Form {...formProps} layout="vertical">
            <Form.Item hidden
                    label="Id"
                    name={["id"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input readOnly disabled />
                </Form.Item>
            <Row gutter={20}>
            {/*    <Col xs={24} lg={8}>
                    <Form.Item>
                        <Form.Item
                            name="avatar"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                            noStyle
                        >
                            <Upload.Dragger
                                name="file"
                                action={`${apiUrl}/media/upload`}
                                listType="picture"
                                maxCount={1}
                                multiple
                                style={{
                                    border: "none",
                                    width: "100%",
                                    background: "none",
                                }}
                            >
                                <Space direction="vertical" size={2}>
                                    <Avatar
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            maxWidth: "200px",
                                        }}
                                        src="/images/user-default-img.png"
                                        alt="Store Location"
                                    />
                                    <Text
                                        style={{
                                            fontWeight: 800,
                                            fontSize: "16px",
                                            marginTop: "8px",
                                        }}
                                    >
                                        {t(
                                            "couriers.fields.images.description",
                                        )}
                                    </Text>
                                    <Text style={{ fontSize: "12px" }}>
                                        {t("couriers.fields.images.validation")}
                                    </Text>
                                </Space>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>
                </Col>
                */}
                <Col xs={24} lg={16}>
                    <Form.Item 
                     
                      name="email"
                      label={t("employees.fields.email")}
                      rules={[{ required: true, type: "email" }]}
                    >
                      <Input  disabled={true}  size="large" placeholder="Email" />
                    </Form.Item>
                   
                    <Row gutter={18}>                      
                      <Col span={12}>
                        <Form.Item
                          label={t("employees.fields.lastName")}
                          name="lastName"
                        >
                          <Input style={{}} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={t("employees.fields.firstName")}
                          name="firstName"
                        >
                          <Input style={{}} />
                        </Form.Item>{" "}
                      </Col>
                    </Row>
                    <Row gutter={18}>
                      <Col span={12}>
                        <Form.Item
                          label={t("employees.fields.premise")}
                          name="premiseId"
                        >
                          <Select allowClear {...premiseProps} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={t("employees.fields.area")}
                          name="areaId"
                        >
                          <Select allowClear {...areaProps} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

            </Row>

            </Form>
       
           
        </Modal>
    );
};
