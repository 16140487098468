import {
  useGetLocale,
  useMany,
  useNavigation,
  useShow,
  useTranslate,
} from "@pankod/refine-core";

import {
  Avatar,
  DateField,
  Descriptions,
  Input,
  List,
  Modal,
  Table,
  Tag,
  TagField,
  Tooltip,
  Typography,
  Comment,
  Card,
  ImageField,
} from "@pankod/refine-antd";
import { UserOutlined } from "@ant-design/icons";

import { isOverdue } from "utility/functions";
import { IComment, ITicket } from "interfaces/ticket";
import { IUser } from "interfaces";
import { dateFormat, HOST_URL } from "apiconstants";
import { useState } from "react";
import { TicketStatus } from "constants/pm";
import { TicketReview } from "./review";
import moment from "moment";

type ListTicketsProps = {
  //tickets: ITicket[];
  dataSource: any;
  users?: IUser[];
  actions?: any;
  review?: boolean;
};

type CommentListProps = {
  comments?: IComment[] | undefined;
};
export const ListTickets: React.FC<ListTicketsProps> = ({
  dataSource,
  users,
  actions,
  review,
}) => {
  const t = useTranslate();
  const { show, edit } = useNavigation();
  const locale = useGetLocale();
  const currentLocale = locale();

  const { Title, Text } = Typography;
  const { TextArea } = Input;

  // Show Modal
  const [visibleShowModal, setVisibleShowModal] = useState<boolean>(false);

  const { queryResult, setShowId } = useShow<ITicket>({ resource: "tickets" });

  const { data: showQueryResult } = queryResult;
  const record = showQueryResult?.data;

  /*const { data: users } = useMany<IUser>({
    resource: "users",
    ids: [],
  });
*/

  const CommentList: React.FC<CommentListProps> = ({ comments }: any) => {
    let items = comments.map((comment: IComment) => {
      return (
        <div key={comment.id} className="border-l pl-6">
          <Comment
            key={comment.id}
            content={<p>{comment.content}</p>}
            author={
              users?.find(
                (item) =>
                  item.id === comment?.createdBy?.toString().toLowerCase()
              )?.email
            }
            datetime={moment(comment?.createdOn).fromNow()}
          >
            {comment.replies && CommentList({ comments: comment.replies })}
          </Comment>
        </div>
      );
    });

    return <>{items}</>;
  };

  return (
    <>
      <List title={false} breadcrumb={false}>
        <Table
          rowKey="id"
          onRow={(item) => {
            return {
              style: { cursor: "pointer", },
              onClick: () => {
                //  edit("tickets", item.id);

                setShowId(item.id);
                setVisibleShowModal(true);
              },
            };
          }}
          showHeader={true}
          pagination={false}
          dataSource={dataSource}
        >
          <Table.Column dataIndex="title" title={t("tickets.fields.title")} />
          <Table.Column
            title={t("tickets.fields.owner")}
            dataIndex="acceptantId"
            render={(id) => {
              var user = users?.find(
                (item) => item.id === id?.toString().toLowerCase()
              )

              return (
                <TagField
                  value={
                    user?.firstName + " " + user?.lastName
                  }
                />
              );
            }}
          />
          <Table.Column
            key="actions"
            dataIndex="dueDate"
            title={t("tickets.fields.deadline")}
            width="10"
            render={(value) =>
              value &&
              !moment(value, dateFormat)
                .toString()
                .includes("0001 00:00:00") && (
                <DateField locales={currentLocale} value={value} format="L" />
              )
            }
          />
          <Table.Column dataIndex="status" title={t("tickets.fields.status")}
            render={(value) =>
              value == 0 ? t("tickets.fields.notStarted")
                : value == 1 ? t("tickets.fields.inProgress")
                  : value == 2 ? t("tickets.fields.finished")
                    : value == 3 ? t("tickets.fields.approved")
                      : value == 4 ? t("tickets.fields.closed")
                        : value} />
          {actions && (
            <Table.Column<ITicket>
              title="Actions"
              dataIndex="actions"
              render={actions}
            />
          )}
        </Table>
      </List>
      <Modal
        style={{ minWidth: "60rem" }}
        visible={visibleShowModal}
        onCancel={() => setVisibleShowModal(false)}
        title={t("tickets.titles.show")}
        footer={false}
      >
        {review && record?.status !== 3 ? (
          <TicketReview
            record={record}
            users={users}
            onUpdate={() => setVisibleShowModal(false)}
          />
        ) : (
          <>
            <Descriptions
              bordered
              size="small"
              layout="vertical"
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            //title="Custom Size"

            //extra={<Button type="primary">Edit</Button>}
            >
              <Descriptions.Item
                labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
                label={t("tickets.fields.taskNumber")}
              >
                {record?.title}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
                label={t("tickets.fields.projectName")}
              >
                {record?.project.name}
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
                label={t("tickets.fields.owner")}
              >
                <Text>
                  {
                    users?.find(
                      (item) =>
                        item.id ===
                        record?.acceptantId?.toString().toLowerCase()
                    )?.email
                  }
                </Text>
              </Descriptions.Item>
              <Descriptions.Item
                labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
                label={t("tickets.fields.taskStatus")}
              >
                <Text>
                  {TicketStatus[(record?.status && +record?.status) ?? 0]}
                </Text>
              </Descriptions.Item>

              <Descriptions.Item
                labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
                label="MESH"
              >
                {record?.meshChecked === true ? <Text>{t("tickets.fields.meshTrue")}</Text> : <Text>{t("tickets.fields.meshFalse")}</Text>}
              </Descriptions.Item>

              <Descriptions.Item
                labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
                label={t("tickets.fields.description")}
              >
                <TextArea
                  value={record?.description}
                  disabled
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  style={{ height: "100%" }}
                  showCount
                />
              </Descriptions.Item>

            </Descriptions>
            <Descriptions
              bordered
              size="small"
              layout="vertical"
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
              <Descriptions.Item
                labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}>
                <Card
                  style={{ width: "100%", height: 200 }}
                  title={t("projects.fields.uploadedFilesCard")}
                  size="default"
                >
                  {record?.attachments?.map((file: any) => {
                    return (
                      <ImageField
                        key={file.name}
                        //src={file.attachmentUrl}
                        value={`${HOST_URL}/${file.attachmentUrl}`}
                        title={file.name}
                        width="8rem"
                      ></ImageField>
                    );
                  })}
                </Card>
              </Descriptions.Item>
            </Descriptions>
            {record?.comments !== undefined && record?.comments?.length > 0 && (
              <Card title={t("tickets.fields.comments")}>
                <CommentList
                  comments={record?.comments?.filter((comment: IComment) => {
                    return comment.parentId === null;
                  })}
                />
              </Card>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
/*
        <Table.Column
          dataIndex="acceptantId"
          render={(id) => {
            const usr = users?.find(
              (item) => item.id === id?.toString().toLowerCase()
            );
            
            return (              
              usr &&
              //usr.imageUrl && 
              (
                <Tooltip title={usr?.userName} placement="top">
                  <Avatar
                    style={{ backgroundColor: "#f56a00" }}
                    icon={<UserOutlined value={usr?.email} />}
                    //src={`${HOST_URL}/${usr?.imageUrl}`}
                  ></Avatar>
                </Tooltip>
              )
            );
          }}
        />*/
