import { useEffect, useState } from "react";
import {
  CrudFilters,
  HttpError,
  useShow,
  useTranslate,
} from "@pankod/refine-core";

import {
  BooleanField,
  Button,
  ButtonProps,
  Card,
  Col,
  CreateButton,
  Form,
  FormProps,
  getDefaultSortOrder,
  Grid,
  Icons,
  Input,
  List,
  Modal,
  Row,
  Select,
  Space,
  Table,
  TextField,
  Tooltip,
  Typography,
  useModal,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import { IPartner, IPartnerFilter } from "interfaces";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

type PartnerSelectProps = {
  value?: IPartner;
  onChange?: (id?: string) => void;
  disabled?: boolean;
};


/**
 * @param value initialValue passed by parent Form.Item.
 * @param onChange a callback for Form.Item to read its child component's value.
 * @param disabled
 * @constructor
 */
export const PartnerSelect: React.FC<PartnerSelectProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const t = useTranslate();
  const [partner, setPartner] = useState<IPartner | undefined>(value);
  const { tableProps, sorter, filters, searchFormProps } = useTable<
    IPartner,
    HttpError,
    IPartnerFilter
  >({
    resource: "partners",
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],

    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name } = params;

      filters.push({
        field: "name",
        operator: "eq",
        value: name,
      });

      return filters;
    },
    syncWithLocation: false,
  });
  const { xl } = useBreakpoint();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([
    value?.id ?? "",
  ]);

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const {
    modalProps: partnerSelectModal,
    show: partnerSelectShow,
    close: partnerSelectClose,
  } = useModal();

  const { setShowId, queryResult: partnerQueryResult } = useShow<IPartner>({
    resource: "partners",

    id: partner?.id,
  });

  let promise = new Promise(function (resolve, reject) {
    if (typeof onChange === "function") {
      resolve(onChange(partner?.id));
    } else {
      resolve(true);
    }
  });

  useEffect(() => {
    if (value && partner?.id !== value) {
      setPartner({ id: value });
    }
  }, [value]);

  useEffect(() => {
    if (partnerQueryResult.isFetched) {
      setPartner(partnerQueryResult.data?.data);
    }
  }, [partnerQueryResult]);

  useEffect(() => {
    if (partnerQueryResult.isFetched && !partnerQueryResult.isError) {
      promise.then(partnerSelectClose);
    }
  }, [partner]);


  const change = async (selectedRowKeys: React.Key[]) => {
    if (partner?.id !== selectedRowKeys[0]) {
      setShowId(selectedRowKeys[0]);
      if (typeof onChange === "function") {
        onChange(selectedRowKeys[0] as string);
      }
    } else {
      partnerSelectClose();
    }
  };



  return (
    <>
      <Space>

        <TextField value={partner?.name} />

        <Space>
          {" "}
          {!disabled && (
            <Button
              icon={<Icons.SearchOutlined />}
              onClick={() => {
                partnerSelectShow();
              }}
            ></Button>
          )}
        </Space>
      </Space>

      <Modal
        zIndex={1002}
        width={1200}
        {...partnerSelectModal}
        onCancel={partnerSelectClose}
        onOk={() => {
          change(selectedRowKeys);
        }}
      >
        <List
          title="Költséghely kiválasztása"
          resource="partners"
          pageHeaderProps={{
            extra: <CreateButton hidden={true} />,
          }}
        >
          <Form
            {...searchFormProps}
            layout="vertical"
            onValuesChange={() => searchFormProps.form?.submit()}
          >
            <Space wrap>
              <Form.Item
                label="Partnernév"
                name="name"
              >
                <Input
                  prefix={<Icons.SearchOutlined />}
                />
              </Form.Item>
            </Space>
          </Form>

          <Table
            {...tableProps}
            rowKey="id"
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
          >

            <Table.Column
              dataIndex="partnercode"
              key="partnercode"
              title="Partnerkód"
              render={(value) => <TextField value={value} />}
              sorter
              filterSearch={true}
            />

            <Table.Column
              dataIndex="name"
              key="name"
              title="Partnernév"
              render={(value) => <TextField value={value} />}
              sorter
              filterSearch={true}
            />

          </Table>
        </List>
      </Modal>
    </>
  );
};

