import { AuthProvider, HttpError, useGetLocale } from "@pankod/refine-core";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { TENANT_KEY, REFRESH_KEY, ROLES_KEY, TOKEN_KEY } from "./apiconstants";
import { AuthHelper, MyToken } from "./helpers";
const ApiAuthProvider = (apiUrl: string) => {
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      tenant: `${localStorage.getItem(TENANT_KEY)}`,
    },
  });
  /*const token = localStorage.getItem(TOKEN_KEY);
  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${token}`;
*/
  // Request interceptor for API calls
  axiosInstance.interceptors.request.use(
    (config) => {
      //alert(document.documentElement.lang);
      config.headers = {
        ...config.headers,
        "Accept-Language": `${document.documentElement.lang}`,
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
        tenant: `${localStorage.getItem(TENANT_KEY)}`,
      };
      return config;
    },
    (error) => {
      const errors = error?.response?.data?.errors;
      const customError: HttpError = {
        ...error,
        message:
          error.response?.data?.detail ||
          error.response?.data?.message ||
          error.response?.data?.error?.title ||
          error.response?.detail ||
          error.response?.message ||
          error.response?.messages?.map((message: string) => {
            return message;
          }) ||
          error.title ||
          (errors &&
            Object.keys(errors)?.map((message: string) => {
              return `${errors[message]}\r\n`;
            })),
        statusCode: error.response?.status || error.status,
      };

      Promise.reject(customError);
    }
  );

  const apiAuthHelper = AuthHelper(apiUrl, axiosInstance);
  let refreshing_token: any = null;
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const token = localStorage.getItem(TOKEN_KEY) || "";
      const refreshToken = localStorage.getItem(REFRESH_KEY);
      //console.log( `${JSON.stringify(error.response)} ${error.response?.status} -- ${error.response?.data?.detail} `)
      //      alert(error)
      if (
        refreshToken &&
        (error.status === 401 || error.response?.status === 401) &&
        (error.response?.data?.messages?.includes("invalid_token\r\n") ||
          error.response?.data?.messages?.includes("invalid_token")) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        try {
          refreshing_token = refreshing_token
            ? refreshing_token
            : apiAuthHelper.refresh(token, refreshToken);

          const { data, status } = await refreshing_token;
          // alert(data?.token);
          refreshing_token = null;
          if (status === 200) {
            //if (data.token) {
            // console.log(refreshToken + "---" + data.token);
            localStorage.setItem(TOKEN_KEY, data.token);
            localStorage.setItem(REFRESH_KEY, data.refreshToken);
            const user = jwtDecode<MyToken>(data.token ?? "");
/*            localStorage.setItem(
              ROLES_KEY,
              JSON.stringify(user.roles || ["ROLE_GUEST"])
            );
*/
            // set header axios instance
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${data.token}`;
            axiosInstance.defaults.headers.common["tenant"] = `${localStorage.getItem(TENANT_KEY)}`;
          }
          // set header axios instance
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${data.token}`;
          axiosInstance.defaults.headers.common["tenant"] = `${localStorage.getItem(TENANT_KEY)}`;
          return axiosInstance(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }

        //axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(TOKEN_KEY)}`;
        //return axiosInstance(originalRequest);
      } else {
        //alert(error.response?.data?.messages === "Invalid Refresh Token.");
        //alert(JSON.stringify( error.response?.data?.messages == "Invalid Refresh Token."));
        if (
          refreshToken &&
          (error.status === 401 || error.response?.status === 401) &&
          (error.response?.data?.messages == "Invalid Refresh Token." ||
            error.response?.data?.messages?.includes("Invalid Refresh Token."))
        ) {
          //alert('hello')
          localStorage.removeItem(TOKEN_KEY);
          localStorage.removeItem(REFRESH_KEY);
          localStorage.removeItem(ROLES_KEY);
          return Promise.reject();
        } else {
          //alert(error)
          const errors = error?.response?.data?.errors;
          const customError: HttpError = {
            ...error,
            message:
              error.response?.data?.messages?.map((message: any) => {
                return message;
              }) ||
              error.response?.data?.detail ||
              error.response?.data?.message ||
              error.response?.detail ||
              error.response?.message ||
              error.response?.messages?.map((message: string) => {
                return message;
              }) ||
              (errors &&
                Object.keys(errors)?.map((message: string) => {
                  return `${errors[message]}\r\n`;
                })),

            statusCode: error.response?.status || error.status,
            name: error.response?.data?.errorId || error.response?.name,
          };
          /*alert(JSON.stringify(error.response?.data?.messages?.map((message: any) => {
              return JSON.stringify(message);
            })))
*/
          //alert(JSON.stringify(error))

          return Promise.reject(customError);
        }
      }
    }
  );

  const authProvider: AuthProvider = {
    login: async ({ tenant, username, password }) => {
      localStorage.setItem(TENANT_KEY, tenant);
      const { data, status } = await apiAuthHelper.login(tenant, username, password);
      if (status === 200) {
        localStorage.setItem(TOKEN_KEY, data.token);
        localStorage.setItem(REFRESH_KEY, data.refreshToken);
        const user = jwtDecode<MyToken>(data.token ?? "");
/*        localStorage.setItem(
          ROLES_KEY,
          JSON.stringify(user.roles || ["ROLE_GUEST"])
        );
*/
        // set header axios instance
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.token}`;
        axiosInstance.defaults.headers.common["tenant"] = `${localStorage.getItem(TENANT_KEY)}`;

        /*                axiosInstance.defaults.headers = {
                    Authorization: `Bearer ${data.jwt}`,
                };
*/

        return Promise.resolve();
      }
      return Promise.reject();
    },
    logout: () => {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(REFRESH_KEY);
      localStorage.removeItem(ROLES_KEY);
      return Promise.resolve();
    },
    checkError: (error) => {
      /*
        if (error && error.statusCode === 401) {
        const token = localStorage.getItem(TOKEN_KEY);
        const refreshToken = localStorage.getItem(REFRESH_KEY);

        try {
          if (token && refreshToken) {
            const user = jwtDecode<MyToken>(token ?? "");
            if (user.exp > new Date().getTime() / 1000 - 10) {
              return Promise.resolve();
            } else {
              //const { data, status } = await apiAuthHelper.refresh(refreshToken);
              const resp = apiAuthHelper
                .refresh(refreshToken)
                .then(({ data, status }) => {
                  if (status === 200) {
                    localStorage.setItem(TOKEN_KEY, data.token);
                    localStorage.setItem(REFRESH_KEY, data.refreshToken);
                    const user = jwtDecode<MyToken>(data.token ?? "");
                    localStorage.setItem(
                      ROLES_KEY,
                      JSON.stringify(user.roles || ["ROLE_GUEST"])
                    );
                    // set header axios instance
                    axiosInstance.defaults.headers.common[
                      "Authorization"
                    ] = `Bearer ${data.token}`;

                    return Promise.resolve();
                  }
                  return Promise.reject();
                });
              return resp;
            }
          } else {
            return Promise.reject();
          }
        } catch (e) {
          // override possible jwtDecode error
          return Promise.reject();
        }
      }
*/
      const token = localStorage.getItem(TOKEN_KEY);
      const refreshToken = localStorage.getItem(REFRESH_KEY);
      if (token && refreshToken) {
        const decoded = jwtDecode<MyToken>(token ?? "");
        if (decoded.exp > new Date().getTime() / 1000 - 10) {
          return Promise.resolve();
        } else {
          //const { data, status } = await apiAuthHelper.refresh(refreshToken);

          const resp = apiAuthHelper
            .refresh(token, refreshToken)
            .then(({ data, status }) => {
              if (status === 200) {
                localStorage.setItem(TOKEN_KEY, data.token);
                localStorage.setItem(REFRESH_KEY, data.refreshToken);
                const user = jwtDecode<MyToken>(data.token ?? "");
/*                localStorage.setItem(
                  ROLES_KEY,
                  JSON.stringify(user.roles || ["ROLE_GUEST"])
                );
*/
                // set header axios instance
                axiosInstance.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${data.token}`;
                axiosInstance.defaults.headers.common["tenant"] = `${localStorage.getItem(TENANT_KEY)}`;

                return Promise.resolve();
              }
              return Promise.reject();
            });
          return resp;
        }
      } else {
        return Promise.reject();
      }
    },

    checkAuth: () => {
      const token = localStorage.getItem(TOKEN_KEY);
      const refreshToken = localStorage.getItem(REFRESH_KEY);

      try {
        if (token && refreshToken) {
          const user = jwtDecode<MyToken>(token ?? "");
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      } catch (e) {
        // override possible jwtDecode error
        return Promise.reject();
      }
    },
    getPermissions: async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        //const user = jwtDecode<MyToken>(token);
      const role_token = localStorage.getItem(ROLES_KEY);
      if (role_token) {
	 return Promise.resolve(role_token ? JSON.parse(role_token ?? "") : ["ROLE_GUEST"]);
      }
        const { data: roles, status } = await apiAuthHelper.roles(token);
        if (status === 200) {
          localStorage.setItem(
            ROLES_KEY,
            JSON.stringify(roles || ["ROLE_GUEST"])
          );
          return Promise.resolve(roles || ["ROLE_GUEST"]);
        }
      }
      localStorage.removeItem(ROLES_KEY);
      return Promise.reject();
    },

    getUserIdentity: async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        const { data: user, status } = await apiAuthHelper.me(token);
        if (status === 200) {
          return Promise.resolve(user);
        }
      }
      return Promise.reject();
      /*  const token = localStorage.getItem(TOKEN_KEY);

      if (!token) {
        return Promise.reject();
      }

      const user = jwtDecode<MyToken>(token);

      return Promise.resolve({
        id: user.id,
        name: user.username || "",
        fullName: user.name || "",
        roles: user.roles || ["ROLE_GUEST"],
      });*/
      /*  const { data, status } = await apiAuthHelper.me(token);
      if (status === 200) {
        const { id, username, email } = data;
        return Promise.resolve({
          id,
          username,
          email,
        });
      }
*/
      //      return Promise.reject();
    },
    updatePassword: async (params: any) => {
      // you can access query strings from params.queryStrings
      //console.log(params.token);
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        const { data, status } = await apiAuthHelper.updatePassword(
          token,
          params.password,
          params.newPassword,
          params.confirmNewPassword
        );
        if (status === 200) {
          return Promise.resolve();
        }
        return Promise.reject();
      }
      return Promise.resolve(false);
    },
  };

  return {
    authProvider,
    axiosInstance,
  };
};

export default ApiAuthProvider;
