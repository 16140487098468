import React from "react";
import {
  Mix,
  MixConfig,
  Pie,
  PieConfig,
  measureTextWidth,
} from "@ant-design/plots";
import { useTranslate } from "@pankod/refine-core";

interface ChartProps {
  data: {
    type: any; // eslint-disable-line
    value: any; // eslint-disable-line
  }[];
  title?: any;
}

type MixPieData = {
    type: any; // eslint-disable-line
    value: any; // eslint-disable-line,
  };
type MixChartProps = {
  data: {
    [key: string]:   {
        [key: string]: any; // eslint-disable-line      
    }[]  
  },
  title?: any;
}

export const TaskChart01: React.FC<ChartProps> = ({ data, title }) => {
  const config: PieConfig = {
    data: data,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-30%",
      // eslint-disable-next-line
      content: function content(_ref: any) {
        const percent = _ref.percent;
        return "".concat((percent * 100).toFixed(0), "%");
      },
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [{ type: "element-active" }],
    annotations: [
      {
        type: "text",
        content: title,

        /** 位置 */
        position: ["50%", "50%"],

        /** 图形样式属性 */
        style: {
          width: 50,
          height: 50,
        },

        /** x 方向的偏移量 */
        offsetX: -25,

        /** y 方向的偏移量 */
        offsetY: 40,
      },
    ],
  };
  return <Pie {...config} />;
};

export const TaskChart: React.FC<ChartProps> = ({ data, title }) => {
  const t = useTranslate();
  function renderStatistic(containerWidth: number, text: any, style: any) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }
  const config: PieConfig = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.64,
    label: {
      type: "inner",
      offset: "-50%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fill: "#fff",
        textAlign: "center",
      },
    },
    interactions: [{ type: "element-active" }],
    annotations: [],
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = t("dashboard.total");
          return renderStatistic(d, text, {
            //fontSize: 28,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          //fontSize: '32px',
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `${datum.value}`
            : `${data?.reduce((r, d) => r + d.value, 0)} ${t(
                "dashboard.piece"
              )}`;
          return renderStatistic(width, text, {
            // fontSize: 32,
          });
        },
      },
    },
  };
  return <Pie {...config} />;
};

export const MixChart: React.FC<MixChartProps> = ({ data, title }) => {
  const t = useTranslate();
  function renderStatistic(containerWidth: number, text: any, style: any) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }
  const config: MixConfig = {
    // 关闭 chart 上的 tooltip，子 view 开启 tooltip
    tooltip: false,
    //legend: true,
    plots: [
      {
        type: "pie",
        region: {
          start: {
            x: 0,
            y: 0,
          },
          end: {
            x: 0.45,
            y: 1,
          },
        },
        options: {
          data: data?.pie1,
          angleField: "value",
          colorField: "id",
          radius: 0.85,
          tooltip: {
            showMarkers: false,
          },
          label: {
            type: "inner",
            offset: "-15%",
          },
          interactions: [
            {
              type: "element-active",
            },
            {
              type: "association-tooltip",
            },
            {
              type: "association-highlight",
            },
          ],
        },
      },
      {
        type: "pie",
        region: {
          start: {
            x: 0.55,
            y: 0,
          },
          end: {
            x: 1,
            y: 1,
          },
        },
        options: {
          data: data?.pie2,
          radius: 0.85,
          angleField: "value",
          colorField: "id",
          label: {
            type: "inner",
            offset: "-15%",
          },
          tooltip: {
            showMarkers: false,
          },
          interactions: [
            {
              type: "association-tooltip",
            },
            {
              type: "association-selected",
            },
          ],
        },
      },
    ],
  };
  return <Mix {...config} />;
};
