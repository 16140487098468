export const ProjectStatus: Record<number, string> = {
  0: "Not Started",
  1: "Started",
  2: "Finished",
  3: "Reviewed",
  4: "Closed",
};

export const TicketStatus: Record<number, string> = {
  0: "Not Started",
  1: "Started",
  2: "Finished",
  3: "Reviewed",
  4: "Closed",
  5: "Need Changes"
};

export const ProjectRisk: Record<number, string> = {
  0: "Low",
  1: "Normal",
  2: "High",
};
