import { useList, useOne, useTranslate, useUpdate } from "@pankod/refine-core";

import {
  Typography,
  Button,
  Show,
  Space,
  MarkdownField,
  Descriptions,
  Input,
  Popconfirm,
  Card,
  Comment,
} from "@pankod/refine-antd";
import { CommentOutlined, FileSearchOutlined, UserAddOutlined, FileDoneOutlined, CheckOutlined, LoadingOutlined, FormOutlined, ExceptionOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import { IComment, ITicket, IUser } from "interfaces";
import { TicketStatus } from "constants/pm";
import moment from "moment";

const { Title, Text } = Typography;
type TicketReviewProps = {
  record?: any;
  users?: IUser[];
  onUpdate?: any;
};
type CommentListProps = {
  comments: IComment[];
};


export const TicketReview: React.FC<TicketReviewProps> = ({
  record,
  users,
  onUpdate,
}) => {
  const { Title, Text } = Typography;
  const { TextArea } = Input;
  const t = useTranslate();

  
  const mutationResult = useUpdate<ITicket>();

  const { mutate, isLoading: mutateIsLoading } = mutationResult;

  const handleUpdate = (item: ITicket, status: number) => {
    mutate(
      { resource: "tickets", id: item.id, values: { ...item, status } },
      {
        onSuccess: (data) => {
          return onUpdate && onUpdate();
        },
      }
    );
  };

  const CommentList: React.FC<CommentListProps> = ({ comments }) => {
    let items = comments.map((comment) => {
      return (
        <div key={comment.id} className="border-l pl-6">
          <Comment
            key={comment.id}
            content={<p>{comment.content}</p>}
            author={
              users?.find(
                (item) =>
                  item.id === comment?.createdBy?.toString().toLowerCase()
              )?.email
            }
            datetime={moment(comment?.createdOn).fromNow()}
          >
            {comment.replies && CommentList({ comments: comment.replies })}
          </Comment>
        </div>
      );
    });

    return <>{items}</>;
  };

  // const buttonDisabled = isLoading || areaIsLoading || mutateIsLoading;

  return (
    <Show
      title={t("tickets.titles.show")}
      resource="tickets"
      recordItemId={record?.id}
      //       isLoading={isLoading || areaIsLoading}
      pageHeaderProps={{
        backIcon: false,
      }}
      footerButtons={
        <Space key="action-buttons" style={{ float: "right", marginRight: 24 }}>

<Popconfirm
                            title={t("tickets.fields.popConfirmApprove")}
                            onConfirm={() => record && handleUpdate(record, 3)}
                            onCancel={onUpdate}
                            okText={t("tickets.fields.popUpYes")}
                            cancelText={t("tickets.fields.popUpNo")}
                        >
                            <Button size="middle" icon={<FileDoneOutlined style={{ fontSize: '115%' }} />} >{t("tickets.titles.approve")}</Button>
                        </Popconfirm>


         
        </Space>
      }
    >
      <Descriptions
        bordered
        size="small"
        layout="vertical"
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        //title="Custom Size"

        //extra={<Button type="primary">Edit</Button>}
      >
        <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
          label={t("tickets.fields.taskNumber")}
        >
          {record?.title}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
          label={t("tickets.fields.projectName")}
        >
          {record?.project.name}
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
          label={t("tickets.fields.owner")}
        >
          <Text>
            {
              users?.find(
                (item) =>
                  item.id === record?.acceptantId?.toString().toLowerCase()
              )?.email
            }
          </Text>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
          label={t("tickets.fields.taskStatus")}
        >
          <Text >
            {record?.status ? TicketStatus[+record?.status]: TicketStatus[0]}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item
          labelStyle={{ fontSize: "larger", fontWeight: "bolder" }}
          label={t("tickets.fields.description")}
        >
          <TextArea
            value={record?.description}
            disabled
            autoSize={{ minRows: 3, maxRows: 6 }}
            style={{ height: "100%" }}
            showCount
          />
        </Descriptions.Item>
      </Descriptions>
        {record?.comments !== undefined && record?.comments?.length > 0 && (
          <Card title={t("tickets.fields.comments")}>
          <CommentList
            comments={record?.comments?.filter((comment:IComment) => {
              return comment.parentId === null;
            })}
          />
          </Card>
        )}

    </Show>
  );
};
