import { CanParams, CanReturnType, Refine, usePermissions } from "@pankod/refine-core";

import {
  ConfigProvider,
  notificationProvider,
  ReadyPage,
  ErrorComponent,
  AuthPage,
} from "@pankod/refine-antd";

import {
  AntdListInferencer,
  AntdShowInferencer,
  AntdCreateInferencer,
  AntdEditInferencer,
} from "@pankod/refine-inferencer/antd";

import routerProvider from "@pankod/refine-react-router-v6";
//import simpleRestDataProvider from "@pankod/refine-simple-rest";

import { RefineKbarProvider } from "@pankod/refine-kbar";
import { useTranslation } from "react-i18next";
import "styles/antd.less";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
  CustomSider,
} from "components/layout";
import "styles/antd.less";
import { API_URL } from "apiconstants";
import { Login as LoginPage } from "pages/login";

import hu_HU from "utility/antdLocale_HU";
//import hu_HU from "antd/lib/locale/hu_HU";
import dayjs from "dayjs";
import moment from "moment";
import "moment/locale/hu";
import "dayjs/locale/hu";

//import { authProvider } from "./authProvider";

import { ProjectList } from "pages/projects/list";
import { ProjectShow } from "pages/projects/show";
import { DashboardPage } from "pages/dashboard";
import { ProjectEdit } from "pages/projects/edit";
import { EmployeesList } from "pages/employees/list";

import { TicketList } from "pages/tickets/list";
import { TicketEdit } from "pages/tickets/edit";

//import { ApiAccessControlProvider } from "./accessControlProvider";
import { ActionsList } from "pages/actions/list";
import { ActionCreate } from "pages/actions/create";
import { ActionEdit } from "pages/actions/edit";

//import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import apiAuthProvider from "./apiAuthProvider";
import simpleRestDataProvider from "./apiDataProvider";
import { useEffect } from "react";
import {
  PremiseCreate,
  PremiseEdit,
  PremiseList,
  PremiseShow,
} from "pages/premises";
import { PlaceList, PlaceShow, PlaceCreate, PlaceEdit } from "pages/places";
import { AreaCreate, AreaEdit,AreaList, AreaShow } from "pages/areas";
import {
  SourceCreate,
  SourceEdit,
  SourceList,
  SourceShow,
} from "pages/sources";
import { OwnTicketList } from "pages/tickets/own";
import { TicketShow } from "pages/tickets/show";
import { model2, adapter } from "accessControl";
import { newEnforcer } from "casbin";
import { WorkList } from "pages/workedhours/list";
import { CostList } from "pages/costs/list";

//import { apiLiveProvider } from "apiLiveprovider";

function App() {
  const { t, i18n } = useTranslation();

  //  const API_URL = "http://127.0.0.1:5000/api";

  //  const dataProvider = simpleRestDataProvider(API_URL);
  const { authProvider, axiosInstance } = apiAuthProvider(API_URL);
  const dataProvider = simpleRestDataProvider(API_URL, axiosInstance);
  //const liveprovider= apiLiveProvider(axiosInstance);

  const currThemes = {
    dark: `${process.env.PUBLIC_URL}/antd.dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/antd.light-theme.css`,
  };

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const locale = i18nProvider.getLocale();

  //  const { data: permissionsData } = usePermissions();
  //  const role = permissionsData?.[0] ?? "user";
  //const role = "basic";

  const accessControlProvider = {
    can: async ({ resource, action }: CanParams): Promise<CanReturnType> => {
        const roles = await authProvider.getPermissions?.();
        //const role = roles.map((r:any) => r.roleName.toLowerCase());      
        //const role = roles.map((item:any, index:number) => ({ item.roleName.toLowerCase()}));
        //const role = roles.map((r:any) => r.roleName.toLowerCase());      
        const role = roles[0]?.roleName?.toLowerCase() ?? "user" ;
        //const role = "admin";
        //alert(JSON.stringify(role)+'--'+JSON.stringify(resource)+'--'+JSON.stringify(action)) 

        const enforcer = await newEnforcer(model2, adapter);        
        const can = await enforcer.enforce(role, resource, action);
    
        return Promise.resolve({
            can,
        });
    },
};

  
  useEffect(() => {
    if (locale === "hu") {
      moment.locale("hu");
      dayjs.locale("hu");
    } else {
      moment.locale("en");
      dayjs.locale("en");
    }
    document.documentElement.lang = locale;
  }, [locale]);

  return (
    <ThemeSwitcherProvider themeMap={currThemes} defaultTheme="light">
      <RefineKbarProvider>
        <ConfigProvider locale={locale === "hu" ? hu_HU : undefined}>
          <Refine
            //liveProvider={apiLiveProvider(axiosInstance)}
            DashboardPage={DashboardPage}
            notificationProvider={notificationProvider}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            routerProvider={routerProvider}
            dataProvider={dataProvider}
            authProvider={authProvider}
            LoginPage={LoginPage}
            Title={Title}
            Header={Header}
            Sider={Sider}
            Footer={Footer}
            Layout={Layout}
            OffLayoutArea={OffLayoutArea}
            i18nProvider={i18nProvider}
            accessControlProvider={accessControlProvider}
            /*            accessControlProvider={{
              can: async ({ action, params, resource }) => {
                const enforcer = await newEnforcer(model, adapter);
                if (
                  action === "delete" ||
                  action === "edit" ||
                  action === "show"
                ) {
                  return Promise.resolve({
                    can: await enforcer.enforce(
                      role,
                      `${resource}/${params?.id}`,
                      action
                    ),
                  });
                }
                if (action === "field") {
                  return Promise.resolve({
                    can: await enforcer.enforce(
                      role,
                      `${resource}/${params?.field}`,
                      action
                    ),
                  });
                }
                return Promise.resolve({
                  can: await enforcer.enforce(role, resource, action),
                });
              },
            }}
*/
            resources={[
              {
                name: "BaseDatas",
                options: {
                  label: t("menu.baseData"),
                },
              },
              {
                parentName: "BaseDatas",
                name: "sources",
                list: SourceList,
                show: SourceShow,
                create: SourceCreate,
                edit: SourceEdit,
                options: {
                  label: t("menu.sources"),
                },
              },
              {
                parentName: "BaseDatas",
                name: "premises",
                list: PremiseList,
                show: PremiseShow,
                create: PremiseCreate,
                edit: PremiseEdit,
                options: {
                  label: t("menu.premises"),
                },
              },
              {
                parentName: "BaseDatas",
                name: "areas",
                list: AreaList,
                show: AreaShow,
                create: AreaCreate,
                edit: AreaEdit,
                options: {
                  label: t("menu.areas"),
                },
              },
              {
                parentName: "BaseDatas",
                name: "users",
                //create: AntdCreateInferencer,
                list: EmployeesList,
                options: {
                  label: t("menu.employees"),
                },
              },
              {
                parentName: "BaseDatas",
                name: "places",
                list: PlaceList,
                show: PlaceShow,
                create: PlaceCreate,
                edit: PlaceEdit,
                options: {
                  label: t("menu.places"),
                },
              },
              {
                name: "MainProjects",
                options: {
                  label: t("menu.projects"),
                },
              },
              {
                name: "allprojects",
                parentName: "MainProjects",
                list: ProjectList,
                show: ProjectShow,
                edit: ProjectEdit,
                options: { label: t("menu.projects"), route: "allprojects" },
              },
              {
                name: "alltickets",
                parentName: "MainProjects",
                list: TicketList,
                edit: TicketEdit,
                show: TicketShow,
                options: { label: t("tickets.titles.list"), route: "alltickets" },
              },
              {
                name: "projects",               
                list: ProjectList,
                show: ProjectShow,
                edit: ProjectEdit,
                options: {  hide:true, label: t("menu.projects"), route: "projects" },
              },
              {
                name: "tickets",
                //parentName: "MainProjects",
                list: TicketList,
                edit: TicketEdit,
                show: TicketShow,
                options: { hide:true, label: t("tickets.titles.list"), route: "tickets" },
              },
              {
                name: "owntickets",
                list: OwnTicketList,
                //create: TaskCreate,
                //edit: TicketEdit,
                //show: TicketShow,
                options: { label: t("menu.owntickets"), route: "owntickets" },
              },
              {
                name: "CostConnections",
                options: {
                  label: "Költséghelyek",
                },
              },
              {
                name: "usercostconnections",
                parentName: "CostConnections",
                list: WorkList,
                options:{label: t("menu.usercostconnections"), route: "usercostconnections"}
              },
              {
                name: "costs",
                parentName: "CostConnections",
                list: CostList,
                options:{label: "Elvégzett feladatok"}
              }
            ]}
          />
        </ConfigProvider>
      </RefineKbarProvider>
    </ThemeSwitcherProvider>
  );
}

export default App;
