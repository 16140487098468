import { useEffect, useState } from "react";
import {
    CrudFilters,
    HttpError,
    useGetIdentity,
    useMany,
    useShow,
    useTranslate,
} from "@pankod/refine-core";

import {
    BooleanField,
    Button,
    ButtonProps,
    Card,
    Col,
    Create,
    CreateButton,
    DatePicker,
    Form,
    FormProps,
    getDefaultSortOrder,
    Grid,
    Icons,
    Input,
    List,
    Modal,
    Row,
    Select,
    Space,
    Table,
    TagField,
    TextField,
    Tooltip,
    Typography,
    useDrawerForm,
    useModal,
    useSelect,
    useTable,
} from "@pankod/refine-antd";
import { Drawer } from 'antd';
import { ICost, ITicketFilter, IPartner, ITicket, IArea, ITicketpriority, IUser, IProject } from "interfaces";
import { PartnerSelect } from "./PartnersSelect";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

type PartnerSelectProps = {
    value?: ITicket;
    onChange?: (id?: string) => void;
    disabled?: boolean;
};


/**
 * @param value initialValue passed by parent Form.Item.
 * @param onChange a callback for Form.Item to read its child component's value.
 * @param disabled
 * @constructor
 */
export const CostSelect: React.FC<PartnerSelectProps> = ({
    value,
    onChange,
    disabled,
}) => {
    const t = useTranslate();
    const [cost, setCost] = useState<ITicket | undefined>(value);
    const { tableProps, sorter, filters, searchFormProps } = useTable<
        ITicket,
        HttpError,
        ITicketFilter
    >({
        resource: "tickets",
        initialSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],

        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { name } = params;

            filters.push({
                field: "name",
                operator: "eq",
                value: name,
            });

            return filters;
        },
        syncWithLocation: false,
    });
    const { xl } = useBreakpoint();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([
        value?.id ?? "",
    ]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const {
        modalProps: partnerSelectModal,
        show: partnerSelectShow,
        close: partnerSelectClose,
    } = useModal();

    const { setShowId, queryResult: partnerQueryResult } = useShow<ITicket>({
        resource: "tickets",

        id: cost?.id,
    });

    let promise = new Promise(function (resolve, reject) {
        if (typeof onChange === "function") {
            resolve(onChange(cost?.id));
        } else {
            resolve(true);
        }
    });

    useEffect(() => {
        if (value && cost?.id !== value) {
            setCost({ id: value });
        }
    }, [value]);

    useEffect(() => {
        if (partnerQueryResult.isFetched) {
            setCost(partnerQueryResult.data?.data);
        }
    }, [partnerQueryResult]);

    useEffect(() => {
        if (partnerQueryResult.isFetched && !partnerQueryResult.isError) {
            promise.then(partnerSelectClose);
        }
    }, [cost]);


    const change = async (selectedRowKeys: React.Key[]) => {
        if (cost?.id !== selectedRowKeys[0]) {
            setShowId(selectedRowKeys[0]);
            if (typeof onChange === "function") {
                onChange(selectedRowKeys[0] as string);
            }
        } else {
            partnerSelectClose();
        }
    };

    const { data: partners } = useMany<IPartner>({
        resource: "partners",
        ids: [],
    });

    const { formProps: drawerFormCost, drawerProps: drawerPropsCost, show: drawerShowCost, saveButtonProps: saveButtonPropsCost } = useDrawerForm({
        action: "create",
        resource: "tickets",
        redirect: false
    });

    const { selectProps: selectPropsKh, queryResult: queryResultKh, defaultValueQueryResult: defaultValueQueryResultKh } =
        useSelect<IPartner>({
            resource: "partners",
            optionLabel: "name",
            optionValue: "id",
            sort: [
                {
                    field: "name",
                    order: "asc"
                }
            ]
        });

    const allOptionsKh = [
        ...(queryResultKh.data?.data || []),
        ...(defaultValueQueryResultKh.data?.data || []),
    ].filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.id === item.id
        ))
    );

    const { selectProps: areaSelectProps } = useSelect<IArea>({
        resource: "areas",
        optionValue: "id",
        optionLabel: "name",
        hasPagination: false,
        pagination: {
            pageSize: 2,
        },
    });

    const { selectProps: prioritySelectProps } = useSelect<ITicketpriority>({
        resource: "ticketpriorities",
        optionValue: "id",
        optionLabel: "name",
        hasPagination: false,
        pagination: {
            pageSize: 2,
        },

    });

    const { selectProps: projectSelectProps } = useSelect<IProject>({
        resource: "projects",
        optionValue: "id",
        optionLabel: "name",
        hasPagination: false,
        pagination: {
            pageSize: 2,
        },

    });

    const { selectProps: ownerProps } = useSelect<IUser>({
        resource: "users",
        optionValue: "id",
        optionLabel: "email",
        hasPagination: false,
    });

    const { data: user } = useGetIdentity();


    return (
        <>
            <Space>

                <TextField value={cost?.title} />

                <Space>
                    {" "}
                    {!disabled && (
                        <Button
                            icon={<Icons.SearchOutlined />}
                            onClick={() => {
                                partnerSelectShow();
                            }}
                        ></Button>
                    )}
                </Space>
            </Space>

            <Modal
                width={1200}
                {...partnerSelectModal}
                onCancel={partnerSelectClose}
                onOk={() => {
                    change(selectedRowKeys);
                }}
            >
                <List
                    title="Feladat kiválasztása"
                    resource="tickets"
                    pageHeaderProps={{
                        extra: <CreateButton hidden={true} />,
                    }}
                >
                    <Form
                        {...searchFormProps}
                        layout="vertical"
                        onValuesChange={() => searchFormProps.form?.submit()}
                    >
                        <Space wrap>
                            <Form.Item
                                label="Feladat neve"
                                name="subprojects"
                            >
                                <Input
                                    prefix={<Icons.SearchOutlined />}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Rögzítés"
                            >
                                <Button type="primary" onClick={() => { drawerShowCost() }}>
                                    Új feladat rögzítése
                                </Button>
                            </Form.Item>
                        </Space>
                    </Form>

                    <Table
                        {...tableProps}
                        rowKey="id"
                        rowSelection={{
                            type: "radio",
                            ...rowSelection,
                        }}
                    >

                        <Table.Column
                            dataIndex="partnerId"
                            title="Partner"
                            render={(value: string) => {
                                return (
                                    <TextField

                                        value={`${partners?.data.find((item) => item.id === value)?.name}`}
                                    />
                                );
                            }}
                        />

                        <Table.Column
                            dataIndex="subprojects"
                            key="subprojects"
                            title="Feladat"
                            render={(value) => <TextField value={value} />}
                            sorter
                        />
                    </Table>
                </List>
                <Drawer {...drawerPropsCost}>
                    <Create saveButtonProps={saveButtonPropsCost} title="Költséghely rögzítése" >
                        <Form {...drawerFormCost} layout="vertical">

                            <Form.Item
                                label={t("tickets.fields.title")}
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}

                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Projekt"
                                name="projectId"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select {...projectSelectProps} />

                            </Form.Item>

                            <Form.Item
                                label="Partner"
                                name="partnerId"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <PartnerSelect />

                            </Form.Item>

                            <Form.Item
                                label="Feladat neve"
                                name="subprojects"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />

                            </Form.Item>

                            <Form.Item
                                label={t("tickets.fields.description")}
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={6} />
                            </Form.Item>

                            <Form.Item
                                label={t("tickets.fields.deadline")}
                                name="dueDate"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    placeholder={t("tickets.fields.selectDate")}
                                />
                            </Form.Item>

                            {/** --------------------- Initial values --------------------- */}

                            <Form.Item
                                hidden
                                label="Type"
                                name="type"
                                initialValue={0}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label={t("tickets.fields.area")}
                                name={["areaId"]}
                                hidden
                                //Local
                                //initialValue={"4A51B765-8285-4094-AF32-08DC3DB9CC2D"}

                            //Server
                            initialValue={"1602c9af-eaf1-42f8-cdcc-08db2220ecf9"}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label={t("tickets.fields.priority")}
                                name={["priorityId"]}
                                hidden
                                //Local
                                //initialValue={"02774803-75FA-4A2D-ACBD-08DC3DB9CC39"}

                            //Server
                            initialValue={"1ab12a9b-f632-4810-a04e-08db03868c23"}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label={t("tickets.fields.owner")}
                                name="acceptantId"
                                hidden
                                initialValue={user?.id}
                            >
                                <Input />

                                {/*<Select {...assigneProps} />*/}
                            </Form.Item>

                        </Form>
                    </Create>
                </Drawer>
            </Modal>
        </>
    );
};

