import axios, { AxiosInstance } from "axios";
import { TENANT_KEY, REFRESH_KEY, ROLES_KEY, TOKEN_KEY } from "apiconstants";

export interface MyToken {
  id: string;
  username: string;
  email: string;
  name: string;
  exp: number;
  roles: [];
  // whatever else is in the JWT.
}

interface ILoginResponse {
  // jwt: string;
  //user: IUser;
  token: string;
  refreshToken: string;
  refreshTokenExpiryTime: Date;
}

interface IRole {
  id: number | string;
  name: string;
  description: string;
  type: string;
}

interface IUser {
  id: number | string;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  role: IRole;
  created_at: string;
  updated_at: string;
}

export const AuthHelper = (apiUrl: string, httpClient: AxiosInstance) => ({
  login: async (tenant: string, email: string, password: string) => {
    const url = `${apiUrl}/tokens`;
    localStorage.setItem(TENANT_KEY, tenant);
    return await httpClient.post<ILoginResponse>(url, {
      email,
      password,
    });
  },
  refresh: async (token: string, refresh_token: string) => {
    const url = `${apiUrl}/tokens/refresh`;

    return await httpClient.post<ILoginResponse>(url, {
      token: token,
      refreshToken: refresh_token,
    });
  },
  me: async (token: string) => {
    return await httpClient.get<IUser>(`${apiUrl}/personal/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  roles: async (token: string) => {
    return await httpClient.get<IRole[]>(`${apiUrl}/personal/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  updatePassword: async (
    token: string,
    password: string,
    newPassword: string,
    confirmNewPassword: string
  ) => {
    return await httpClient.put(`${apiUrl}/personal/change-password`, {
      password: password,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
});
