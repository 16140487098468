import React from "react";
import { IResourceComponentsProps, useShow, useTranslate } from "@pankod/refine-core";
import { Show, Typography, TagField, TextField } from "@pankod/refine-antd";

const { Title } = Typography;

export const AreaShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
           {/* <Title level={5}>Id</Title>
            <TextField value={record?.id} />*/}
            <Title level={5}>{t('areas.fields.name')}</Title>
            <TextField value={record?.name} />
        </Show>
    );
};
