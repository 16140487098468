import {
  CrudFilters,
  file2Base64,
  HttpError,
  useGetIdentity,
  useGetLocale,
  useMany,
  useOne,
  usePermissions,
  useTranslate,
} from "@pankod/refine-core";

import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useDrawerForm,
  Drawer,
  Create,
  Form,
  Input,
  Radio,
  useModalForm,
  Row,
  Avatar,
  Select,
  //  DatePicker,
  Col,
  Tooltip,
  Modal,
  Upload,
  message,
  EditButton,
  Space,
  ShowButton,
  UploadFile,
  RcFile,
  useSelect,
  getValueFromEvent,
  useModal,
  Button,
  Icons,
  DeleteButton,
} from "@pankod/refine-antd";
import { DatePicker } from "antd";
import type { ColumnsType } from "antd/es/table";

import {
  UserOutlined,
  AntDesignOutlined,
  InboxOutlined,
  UploadOutlined,
  FormOutlined,
  Loading3QuartersOutlined,
  CheckOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  ExceptionOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { dateFormat, HOST_URL } from "apiconstants";
import { getValueProps } from "helpers";
import {
  IPremise,
  IProject,
  IProjectVariable,
  ISource,
  ITicket,
  IArea,
  ITicketpriority,
  IUser,
  IPlace,
} from "interfaces";
import React, { useState } from "react";
import moment from "moment";
import { ProjectStatus, ProjectRisk } from "constants/pm";
import { PdfLayout } from "components/pdf";
const { FilePdfOutlined } = Icons;

export const ProjectList: React.FC = () => {
  const t = useTranslate();
  const { Option } = Select;
  const { Dragger } = Upload;
  const { TextArea } = Input;
  const locale = useGetLocale();
  const currentLocale = locale();

  const {
    tableProps: projectsTable,
    sorter,
    filters,
    searchFormProps,
  } = useTable<
    IProject,
    HttpError
    //IPartnerFilter
  >({
    resource: "projects",

    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    syncWithLocation: false,
  });

  const { data: user, refetch:userRefetch } = useGetIdentity<IUser>();
  const { data: permissionsData } = usePermissions();
  const {
    modalProps: editModal,
    formProps: editForm,
    show: editShow,
    id,
  } = useModalForm<IProject, HttpError, IProjectVariable>({
    action: "edit",
    warnWhenUnsavedChanges: true,
  });
  const {
    modalProps: createModal,
    formProps: createForm,
    show: showCreateModal,
    submit: submitCrtForm,
  } = useModalForm<IProject>({
    action: "create",
    resource: "projects",
    warnWhenUnsavedChanges: true,
  });

  const { selectProps: premiseProps } = useSelect<IPremise>({
    resource: "premises",
    optionValue: "id",
    optionLabel: "name",
    hasPagination: false,
  });
  const { selectProps: sourceProps } = useSelect<ISource>({
    resource: "sources",
    optionValue: "id",
    optionLabel: "name",
    hasPagination: false,
  });
  const { selectProps: placeProps } = useSelect<IPlace>({
    resource: "places",
    optionValue: "id",
    optionLabel: "name",
    hasPagination: false,
  });
  const ownerIds = projectsTable?.dataSource?.map((item) => item.ownerId) ?? [];

  const { data: owners } = useMany<IUser>({
    resource: "users",
    ids: ownerIds,
  });

  const { data: users } = useMany<IUser>({
    resource: "users",
    ids: [],
  });

  const columnsAllTasks: ColumnsType<IProject> = [
    {
      title: t("projects.fields.projectTitle"),
      dataIndex: "name",
      key: "name",
    },
    /* {
      title: t("projects.fields.projectId"),
      dataIndex: "projectId",
      key: "projectId",
    },*/
    {
      title: t("projects.fields.leader"),
      dataIndex: "ownerId",
      key: "ownerId",
      /*render: (value: string) => {
        var user = owners?.data.find(
          (item) => item.id === id?.toString().toLowerCase())
        return (
          <TagField
            value={user?.firstName + " " + user?.lastName}
          />
        );
      },*/
      render: (value: string) => {
        return (
          <TagField
            value={owners?.data.find((item) => item.id === value)?.email}
          />
        );
      },
    },
    {
      title: t("projects.fields.team"),
      dataIndex: "tickets",
      key: "team",
      render: (value: any) => {
        let x = value.reduce((acc: any, { acceptantId }: any) => {
          acc[acceptantId] = acceptantId;

          return acc; //acceptantId
        }, {});
        //return JSON.stringify(Object.keys(acc));
        return Object.keys(x).map((id: string) => {
          //return JSON.stringify(ticket.acceptantId);
          return (
            <TagField
              value={
                users?.data.find(
                  (item) => item.id === id?.toString().toLowerCase()
                )?.email
              }
            />
          );
        });

        /*return value.map((ticket: ITicket) => {
          
          //return JSON.stringify(ticket.acceptantId);
          return (
            <TagField
              value={
                users?.data.find(
                  (item) =>
                    item.id === ticket.acceptantId?.toString().toLowerCase()
                )?.email
              }
            />
          );
        });*/
      },
    },
    {
      title: t("projects.fields.deadline"),
      dataIndex: "dueDate",
      key: "dueDate",
      align: "center",
      render: (value) =>
        value &&
        !moment(value, dateFormat).toString().includes("0001 00:00:00") && (
          <DateField locales={currentLocale} value={value} format="L" />
        ),
    },
    {
      title: t("projects.fields.priority"),
      dataIndex: "risk",
      key: "risk",
      render: (value) => (
        <TextField value={ProjectRisk[value || 0].toString()} />
      ),
    },
    {
      title: t("projects.fields.status"),
      dataIndex: "status",
      key: "status",
      render: (value) => (
        //ProjectStatus[value || 0]

        <TextField value={ProjectStatus[value || 0].toString()} />
      ),
    },
    {
      title: t("projects.fields.actions"),
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      render: (_, record) => (
        <Space>
          <EditButton
            hideText
            size="small"
            resourceNameOrRouteName="projects"
            recordItemId={record.id}
          />
          <ShowButton  resourceNameOrRouteName="projects" hideText size="small" recordItemId={record.id} />
          {permissionsData?.some(
            (r: any) => r.roleName === "Admin" && r.enabled === true
          ) && <DeleteButton  resourceNameOrRouteName="projects" hideText size="small" recordItemId={record.id} />}
          {record?.id && (
            <Button
              size="small"
              icon={<FilePdfOutlined />}
              onClick={() => {
                setRecord(record);
                showPdf();
              }}
            />
            )}
        </Space>
      ),
    },
  ];

  const {
    modalProps: modalPropsPdf,
    show: showPdf,
    close: closePdf,
  } = useModal();
  const [record, setRecord] = useState<IProject>();
  return (
    <>
      <List //canCreate={permissionsData?.includes("admin")}
        title={t("projects.titles.list")}
        createButtonProps={{
          style: { borderRadius: "10px" },
          onClick: () => {
            showCreateModal();
          },
        }}
      >
        <Table {...projectsTable} columns={columnsAllTasks} rowKey="id">
          <Table.Column dataIndex="title" title={t("projects.fields.title")} />
          <Table.Column
            dataIndex="projectName"
            title={t("projects.fields.projectName")}
          />
          <Table.Column
            key="dueDate"
            dataIndex="dueDate"
            title={t("projects.fields.deadline")}
            render={(value) => (
              <DateField locales={currentLocale} value={value} format="L" />
            )}
            sorter
          />

          <Table.Column<IProject>
            key="aa"
            title={t("table.actions")}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal
        {...createModal}
        cancelButtonProps={{ style: { borderRadius: "10px" } }}
        okButtonProps={{ style: { borderRadius: "10px" } }}
        //onOk={()=>alert('hello')}
        //onOk={() =>createSubmit()}
        onOk={() => createForm.form?.submit()}
      >
        <Form {...createForm} layout="vertical">
          <Row gutter={10}>
            <Col xs={24} lg={12}>
              <Form.Item hidden name={"id"}>
                <Input />
              </Form.Item>

              <Form.Item label={t("projects.fields.projectTitle")} name="name"
               rules={[
                {
                  required: true,
                },
              ]}
              >
                <Input style={{}} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                label={t("projects.fields.site")}
                name={"premiseId"}
                getValueProps={(value) => ({
                  value: value && value,
                })}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select allowClear {...premiseProps} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t("projects.fields.description")}
                name="description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  style={{ height: "100%" }}
                  showCount
                  minLength={1000}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Row gutter={10}>
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={t("projects.fields.source")}
                    name={"sourceId"}
                    getValueProps={(value) => ({
                      value: value && value,
                    })}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select allowClear {...sourceProps} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={t("projects.fields.sourceReference")}
                    name="sourceReference"
                  >
                    <TextArea
                      style={{ height: "100%" }}
                      showCount
                      minLength={1000}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={6} lg={6}>
              <Form.Item
                label={t("projects.fields.detectionDate")}
                getValueProps={(value) => ({
                  value:
                    value &&
                    value !== "0001-01-01T00:00:00" &&
                    !moment(value?.detectionDate, dateFormat)
                      .toString()
                      .includes("0001 00:00:00")
                      ? moment(value, dateFormat)
                      : "",
                })}
                name="detectionDate"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  //onChange={onChangeDate}
                  //defaultValue={moment("2022-10-31", dateFormat)}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
            <Col xs={6} lg={6}>
              <Form.Item
                label={t("projects.fields.detectionPlace")}
                name={"detectionPlaceId"}
                // name={["place", "id"]}
                //valuePropName={"detectionPlaceId"}
                getValueProps={(value) => ({
                  value: value && value,
                })}
              >
                <Select allowClear {...placeProps} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal {...modalPropsPdf} onCancel={closePdf} width="80rem" footer={null}>
        <PdfLayout users={undefined} record={record} />
      </Modal>
    </>
  );
};
